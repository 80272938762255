import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as filters from './filters/app'
import service from './api/request'
import i18n from './assets/lang/index'
// import amplitude from 'amplitude-js'
// const amplitudeKey = process.env.NODE_ENV !== "production" ? '030093ff3098f25518bdab80f0eca1d3' :  'd8c5b2c057ce3fc6ed413bdae09f84eb'
// console.log(amplitudeKey)
// amplitude.getInstance().init(amplitudeKey)
// amplitude.getInstance().setVersionName('1.0.0.0')
// Vue.prototype.amplitude = amplitude

Vue.config.productionTip = false

Vue.prototype.$axios = service
Vue.prototype.$analytics = window.analytics
Vue.prototype.gtag = window.gtag

Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
})

Vue.prototype.eventLog = function(btnId) {
    let param = {
        btnId,
        "type": 0,
        "phoneId": window.uuidstr,
    }
    this.$axios.post('/operationLog/protal',param)
}

Vue.prototype.loading = function () {
    if (document.getElementById('globalLoader')) {
        document.getElementById('globalLoader').remove()
    }
    let div = document.createElement('div')
    let loader_div = document.createElement('div')
    loader_div.className = 'loader'
    div.className = 'global-loader-box'
    div.setAttribute('id', 'globalLoader')
    div.insertBefore(loader_div, div.lastChild)
    let app = document.getElementById("app")
    app.insertBefore(div, app.lastChild)
    div.style.display = 'flex'
}

Vue.prototype.hideLoading = function () {
    if (document.querySelector('#globalLoader')) {
        document.querySelector('#globalLoader').remove()
    }
}

Vue.prototype.toast = function (text = 'toast', duration = 4000) {
    if (document.getElementById('globalToast') !== null) {
        document.getElementById('globalToast').remove()
    }
    let div = document.createElement('div');
    div.className = 'tips-box'
    div.setAttribute('id', 'globalToast')
    div.innerText = text
    let app = document.getElementById("app")
    app.insertBefore(div, app.lastChild)
    div.style.display = 'flex'
    setTimeout(() => {
        div.remove()
    }, duration)
}

// 跟 inputErr 一起使用
window.$('body').on('focus','input',function () {
    window.$(this).parents('.input-err-msg').find('.err-tips').remove()
    window.$(this).parent().removeClass('input-err-msg')
})

Vue.prototype.inputErr = function (className,test) {
    window.$(className).parent().find('.err-tips').remove()
    window.$(className).parent().addClass('input-err-msg')
    window.$(className).after('<p class="err-tips" style="color: #ff4d4f;margin-top: 3px;">'+ test +'</p>')
}

Vue.prototype.isEmailAvailable = function (emailInput) {
    let myreg = /^([a-zA-Z0-9]+[_|/_|/.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|/_|/.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
    return myreg.test(emailInput)
}

Vue.prototype.add_url_search = function (list, str) {
    let u,url;
    if (typeof list == "string") {
        url = list
    }
    if (Array.isArray(list)) {
        if (location.host == 'test.chamspy.soouya.cn' && list[1].indexOf('/phonenumber2') == -1) {
            url = list[0]+ '/phonenumber2' + list[1]
        }else {
            url = list[0] + list[1]
        }
    }
    console.log(url)
    if (localStorage.getItem('url_search') != null && localStorage.getItem('url_search') != "" ) {
        let s = url.indexOf("?") == -1 ? '?' : '&';
        u = url + s + localStorage.getItem('url_search').substr(1) + '&' + str
    }else {
        let s = url.indexOf("?") == -1 ? '?' : '&';
        u = url + s + str
    }
    return encodeURI(u)
}

Vue.prototype.routerQuery = function(str) {
    let obj = {}
    let list = str.split('&')

    for (let i = 0; i < list.length; i++) {
        let objList = list[i].split('=')
        obj[objList[0]] = objList[1]
    }
    if (localStorage.getItem('url_search') != null && localStorage.getItem('url_search') != "") {
        let tempStr = localStorage.getItem('url_search').substr(1).split('&')
        for (let i = 0; i < tempStr.length; i++) {
            let objList = tempStr[i].split('=')
            obj[objList[0]] = objList[1]
        }
    }
    return obj
}

Vue.prototype.IsAndroid = function () {
    let u = navigator.userAgent;
    let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
    return isAndroid;
}
Vue.prototype.IsPC = function () {
    let userAgentInfo = navigator.userAgent;
    let Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
    let flag = true;
    for (let v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
            flag = false;
            break;
        }
    }
    return flag;
}

Vue.prototype.timeFormat = function (val, fmt) {
    if (val === 0 || val === '' || val === undefined) return '';
    let ret;
    let len = val.toString().length
    let timestamp = val
    if (len === 10) {
        timestamp = Number(timestamp) * 1000
    }
    if (typeof timestamp === 'string') {
        timestamp = Number(timestamp)
    }
    let date = new Date(timestamp)
    const opt = {
        "Y+": date.getFullYear().toString(), // 年
        "m+": (date.getMonth() + 1).toString(), // 月
        "d+": date.getDate().toString(), // 日
        "H+": date.getHours().toString(), // 时
        "M+": date.getMinutes().toString(), // 分
        "S+": date.getSeconds().toString() // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
            fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
        }
    }

    return fmt;
}
Vue.prototype.loadLabel = function (obj, label,fu) {
    let s = document.createElement(label)
    for (let k in obj) {
        s[k] = obj[k]
    }
    if (label == 'script') {
        document.getElementsByTagName('head')[0].appendChild(s)
    } else {
        document.getElementsByTagName('body')[0].appendChild(s)
    }
    s.onload = fu
}

Vue.prototype.handleData = function (data) {
    let obj = data
    let arr = obj.result
    if (arr.length === 0) return obj
    try {
        for (let i = 0; i < arr.length; i++) {
            arr[i].json = JSON.parse(arr[i].json)
        }
        obj.result = arr
        return obj
    } catch (e) {
        return obj
    }
}

if (localStorage.getItem('url_search') == null && location.search.indexOf('utm_') != -1) {
    localStorage.setItem('url_search',location.search)
}
if (localStorage.getItem('inif_url') == null) {
    localStorage.setItem('inif_url',location.href)
}

// uuid
window.uuidstr = localStorage.getItem('uuid');
if (!window.uuidstr) {
    window.uuidstr = +new Date() + uuid(16, 16)
    localStorage.setItem('uuid', window.uuidstr)
}
// sem
window.channel = ''
if (getQueryString('channel')) {
    localStorage.setItem('channel', getQueryString('channel'))
    window.channel = getQueryString('channel')
} else {
    window.channel = localStorage.getItem('channel') || ''
}
// key words
window.utm_term = ''
if (getQueryString('utm_term')) {
    localStorage.setItem('utm_term', getQueryString('utm_term'))
    window.utm_term = getQueryString('utm_term')
} else {
    window.utm_term = localStorage.getItem('utm_term') || ''
}
//  p method
window.global_method = ''
if (getQueryString('methods')) {
    localStorage.setItem('global_method', getQueryString('methods'))
    window.global_method = getQueryString('methods')
} else {
    window.global_method = localStorage.getItem('global_method') || ''
}
// alliance
if (getQueryString('utm_medium')) {
    localStorage.setItem('hmci', getQueryString('utm_medium'))
}


//get url key-val
function getQueryString(name) {
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    const search = decodeURIComponent(window.location.search.substr(1))
    const r = search.match(reg);
    if (r != null)
        return unescape(r[2]);
    return null;
}

Vue.prototype.$getQueryString = getQueryString

function uuid(len, radix) {
    let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
    let uuid = [],
        i;
    radix = radix || chars.length;
    if (len) {
        for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
    } else {
        let r;
        uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
        uuid[14] = '4';
        for (i = 0; i < 36; i++) {
            if (!uuid[i]) {
                r = 0 | Math.random() * 16;
                uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
            }
        }
    }
    return uuid.join('');
}

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
