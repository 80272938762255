<template>
    <nav class="navbar">
        <div class="content">
            <a class="logo-box" @click="goHome('/')">
                <!-- <div class="logo"></div> -->
                <div class="logo-text">{{$t("nav.logoName")}}</div>
            </a>
            <!-- <a @click="go('/cancel-order')" class="unsubscribe">UNSUBSCRIBE</a> -->
            <a class="sign" @click="signClick">{{sign_text}}</a>
            <div class="nav-btn" @click="showNav()" :class="!nav_flag? 'active' : ''">
                <span></span>
                <span></span>
                <span></span>
            </div>
            <ul class="navbar-list" :class="!nav_flag? 'active' : ''">
                <li>
                    <a class="scrollTop" data-scrollTop="Advantage" @click="goIndex('Advantage')">{{$t("nav.advantage")}}</a>
                </li>
                <li>
                    <a class="scrollTop" data-scrollTop="Price" @click="goIndex('Price')">{{$t("nav.price")}}</a>
                </li>
                <li>
                    <a class="scrollTop" data-scrollTop="Operating" @click="goIndex('Operating')">{{$t("nav.operating")}}</a>
                </li>
                <!-- <li class="cancel-item">
                    <a class="Unsubscribe-title" @click="go('/cancel-order')">Unsubscribe</a>
                </li> -->
                <li>
                    <a @click="go('/contact')">{{$t("nav.contact")}}</a>
                </li>
            </ul>
            <div class="shadow" v-show="!nav_flag" @click="nav_flag = !nav_flag"></div>
        </div>


    </nav>
</template>

<script>
    import {mapGetters} from 'vuex';
    export default {
        name: "page-head",
        data() {
            return{
                nav_flag: true,
            }
        },
        computed: {
            sign_text(){
                return this.userInfo ? this.$t("nav.account") : this.$t("nav.signIn")
            },
            ...mapGetters(['userInfo'])
        },
        methods:{
            signClick() {
                if (this.userInfo) {
                    this.$router.push({
                        path: '/account'
                    })
                }else {
                    this.$router.push({
                        path: '/login'
                    })
                }
                this.nav_flag = true
            },
            goIndex(str) {
                this.nav_flag = true
                this.$emit('scroll',str)
            },
            goHome() {
                this.$router.push({
                    path: '/'
                })
            },
            go(str) {
                this.$router.push({
                    path: str
                })
                if (str === '/cancel-order') {
                    // this.amplitude.getInstance().logEvent('UnSubscribeScreen',{
                    //     Source: 'Title'
                    // })
                    this.eventLog('UnSubscribeScreen-Title')
                }
                this.nav_flag = true
            },
            showNav() {
                this.nav_flag = !this.nav_flag
            }
        }
    }
</script>

<style lang="scss" scoped>
    .shadow{
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
    }
</style>
