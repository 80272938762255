module.exports = {
    nav:{
        logoName: 'Phone Locator',
        account: 'COMPTE',
        signIn: 'S\'IDENTIFIER',
        advantage:'Avantage',
        price: 'Tarif',
        operating: 'Comment ça marche',
        contact: 'Contact'
    },
    footer:{
        ourService: 'NOTRE SERVICE',
        recoverPassword: 'Récupérer mot de passe',
        more: 'PLUS',
        termsOfUse: 'Termes et conditions',
        privacyPolicy :"Politique de confidentialité",
        cookiePolicy: 'Politique de cookies'
    },
    index: {
        title: 'Localiser le téléphone',
        enterNumnerFind :'Entrez le numéro à suivre',
        continueUP :'CONTINUER',
        phoneNumberErrTips: 'Veuillez entrer le numéro de téléphone portable correct pour votre pays',
        phoneNumberErrTipsDesc: 'Grâce à notre service de géolocalisation, vous pouvez localiser le téléphone quel que soit l\'opérateur choisi et trouver la personne que vous recherchez. Conformément à la réglementation AEPD, le système de localisation géographique nécessite l\'autorisation préalable de l\'utilisateur.',
        emailErrTips: 'Veuillez entrer une adresse e-mail valide pour recevoir le résultat recherché.',
        clause: "<p>J'ai lu et j'accepte <a href=\"/terms-of-use\">les conditions d'utilisation</a>, le contrat et <a href=\"/privacy-Policy\">la politique de confidentialité</a>.</p>",
        startBtn: "DÉMARRER LA GÉOLOCALISATION",
        nowBtn: "LOCALISER LE MOBILE",
        advantage: {
            title: 'l\'avantage de notre service',
            oneTitle: 'Tous lès opérateur',
            oneDesc: 'Notre système de localisation est compatible avec tous lès opérateur et entreprise de téléphone mobile.',
            twoTitle: 'Tous marque de téléphone mobile',
            twoDesc: 'Nous sommes en mesure de suivre un téléphone mobile de n\'importe quelle marque grâce à notre système de géolocalisation.',
            threeTitle: 'Couverture mondiale',
            threeDesc: "La technologie de notre service de géolocalisation par satellite est précise et accessible sur tout le territoire à condition que le mobile recherché dispose d'Internet.",
            fourTitle: 'Conformément à la législation',
            fourDesc: 'Ce service respect les réglementation de l’APED. La géolocalisation téléphone se fait uniquement si la personne recherchée est d’accord.',
        },
        comment:{
            title: 'Découvrez ce que disent nos clients',
            oneDesc: "J'ai embauché le service et cela a résolu mon problème presque instantanément et aussi facilement que possible. Maintenant je peux suivre mon portable quand je veux, Je ne peux pas être plus heureux avec la page!",
        },
        map:{
            miniText: 'LOCALISATION DE MOBILE',
            title: 'La géolocalisation ultra-précise',
            titleDesc: 'Le suivi de n\'importe quel mobile de n\'importe quelle marque, modèle, opérateur est désormais possible. Trouvez la personne que vous recherchez en quelques instants.',
            desc: 'Une fois le numéro de téléphone de la personne attribué à l\'interface, nous vous déploierons la solution avec une marge d\'erreur minimale. Notre force réside en la précision de nos algorithmes. Une fois le téléphone portable localisé, vous recevrez l\'emplacement exact sous la forme d\'une carte.'
        },
        how: {
            title: 'Comment ça marche',
            titleD: 'Notre système de géolocalisation vous permettra de localiser un téléphone portable',
            oneDesc: 'Pour que cela fonctionne, il vous suffit d\'indiquer le numéro de mobile que vous souhaitez géolocaliser et de saisir le numéro de mobile sur lequel vous souhaitez recevoir le résultat par SMS. Pour permettre la géolocalisation du numéro recherché, une URL sera envoyée par SMS. La personne qui souhaite se géolocaliser doit être connectée à Internet et avoir activé le paramètre de localisation sur son téléphone. Nous restituons alors l\'autorisation de l\'utilisateur et sa position géographique précise. Le résultat est communiqué directement au plaignant par SMS. La réponse est donnée via un système de cartographie qui localiser le mobile recherché dans un rayon de quelques mètres. La carte est adaptée pour permettre le zoom ou pour afficher le nom des rues.',
            twoDesc: 'Le service ne peut pas fonctionner si le mobile est éteint. Une fois le processus terminé, l\'emplacement exact vous sera envoyé.',
            threeDesc: 'Il est nécessaire de préciser que selon la réglementation applicable aux données collectées, la géolocalisation implique la collecte et le traitement de données personnelles et, par conséquent, le service est soumis à la réglementation sur la protection de la vie privée. En effet, notre service doit respecter les obligations légales qui lui sont imposées, principalement l\'obtention de l\'autorisation préalable de l\'utilisateur pour être géolocalisé.'
        },
        sfg: {
            title: 'Étapes pour la géolocalisation',
            step: 'Étape',
            stepOne: 'Entrez le numéro de téléphone de la personne que nous souhaite localiser',
            stepTwo: 'Effectuez votre paiement par carte bancaire (paiement sécurisé)',
            stepthree: 'Entrez votre numéro de téléphone pour recevoir le résultat de la géolocalisation',
            stepFour: 'Vous recevrez un SMS sur votre mobile vous indiquant le résultat de la géolocalisation',
        },
        fq:{
            title: 'Questions fréquentes',
            text: 'Trouvez les réponses aux questions les plus fréquemment posées',
            textM: 'Si vous avez encore des questions, n’hesitez pas à nous contacter personnellement',
            oneTitle: 'EST-CE LÉGAL DE FAIRE SUIVRE QUELQU’UN ?',
            oneDesc: 'On devrait avoir des raisons légitimes de suivre quelqu\'un. Il est illégal de localiser quelqu\'un sans le consentement préalable de cette personne. Cependant, il est tout à fait légal de suivre quelqu\'un si vous possédez le téléphone portable que vous souhaitez localiser, obtenez l\'autorisation préalable de quelqu\'un ou si vous souhaitez localiser des enfants mineurs pour leur sécurité.',
            twoTitle:'COMBIEN DE TEMPS POUR LOCALISER?',
            twoDesc: 'Le suivi de localisation n\'est qu\'à quelques clics. Un lien SMS est envoyé à la personne en moins d\'une minute et vous pouvez vérifier l\'emplacement une fois que la personne a donné son accord pour localiser son emplacement.',
            threeTitle: 'MES INFORMATIONS SON-ELLES COMPLÈTEMENT SÉCURISÉES?',
            threeDesc: 'Oui, ce service ne stocke aucune donnée ou information sur son serveur. Personne ne peut accéder à vos informations. Vous avez un contrôle total sur vos informations.',
            fourTitle: 'COMBIEN ÇA COÛTE POUR LOCALISER UN TÉLÉPHONE MOBILE?',
            fourDesc: 'Le marché vous propose divers services gratuits, mais la plupart d\'entre eux sont des sites malveillants et ils ne fournissent pas de résultat garanti. Il est préférable d\'obtenir un service fiable et abordable qui vous donne un résultat précis. Phone Locator est un service digne de confiance qui ne coûte que 0.83€ pour 48 heures.',
            goPhone: 'N’attendez plus et localisez un mobile maintenant'
        },
        price: {
            title: 'Prix',
            trialTitle: 'Offre d\'essai',
            trialDay: '48 HEURES',
            trialDesc: 'Au bout de 48 heures et sans annulation, notre offre sera automatiquement renouvelée en abonnement sans engagement, au prix de 33.36 € par mois.',
            buyTipsF: 'Notre service de géolocalisation est un service de paiement avec un essai de',
            buyTipsC: 'pendant deux jours. Si vous n\'annulez pas votre abonnement, il sera renouvelé automatiquement et vous serez facturé',
            buyTipsL: 'par mois.',
            monthTitle: 'Mensuel',
            month: 'MOIS',
            monthDesc: 'Service d\'abonnement mensuel que vous pouvez annuler à tout moment'
        },
        pay:{
            TrialTime:'Essai de 48 Heures - Sans Permanence',
            zipUP: 'ZIP',
            pay: 'Payer'
        }
    },
    login: {
        title: 'S\'identifier',
        e_amil: 'E-MAIL',
        passwordUP: 'LE MOT DE PASSE',
        forgetPassword: 'Mot de passe oublié',
        enterUP :"ENTRER",
        startLocating :"Commencez à localiser",
        rpDesc:"Tu ne veux pas être ici? - Retour à la",
        rpUP :"RÉCUPÉRER MOT DE PASSE",
        sendCodeUP: 'ENVOYER LE CODE',
        codeUP: 'CODE',
        submitUP: 'NOUS FAIRE PARVENIR',
        login: 'Connexion',
        backTo: 'Retour à',
    },
    account:{
        title: 'Mon compte',
        titleBtn: 'LOCALISER LE TÉLÉPHONE',
        accountUP: 'COMPTE',
        historyUP: 'L\'HISTOIRE',
        accountTitle: 'Paramètres du compte',
        accountBtn: 'SAUVEGARDER LES MODIFICATIONS',
        historyOneTitle: 'Historique de paiement',
        historyTwoTitle: 'Historique des envois',
        date:"Date",
        payment :'Paiement',
        noData: 'Pas de données disponibles',
        phoneNumber: 'Numéro de téléphone',
        state:"État",
    },
    unsubscribe:{
        title: 'Désinscrire',
        tipsErr: 'L\'email que vous avez saisi n\'existe pas dans notre base de données clients. Pouvez-vous écrire l\'e-mail que vous avez créé le compte?',
        success: 'L\'annulation du service a été réussie. Vous recevrez un email de confirmation. Les frais sur votre carte de crédit s\'arrêteront.',
        desc: 'Pour vous désinscrire des services de localisation, veuillez cliquer sur le lien ci-dessous. Nous étudierons votre demande immédiatement. Les frais cesseront et les abonnements ne seront pas mis à jour. Nous vous enverrons un e-mail de confirmation (si vous n\'avez pas reçu l\'e-mail, veuillez vérifier le dossier "Spam")',
        emailPlaceholder: 'Entrer votre Emai',
        cancelBtn:'ANNULER MAINTENANT'
    },
    contact:{
        time: 'Sun - Thu (09:00 - 19:00)'
    },
    getLocation:{
        title: 'Merci!',
        desc:"Partager c'est aimer! Merci pour votre contribution. Maintenant, commencez à suivre l'emplacement du numéro de téléphone!",
        textM :"Traqueur de numéro de téléphone",
        btn: 'Vue'
    },
    locationPhone:{
        tips: 'Format de numéro de téléphone incorrect',
        oneLabel: 'Le numéro de téléphone que vous souhaitez localiser',
        towLabel: 'Entrez votre numéro pour recevoir le résultat',
        threeLabel: 'Format SMS',
        smsListOne: 'Bonjour, un de vos proches vous cherche.',
        smsListTow: 'Bonjour, une personne vous recherche.',
        smsListThree: 'Bonjour, ta mère te cherche.',
        smsListFour: 'Bonjour, votre père vous cherche.',
        smsListFive: 'Bonjour, votre femme vous cherche.',
        smsListSix: 'Bonjour, vos parents vous recherchent.',
        fourLabel:"Aperçu personnalisé",
        descTitle: 'Passer par différentes étapes de géolocalisation',
        descContent: "Notre service de localisation mobile vous permet de connaître la localisation exacte des personnes. La localisation géographique est confidentielle et le numéro du demandeur est anonyme. Tout le traitement est effectué via notre système, il n'y a pas de lien direct. Pour obtenir la localisation géographique, il vous suffit de saisir le numéro de l'appareil mobile recherché pour recevoir l'adresse de la localisation par mail. Le résultat est complété par un e-mail contenant un lien, qui vous renverra vers une carte des emplacements géographiques. Vous pouvez alors zoomer sur la carte et afficher les noms des rues. La géolocalisation ne peut fonctionner que lorsque le téléphone est allumé. Si le téléphone n'a pas de batterie, la géolocalisation ne fonctionnera pas immédiatement. Mais notre service de localisation démarrera lorsque le téléphone sera allumé. À ce moment-là, vous recevrez l'emplacement et les horodatages.",
        btn: 'TROUVER UN EMPLACEMENT'
    },
    map:{
        locaOf:'Emplacement de ',
        desc: "Le service vous demande d'envoyer un message au destinataire pour lui indiquer que vous souhaitez connaître sa localisation exacte.",
        sendUP: 'ENVOYER',
        smsUP: "SMS",
        email: 'Email',
        messenger:"Messenger",
        whatsApp:"WhatsApp",
        copyLink:"Copier le lien",
        wait:'La localisation est en cours de traitement, et le résultat sera envoyé dans votre boîte mail par email. Si le téléphone que vous recherchez est actif, le processus de recherche peut prendre quelques minutes. Dans le cas contraire, le service ne sera activé que lorsque le téléphone sera rallumé, et vous recevrez l\'e-mail dans les 48 heures maximum.'
    },
    success:{
        titleListOne:'Vérification du numéro de téléphone',
        titleListTwo:'Connexion à la base de données',
        titleListThree:'Opérateur de détection',
        titleListFour:'Réception de l\'emplacement actuel',
        titleTwo: 'Le numéro est disponible pour être localisé',
        errTitle: 'Payer avec une autre carte',
        errBtn: 'PROCÉDER AU PAIEMENT'
    },
    revealLocation: {
        successText: 'Position géographique ',
        errText: 'Emplacement géographique introuvable',
        marker: 'Localisation du mobile'
    },
    cookie:{
        title: 'Politique de cookies',
        update: 'Dernière mise à jour: 17 juin 2021',
        summary:{
            title: 'Résumé',
            one: "Cookies utilisés: propres et tiers",
            two:"Sont-ils utilisés pour obtenir des statistiques? Oui",
            three:"Sont-ils utilisés pour la publicité? Non",
            four: 'Services tiers qui installent des cookies via ce site Web: Google Analytics, Google Maps et Stripe'
        },
        how:{
            title: 'Comment configurer et désactiver les cookies',
            desc: 'Vous pouvez autoriser, bloquer ou supprimer les cookies installés sur votre appareil à partir de votre navigateur Web. Si vous n\'autorisez pas l\'installation de cookies sur votre appareil, l\'expérience de navigation peut se détériorer. Vous trouverez ci-dessous comment configurer les cookies dans chaque navigateur:',
            one: 'Edge',
            two: 'Google Chrome',
            three:'Google Android',
            four: 'Internet Explorer 7 et 8',
            five: 'Internet Explorer 9',
            six: "Internet Explorer 10",
            seven: 'Internet Explorer 11',
            eight:"Mozilla Firefox",
            nine: 'Opera',
            ten:"Safari",
            eleven: 'Safari Mobile'
        },
        type: {
            title: '1.- Types et utilisations',
            desc: 'Le service utilise ses propres cookies et des cookies tiers pour améliorer la navigation, fournir le service et obtenir des statistiques sur l\'utilisation du site Web.'
        },
        what:{
            title: '2.- Qu\'est-ce qu\'un cookie et à quoi sert-il?',
            desc:'Un cookie est un fichier texte qui est envoyé au navigateur Web de votre ordinateur, mobile ou tablette et qui est utilisé pour stocker et récupérer des informations sur la navigation effectuée. Par exemple, mémoriser votre nom d\'utilisateur et votre mot de passe ou vos préférences de profil.'
        },
        whatType:{
            title:'3.- Quels types de cookies existe-t-il?',
            accordingTitle: 'Selon qui les gère:',
            accordingOne: '<u>Propres cookies;</u>Ils sont envoyés à votre navigateur depuis nos ordinateurs ou domaines Web.',
            accordingTwo: '<u>Cookies tiers;</u> Ils sont envoyés à votre navigateur à partir d\'un ordinateur ou d\'un domaine Web que nous ne gérons pas directement, mais d\'une entité tierce telle que Google.',
            dependingTitle: 'En fonction de l\'heure à laquelle ils sont actifs, il y a:',
            dependingOne: "<u>Cookies de session;</u> ils restent dans le fichier cookie de votre navigateur jusqu'à ce que vous quittiez le site Web, de sorte qu'aucun ne reste sur votre appareil",
            dependingTwo: "<u>Cookies persistants;</u> ils restent sur votre appareil et notre site Web les lit à chaque fois que vous effectuez une nouvelle visite. Ces cookies cessent de fonctionner après une date précise.",
            finallyTitle: 'Enfin, et selon sa finalité, nous avons:',
            finallyOne: '<u>Cookies techniques;</u> améliorer la navigation et le bon fonctionnement du web.',
            finallyTwo: '<u>Cookies de personnalisation;</u> Ils permettent l\'accès au service avec des caractéristiques prédéfinies en fonction d\'une série de critères..',
            finallyThree: '<u>Cookies d\'analyse;</u> permettent de mesurer et d\'analyser statistiquement l\'utilisation du service fourni.',
        },
        used:{
            title: 'Cookies utilisés par Phone Locator:',
            name: 'Nom',
            origin: 'Origine',
            purpose: 'Objectif',
            type: 'Taper',
            expiration:'Expiration',
            nameOne: '_ga',
            nameTwo: '_gat',
            nameThree: 'NID',
            namefour: 'SNID',
            nameFive: 'OGPC',
            nameSix: 'XSRF-TOKEN',
            nameSeven: 'laravel_session',
            nameEight: 'remember_web',
            originOne: 'Phone Locator',
            originTwo: 'Google com',
            originThree: 'Google is',
            originFour: 'Google com',
            originFive: 'OGPC',
            originSix: 'XSRF-TOKEN',
            originSeven: 'laravel_session',
            originEight: 'remember_web',
            purposeOne:'Analytique',
            purposeTwo:'Technique',
            typeOne: 'Third',
            typeTwo: 'Own',
            expirationOne:"2 années",
            expirationTwo:"10 minutes",
            expirationThree:"4 mois",
            expirationFour:"6 mois",
            expirationFive:"---",
            expirationSix:"Session",
        },
        thirdParty :{
            title: 'Liste des cookies tiers utilisés par Phone Locator: :',
            ga: 'Google Analytics:',
            gaDesc: 'Ses cookies (_ga, _gat) permettent de compter le nombre de fois qu\'un utilisateur visite un site Web, comment, quand et d\'où il le fait.',
            gaDescTwo: "Dans tous les cas, vous pouvez désactiver les cookies de Google Analytics à partir ",
            here: 'd\' ici.',
            google: 'Google:',
            googleDesc: 'vos cookies (NID) vous permettent de personnaliser la façon dont les annonces sont vues en dehors de Google ou de stocker des informations telles que la langue préférée lors de l\'affichage des résultats de recherche.',
            googleDescTowFirst: 'Dans tous les cas, vous trouverez ici ',
            googleDescTowC: 'plus d\'informations',
            googleDescTowLast: 'sur les types de cookies que Google utilise dans ses multiples services.',
            googleMaps: 'Google Maps:',
            googleMapsDesc: 'Ses cookies (SNID, OGPC) permettent de mémoriser des préférences telles que le niveau de zoom et de vérifier comment les utilisateurs utilisent les cartes intégrées sur d\'autres sites Web.',
            stripe: 'Stripe:',
            stripeDesc: 'le service permet le paiement de l\'abonnement pour l\'utilisation du localisateur.'
        }
    },
    privacyPolicy:{
        title: 'Politique de confidentialité',
        update: 'Dernière mise à jour: 17 juin 2021',
        description:{
            title: '1.- Description',
            one: 'L\'acceptation de la politique de confidentialité de Phone Locator (ci-après «Politique de confidentialité»), est une condition nécessaire à l\'utilisation de notre service Phone Locator (ci-après «Service»)',
            two: 'Cette politique de confidentialité régit la collecte, le traitement et l\'utilisation de vos informations personnelles et non personnelles en tant qu\'utilisateur du service, à partir de la date d\'entrée en vigueur qui apparaît dans l\'en-tête.',
            three: 'Phone Locator ne vend pas vos informations personnelles à des tiers et vous demandera toujours votre consentement avant d\'utiliser vos données à des fins autres que celles décrites dans la présente politique de confidentialité.',
            four: 'Pour traiter vos données personnelles, Phone Locator se conforme à la législation applicable en vigueur, ainsi qu\'à ses règlements d\'application.',
            five: 'Par conséquent, il adopte les mesures techniques et organisationnelles nécessaires pour empêcher la perte, l\'utilisation abusive, l\'altération, l\'accès non autorisé et le vol des données personnelles fournies. Toujours en fonction de l\'état de la technologie, de la nature des données et des risques auxquels elles sont exposées.'
        },
        informationCollected:{
            title: '2.- Informations collectées',
            descOne: 'Pour utiliser le Service, l\'utilisateur doit créer un profil au moment de la demande du Phone Locator d\'un appareil mobile.',
            descTwo: 'Les informations (personnelles ou non personnelles) collectées par le Service varieront en fonction de cela.',
            descThree: 'Les informations personnelles et non personnelles collectées par le Service nous parviendront de trois manières: 1) celles collectées automatiquement 2) celles que vous nous fournissez volontairement et 3) celles fournies par des tiers.',
            one: {
                title: '2.1.- Celui collecté automatiquement',
                desc: 'Ces informations comprendront:',
                one: 'Celui collecté via des cookies ou des mécanismes similaires stockés sur votre appareil, toujours avec votre consentement. Consultez notre politique de cookies pour plus d\'informations.',
                two: 'L\'adresse IP à partir de laquelle la connexion est établie, le type d\'appareil utilisé et ses caractéristiques, la version du système d\'exploitation, le type de navigateur, la langue, la date, le pays, l\'heure de la demande, l\'URL de référence ou le réseau mobile utilisé (entre autres).',
                three: 'Données d\'utilisation du service et erreurs éventuelles détectées lors de son utilisation.',
                four: 'Données sur votre emplacement physique à l\'aide de différentes technologies: signaux GPS envoyés par un appareil mobile ou informations sur les points d\'accès Wi-Fi ou les antennes de téléphone mobile les plus proches. Dans tous les cas, ces données sont toujours collectées en fonction des paramètres de confidentialité de votre appareil.',
                five: 'En outre, Phone Locator utilise Google Analytics, un service d\'analyse fourni par Google, Inc. domicilié aux États-Unis dont le siège social est situé au 1600 Amphitheatre Parkway, Mountain View, Californie 94043. Pour la fourniture de ces services, ils utilisent des cookies qui collectent les informations, y compris l\'adresse IP de l\'utilisateur, qui sera transmise, traitée et stockée par Google dans les conditions définies sur le site www.google.com . Y compris la transmission éventuelle desdites informations à des tiers pour des raisons d\'obligation légale ou lorsque ces tiers traitent les informations pour le compte de Google.',
                six: 'Dans tous les cas, vous pouvez désactiver les cookies de Google Analytics à partir d\' ici <a href="https://tools.google.com/dlpage/gaoptout">here</a>.'
            },
            two:{
                title:'2.2.- Celui que vous fournissez volontairement',
                one: 'Celui requis par Phone Locator dans la création d\'un profil d\'utilisateur dans le Service, en indiquant au minimum les informations marquées d\'un astérisque sur certains champs: email, téléphone, nom et prénom et informations de paiement.',
                two: 'Vous devez également ajouter le numéro de téléphone mobile que vous souhaitez localiser. Vous reconnaissez avoir obtenu ce numéro que vous comptez localiser de manière légale et avec le consentement préalable de son propriétaire.',
                three: 'En ce qui concerne le mot de passe, l\'Utilisateur sera responsable de sa protection. En ce sens, il assumera tous les dommages pouvant résulter de son utilisation non conforme ou du transfert de celui-ci à des tiers.',
                four: 'Les informations, personnelles ou non, que les messages envoyés via les canaux de contact ou d\'opinion établis dans le Service peuvent contenir, par exemple votre nom ou votre email',
                five: 'L\'e-mail que vous fournissez pour vous inscrire à la newsletter. Si vous utilisez l\'e-mail d\'un tiers, vous reconnaissez avoir son consentement pour le faire.'
            },
            three: {
                title: '2.3.- Celui fourni par des tiers',
                one: 'Celui fourni par les systèmes de paiement ou les processeurs de cartes de crédit, comme le moment de l\'achat ou son montant',
                two: 'Celui fourni par les entreprises de messagerie tels que l\'heure, le lieu et le jour de la livraison.'
            },
            four: {
                title: '2.4.- Le tiers qui consent à la localisation, transférera des informations automatisées sur son appareil comme indiqué dans la section 2.1, son numéro de téléphone et sa localisation spécifique et spécifique pour le moment où il a consenti à le faire connaître.',
                one: 'En aucun cas, le lieu ne dépassera le moment précis où il a été accepté.',
                two: 'Le tiers qui ne consent pas à la localisation, donnera à Phone Locator des informations automatisées sur son appareil comme indiqué dans la section 2.1, son numéro de téléphone et le nom auquel il aurait été associé.',
                three: 'Vous pouvez exercer à tout moment les droits d\'accès, de rectification, d\'annulation et d\'opposition de vos données personnelles par courrier électronique à:',
                four: 'Dans les deux cas, vous devez vous identifier avec vos nom et prénom, ainsi qu\'une copie de votre DNI ou de votre carte d\'identité nationale.',
                five: '<a href="https://www.google.com/url?q=http://www.agpd.es/portalwebAGPD/CanalDelCiudadano/derechos/principales_derchos/index-ides-idphp.php&sa=D&ust=1496145830315000&usg=AFQjCNGfpKOUK22rT9u5bvVklQ1ES-R_cQ"Ici</a> vous trouverez les différents modèles d\'exercer ces droits.'
            },
        },
        rights:{
            title: '3.- Droits et finalités',
            one: 'Nous vous informons que le remplissage des formulaires est volontaire. Cependant, si vous ne remplissez pas les champs obligatoires (marqués d\'un astérisque), l\'utilisation du Service ne sera pas possible ou sera limitée',
            two: 'Les données personnelles que vous nous fournissez sont incorporées et seront traitées dans les fichiers, afin de pouvoir répondre à vos demandes, fournir le service demandé et vous tenir informé des problèmes liés à l\'activité de l\'entreprise et de ses services.',
            three: 'Vous pouvez exercer à tout moment les droits d\'accès, de rectification, d\'annulation et d\'opposition de vos données personnelles par courrier électronique à:',
            four: 'Dans les deux cas, vous devez vous identifier avec vos nom et prénom, ainsi qu\'une copie de votre DNI ou de votre carte d\'identité nationale.',
            five: 'Phone Locator s\'engage à respecter la confidentialité des données collectées dans le fichier, à les utiliser conformément à sa finalité, à se conformer à son obligation de conservation et à adopter toutes les mesures nécessaires pour éviter l\'altération, la perte, le traitement ou l\'accès à des données non autorisées, conformément à la réglementation en vigueur.',
            six: '<a href="https://www.google.com/url?q=http://www.agpd.es/portalwebAGPD/CanalDelCiudadano/derechos/principales_derchos/index-ides-idphp.php&sa=D&ust=1493218316003000&usg=AFQjCNEe-8VXcChQpwRKlbVrVL2P_ynmEw">Ici</a> vous pouvez trouver les différents modèles pour exercer ces droits.',
        },
        use:{
            title: '4.- Utilisation des données',
            one: 'Phone Locator utilisera les données collectées pour:',
            two: 'Gérer, fournir et mettre à jour le service.',
            three: 'Effectuer la localisation demandée, à condition qu\'elle soit acceptée par le destinataire Répondez aux questions que vous posez ',
    
            four: 'Répondez aux questions que vous posez.',
            five: 'Traitez les paiements que vous effectuez.',
            six: 'Vous envoyer par e-mail, si vous l\'avez autorisé ou tel que prévu dans la réglementation applicable, notre newsletter et / ou les offres de nos partenaires commerciaux.',
            seven: 'Vous pouvez vous désinscrire de l\'e-mail reçu, de votre compte utilisateur ou en nous contactant à <a href="mailto:Findmyphone@chamspy.com">Findmyphone@chamspy.com</a>. Cependant, vous ne pourrez pas vous désabonner de certaines correspondances de notre part, telles que les messages liés à la sécurité de vos données ou aux termes et conditions du Service.',
            eight: 'Planifier et développer les activités commerciales du Service, telles que la publicité, directement ou par le biais de tiers collaborateurs.',
            nine: 'Maintenir la sécurité du Service, enquêter sur les activités illégales, appliquer nos conditions générales et assister les forces de sécurité de l\'État dans le cadre de leurs enquêtes éventuelles.',
            ten: "De même, Phone Locator peut utiliser les informations personnelles et non personnelles des utilisateurs sous la forme de données agrégées et anonymes pour les montrer à des tiers. Vous pouvez également partager des statistiques et des informations démographiques sur les utilisateurs et leur utilisation du Service avec des tiers. Rien de tout cela ne permettra à ces tiers de vous identifier personnellement."
        },
        delete: {
            title: '5.- Supprimer le compte',
            one: 'Vous pouvez supprimer votre compte en demandant sa suppression à ',
            two: 'Si vous décidez de supprimer votre compte, toutes vos données seront supprimées, y compris les copies de sauvegarde, dans un délai maximum de 7 jours.'
        },
        data:{
            title: '6.- Données partagées avec des tiers',
            one: 'Il existe des tiers qui gèrent une partie du Service.',
            two: 'Phone Locator les oblige à se conformer à cette politique de confidentialité dans ce qui leur est applicable, en plus d\'avoir la leur. Cependant, Phone Locator ne sera pas responsable du respect de ladite politique.',
            three:'Dans certaines circonstances, Phone Locator peut partager, utiliser ou conserver certaines des informations personnelles collectées avec des tiers:',
            four: 'Pour fournir le service:',
            five: 'Les fournisseurs de services qui exécutent des fonctions en notre nom, telles que le traitement des cartes de crédit, le processus de localisation, l\'hébergement Web, l\'analyse commerciale, le service client ou le marketing. Ces prestataires de services peuvent collecter et avoir accès aux informations qui leur sont nécessaires pour s\'acquitter de leurs fonctions, mais ils ne sont pas autorisés à partager ou utiliser les informations à d\'autres fins.',
            six: "Pour coopérer avec les autorités compétentes:",
            seven: 'Si nous pensons qu\'il est raisonnablement nécessaire de satisfaire une loi, une procédure légale ou un intérêt légitime dans n\'importe quelle partie du monde. Dans tous les cas, nous ne fournirons que les informations strictement requises.',
            eight:"Si nous pensons que cette action est appropriée pour appliquer les termes et conditions de Phone Locator",
            nine: 'Si nécessaire pour détecter ou prévenir la fraude ou les problèmes liés à la sécurité de Phone Locator, de ses fournisseurs et utilisateurs',
        },
        security:{
            title: '7.- Mesures de sécurité',
            one: 'Phone Locator adopte toutes les mesures techniques et organisationnelles nécessaires pour protéger la sécurité et l\'intégrité des informations personnelles et non personnelles collectées. À la fois contre l\'accès non autorisé et l\'altération, la perte ou la destruction accidentelles.',
            two: 'L\'envoi et la remise des données que vous effectuez via le Service sont protégés par des techniques de sécurité électronique sur le réseau telles que le protocole SSL. De même, les informations fournies et stockées dans les bases de données Phone Locator sont également protégées par des systèmes de sécurité qui en empêchent l\'accès par des tiers non autorisés.',
            three: 'Phone Locator fait ses meilleurs efforts pour disposer des systèmes les plus à jour pour l\'efficacité de ces mécanismes de sécurité. De plus, Phone Locator stocke des informations personnelles et non personnelles aussi longtemps que la réglementation le permet et l\'exige. Celui qui n\'est pas nécessaire est périodiquement détruit.',
            four: 'Malgré ce qui précède, Phone Locator ne peut garantir la sécurité absolue des informations collectées, vous devez donc collaborer et faire preuve de bon sens concernant les informations partagées à tout moment.',
        },
        changes:{
            title: '8.- Modifications de la politique de confidentialité',
            one: 'Nous pouvons mettre à jour cette politique de confidentialité à l\'avenir.',
            two: 'Nous vous informerons de ses modifications en envoyant un avis à l\'adresse e-mail fournie et / ou en plaçant un avis à un endroit bien en vue sur notre site Web.'
        },
        contact:{
            title: '9.- Contact',
            one: 'Si vous avez des questions sur cette politique de confidentialité, contactez-nous à:',
            two:'Adresse: Unit 1318-19, Hollywood Centre, 610 Nathan Road, Mongkok, Kowloon, Hong Kong'
        }

    },
    terms:{
        title: 'Conditions d\'Utilisation et Contrat',
        update: 'Dernière mise à jour: 17 Juin 2021',
        ownership:{
            title: '1. Propriété',
            desc: 'Conformément à la loi 34/2002 sur les services de la société de l\'information et du commerce électronique (LSSICE), nous vous informons que ce site est détenu et exploité par SOOUYA TECHNOLOGY dont le siège social est situé à Unit 1318-19, Hollywood Center, 610 Nathan Road, Mongkok, Kowloon, Hong Kong.'
        },
        acceptance:{
            title: '2. Acceptation',
            one: 'Ce site Web et le service de localisation par SMS appelé Phone Locator (ci-après le Service) est accessible à tout utilisateur, pour sa propre consommation, et sous réserve des conditions générales suivantes : les présentes Conditions d\'utilisation et du Contrat (ci-après CUC), notre politique de confidentialité et notre politique de cookies.',
            two: 'Lorsque vous utilisez le Service, vous acceptez ces CUC et le reste des termes et conditions.',
            three: 'En faisant cela, vous acceptez de ne pas utiliser ce site à des fins illégales.'
        },
        description:{
            title: '3. Description du service',
            one: 'Grâce au Service, Phone Locator vous permet de localiser la position actuelle d\'une personne qui consent à être localisée. Le Service vous permet d\'envoyer un SMS au destinataire pour indiquer que quelqu\'un souhaite connaître sa localisation. Si le destinataire du message accepte expressément, la géoposition du destinataire à ce moment précis est partagée par le demandeur.',
            two: 'En aucun cas Phone Locator ne va au-delà du moment précis où il a été accepté.',
            three: 'Le paiement mensuel du service ne garantit pas que la personne recherchée puisse être localisée, il fournit uniquement les moyens d\'y parvenir mais en cas de disparition de la personne, avec le téléphone portable volé, éteint ou n\'accepte pas le lien que nous envoyons dans le SMS, nous ne pouvons pas obtenir votre position GPS.'
        },
        external:{
            title: '4. Liens externes',
            one: 'Vous pouvez être envoyé du Service vers d\'autres sites Web via des liens.',
            two: 'Cependant, Phone Locator ne contrôle pas ces sites ou leur contenu, qui sont en fait soumis à leurs propres conditions générales. Par conséquent, Phone Locator n\'est PAS responsable de la qualité, de la véracité ou de l\'exactitude de ces sites.',
            three: "Lorsque vous vous inscrivez à notre application, vous acceptez que nous puissions vous envoyer nos mises à jour et nos messages promotionnels. Si vous ne souhaitez plus les recevoir, vous pouvez nous envoyer un e-mail à Findmyphone@chamspy.com"
        },
        age:{
            title: '5. Âge',
            one: 'En ce qui concerne l\'utilisation du Service, vous déclarez que vous avez l\'âge légal et que vous avez la capacité juridique nécessaire pour être lié par cet accord et pour l\'utiliser conformément à ses termes et conditions, que vous comprenez et reconnaissez pleinement.',
            two: 'Vous déclarez que toutes les informations que vous fournissez pour accéder au Service, avant et pendant son utilisation, sont vraies, complètes et exactes.',
        },
        intellectual:{
            title: '6. Propriété intellectuelle et industrielle',
            one: 'Le contenu et les informations du Service (entre autres données, texte, son, image ou code informatique), ainsi que l\'infrastructure utilisée pour fournir ces contenus et informations, sont la propriété de Phone Locator ou dispose des autorisations correspondantes pour son utilisation.',
            two: 'En outre, la modification, la reproduction, la duplication, la copie, la distribution, la vente, la revente et autres formes d\'exploitation à des fins commerciales ou équivalentes du Service sont interdites.',
            three: 'Pour toute autre utilisation du contenu du Service, vous avez besoin de notre consentement écrit préalable.'
        },
        user:{
            title: '7. Contenu utilisateur',
            one: 'Vous pouvez contribuer au Service en nous envoyant des messages à notre adresse e-mail ou via le formulaire de contact (ci-après «Contenu»).',
            two: 'Nous pouvons utiliser votre contenu de différentes manières, telles que: l\'afficher sur le site Web, le reformater, le traduire dans d\'autres langues, le modifier pour plus de clarté, corriger les erreurs, le promouvoir ou le distribuer. Par conséquent, en nous soumettant du contenu, vous accordez à Phone Locator une licence d\'utilisation mondiale gratuite, non exclusive, en attendant la suppression du contenu, transférable et sous-licenciable sur ce contenu.',
            three: 'Par conséquent, en nous soumettant du contenu, vous accordez à Phone Locator une licence d\'utilisation mondiale gratuite, non exclusive, en attendant la suppression du contenu, transférable et sous-licenciable sur ce contenu.',
            four: 'Cela signifie que le contenu reste le vôtre, mais Phone Locator, grâce à cette licence d\'utilisation, peut: a) utiliser, reproduire, modifier, adapter, traduire, distribuer et publier le contenu, en créer des œuvres dérivées, l\'afficher et l\'afficher autour le monde, par tout moyen connu et à toute fin légitime; et b) utiliser le nom que vous soumettez en relation avec ce contenu.',
            five: 'Cependant, Phone Locator se réserve le droit de ne pas publier de contenu ou d\'informations fausses, illégales, trompeuses ou contraires aux droits de tiers.'
        },
        price:{
            title: '8. Prix et taxes',
            one: 'Les prix des plans proposés sur Phone Locator sont indiqués en euro (€) et incluent la taxe sur la valeur ajoutée (TVA) applicable à ces services.',
        },
        payment:{
            title: '9. Modes de paiement',
            one: 'Le paiement du Service contracté peut être effectué:',
            two: '- Par carte de crédit ou de débit VISA ou MasterCard, facturant le montant au moment de l\'achat des produits.',
            three: 'En ce sens, Phone Locator informe les titulaires de cartes de crédit et de débit qu\'il est responsable des transactions dans la boutique en ligne. Ils sont réalisés sur une page sécurisée, en utilisant la technologie SSL pour garantir la sécurité de la transmission des données.'
        },
        validity:{
            title: '10. Validité des offres',
            one: 'Le Service proposé, et les prix de ses éventuels plans ou modalités, seront disponibles à l\'achat tant qu\'ils seront accessibles via ce site Web.',
            two: 'Dans tous les cas, Phone Locator se réserve le droit d\'apporter les modifications qu\'il jugera appropriées au Service, en pouvant mettre à jour ses produits et services en fonction du marché.',
            three: 'En ce qui concerne les prix affichés sur le Web, Phone Locator se réserve le droit de les modifier sans préavis.',
            four: 'Nous vous informons que malgré les mises à jour apportées aux prix du Service, celles-ci pourraient contenir des erreurs. Nous corrigerons rapidement toutes les erreurs qui apparaissent, mais elles ne lieront pas le Service.'
        },
        guarantees:{
            title: '11. Garanties',
            one: 'Tous les droits que les lois en vigueur vous garantissent en tant que consommateur et utilisateur sont garantis.',
            two: "Dans tous les cas, bien que Phone Locator fasse tous les efforts commerciaux raisonnables pour fournir le Service, vous comprenez que: a) le Phone Locator de l'utilisateur nécessite le consentement exprès du destinataire et; b) que même avec votre consentement, l'état de la technologie ne garantit pas que votre localisation sera toujours obtenue ou qu'elle pourra être effectuée avec une précision suffisante.",
            three: 'Dans tous les cas, et dans la mesure permise par la loi, Phone Locator se réserve le droit de rejeter, suspendre ou annuler la fourniture du Service à sa seule discrétion.'
        },
        responsibility:{
            title: '12. Responsabilité',
            one: 'En utilisant le Service, vous confirmez que: a) vous avez obtenu le numéro de téléphone que vous avez l\'intention de localiser de manière légale et avec le consentement préalable de son propriétaire et; b) que vous n\'avez pas l\'intention d\'utiliser le Service à des fins illicites.',
            two: 'D\'autre part, dans la mesure permise par la législation applicable, Phone Locator ne sera pas responsable de la perte éventuelle de données ou de programmes dérivés de l\'utilisation du Service.',
        },
        security:{
            title: '13. Sécurité',
            one: 'D\'autre part, Phone Locator garantit la sécurité du Service conformément aux connaissances technologiques actuelles.',
            two: 'Cependant, Phone Locator ne peut garantir la sécurité totale du Service, bien qu\'il s\'engage à corriger et mettre en œuvre les mesures correctives appropriées pour corriger une éventuelle faille de sécurité dans les meilleurs délais.',
            three: 'L\'utilisateur s\'engage à informer le Service, immédiatement et par e-mail à  Findmyphone@chamspy.com , de toute perte d\'informations, accès non autorisé ou utilisation par des tiers ou toute situation pouvant conduire à l\'usurpation de l\'identité d\'autres utilisateurs.',
            four: 'Phone Locator ne sera pas responsable des dommages pouvant résulter de l\'utilisation du Service s\'ils sont causés par des problèmes techniques imprévisibles ou, étant prévisibles, sont inévitables. De même ',
            five: 'Phone Locator ne sera pas responsable lorsque les performances défectueuses de l\'utilisateur, qu\'il s\'agisse de l\'expéditeur ou du destinataire du message, provoquent des dommages.'
        },
        subscription:{
            title: '14. Abonnement et remboursement',
            one: 'Si vous vous abonnez à notre service de géolocalisation, vous bénéficierez d\'un essai de 48 heures pour €0.83. Dans le cas où vous n\'avez pas annulé le compte, votre abonnement au service est mensuel pour un montant de €33.36 et sera automatiquement renouvelé pour la même période jusqu\'à sa résiliation.',
            two: 'Sauf si vous annulez votre abonnement, vous nous autorisez à facturer les frais d\'abonnement mensuels correspondants à votre mode de paiement.',
            three: 'Vous pouvez annuler votre abonnement au Service à tout moment, et vous continuerez d\'y avoir accès jusqu\'à la fin de votre période de facturation mensuelle. Dans la mesure permise par la loi, les paiements ne sont pas remboursables et nous ne fournissons pas de remboursements ou de paiements pour les périodes d\'abonnement mensuelles partielles. ',
            four: 'Dans tous les cas, si vous aviez souscrit au Service, le montant correspondant aurait été facturé après le délai de grâce initial, mais vous n\'auriez envoyé aucun message, vous pouvez demander un remboursement intégral du montant payé si vous le demandez avant la fin de 14 jours naturels à compter de la location.',
            five:'Pour annuler le service ou demander un remboursement, accédez à votre profil ou contactez-nous à:  Findmyphone@chamspy.com ',
            six:'Dans le cas où la carte de crédit avec laquelle vous vous êtes inscrit au service n\'a pas de fonds au moment du paiement, le compte sera annulé au moment'
        },
        withdrawal:{
            title: '15. Retrait',
            one: 'Si vous souscrivez au Service, vous contracterez la fourniture de contenu numérique non fourni à titre de support matériel.',
            two: 'Par conséquent, et à partir du moment où le contenu a commencé à être exécuté, vous acceptez et comprenez que le droit de rétractation tel qu\'établi à l\'art. 103 m) de la loi générale pour la défense des consommateurs et des utilisateurs, texte approuvé par le décret législatif royal 1/2007 du 16 novembre.',
        },
        modifications:{
            title: '16. Modifications et invalidité',
            one: 'Nous pouvons mettre à jour les termes et conditions du Service à l\'avenir, ainsi que les caractéristiques et fonctions du Service lui-même.',
            two: 'Nous vous informerons des changements dans les termes et conditions en plaçant un avis à un endroit bien en vue sur notre site Web et / ou par e-mail.',
            three: 'Si une clause incluse dans nos conditions générales est déclarée, totalement ou partiellement, nulle ou sans effet, elle n\'affectera que ladite disposition ou la partie de celle-ci qui est nulle ou sans effet. Nos termes et conditions subsisteront dans tout le reste, ayant une telle disposition, ou la partie de celle-ci qui est affectée, en ne la plaçant pas..'
        },
        claims:{
            title: '17. Réclamations et actions découlant du contrat',
            one: 'Afin de simplifier la résolution des réclamations par le biais de procédures civiles et de réduire les coûts, nous n\'excluons pas la possibilité de se soumettre à un arbitrage en équité du Tribunal arbitral des chambres de commerce et d\'industrie.',
        },
        contact:{
            title: '18. Contact',
            one: 'Pour toute clarification, incident ou réclamation, vous pouvez nous contacter via: E- mail: ',
            two: 'Adresse: Unit 1318-19, Hollywood Centre, 610 Nathan Road, Mongkok, Kowloon, Hong Kong',
            three: 'Téléphone: '
        },
    },
    tips: {
        enterVaildEmail: "Veuillez entrer votre email valide",
        agreeTermsOrPrivacy :"Veuillez accepter nos conditions d'utilisation, notre contrat et notre politique de confidentialité.",
        passWordNoSpaces: "Mot de passe sans espaces",
        passWordNoSix: "Le mot de passe ne peut pas être inférieur à 6 chiffres",
        successModified: "Modifié avec succès",
        notLogin: "Vous n'êtes pas connecté",
        emailFormatErr: "Erreur de format d'e-mail",
        inputNoEmpty: "Le contenu d'entrée ne peut pas être vide",
        enterCorrectCode: "Veuillez saisir le bon code",
        emailNoEmpty: "L'e-mail ne peut pas être vide",
        emailIncorrect: "L'adresse e-mail saisie est incorrecte",
        codeSendEmail: "Le code de vérification a été envoyé à votre e-mail !",
        incorrectCode: "Code postal incorrect",
        paymentErr: "Erreur de paiement",
        cradNameNoEmpty: "Le nom de la carte ne peut pas être vide",
        addressNoEmpty: "L'adresse ne peut pas être vide",
        cityNoEmpty: "La ville ne peut pas être vide",
        zipNoEmpty: "Le code postal ne peut pas être vide",
        zipIncorrect: "Le code postal est incorrect",
        err: "Erreur",
        fillCorrectPostalCode: "Veuillez remplir le bon code postal",
        notLoginPleaseLogin: "Vous n'êtes pas connecté, veuillez vous connecter",
        reBuy: "Veuillez racheter après l'expiration du compte !",
        phoneNumberNotBlank: "Le numéro de téléphone ne peut pas être vide",
        phoneNumberDifferent: "Veuillez saisir deux numéros de téléphone portable différents",
        emailAndPasswordNoEmpty: "L'email et le mot de passe ne peuvent pas être vides",
        rePhoneNumberToLocate: "Veuillez ressaisir votre numéro de téléphone portable pour localiser",
        noDownApp: "Vous ne pouvez pas télécharger cette application",
        shareLinkSuccess: "Lien de partage copié avec succès",
        copyFailed: "Échec de la copie, veuillez utiliser d'autres méthodes pour partager",
        rePasswordSuccess: "Votre mot de passe a été réinitialisé avec succès.",
    }
}