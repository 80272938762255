import Vue from 'vue'
import VueRouter from 'vue-router'
// import index from '../views/index.vue'
import indexStripe from '../views/index-stripe'

Vue.use(VueRouter)

const routes = [
    // {
    //     path: '/index-lianlian',
    //     name: 'index',
    //     component: index,
    // },
    {
        path:  '/',
        name: 'Phone Locator - A Reliable Phone Number Tracker for Android & iPhone',
        component: indexStripe,
        alias: '/indexStripe'
    },
    {
        path: '/contact',
        name: 'contact',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/contact.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')
    },
    {
        path: '/forget-password',
        name: 'forget-password',
        component: () => import(/* webpackChunkName: "about" */ '../views/forget-password.vue')
    },
    {
        path: '/account',
        name: 'account',
        component: () => import(/* webpackChunkName: "about" */ '../views/account.vue')
    },
    {
        path: '/location-phone',
        name: 'location-phone',
        component: () => import(/* webpackChunkName: "about" */ '../views/location-phone.vue')
    },
    {
        path: '/pay-success',
        name: 'pay-success',
        component: () => import(/* webpackChunkName: "about" */ '../views/pay-success.vue')
    },
    {
        path: '/map',
        name: 'map',
        component: () => import(/* webpackChunkName: "about" */ '../views/myMap.vue')
    },
    {
        path: '/getLocation',
        name: 'getLocation',
        component: () => import(/* webpackChunkName: "about" */ '../views/getLocation.vue')
    },
    {
        path: '/cancel-order',
        name: 'cancel-order',
        component: () => import(/* webpackChunkName: "about" */ '../views/cancel-order.vue')
    },
    {
        path: '/recover-password',
        name: 'recover-password',
        component: () => import(/* webpackChunkName: "about" */ '../views/recover-password.vue')
    },
    {
        path: '/reveal-location',
        name: 'reveal-location',
        component: () => import(/* webpackChunkName: "about" */ '../views/reveal-location.vue')
    },
    {
        path: '/cookie',
        name: 'cookie',
        component: () => import(/* webpackChunkName: "about" */ '../views/cookie.vue')
    },
    {
        path: '/terms-of-use',
        name: 'terms-of-use',
        component: () => import(/* webpackChunkName: "about" */ '../views/terms-of-use.vue')
    },
    {
        path: '/privacy-Policy',
        name: 'privacy-Policy',
        component: () => import(/* webpackChunkName: "about" */ '../views/privacy-Policy.vue')
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
