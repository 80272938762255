import axios from 'axios'
import Router from '../router'
import store from '../store/index'
// let baseUrl = process.env.NODE_ENV === 'development' ? 'https://api.chamspy.soouya.cn/chamspy_phone/v1' : 'https://phonenumber.spyapp.org/chamspy_phone/v1'
// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    withCredentials: true, // send cookies when cross-domain requests
    timeout: 15000 // request timeout
})
// request interceptor
service.interceptors.request.use(
    config => {
        config.headers['_chamspy_token'] = localStorage.getItem('_chamspy_token') == null ? '' : localStorage.getItem('_chamspy_token')
        config.headers['website_type'] = 0
        if (config.method==='get') {

            config.params = {
                ...config.params,
                keyword: window.utm_term,
                apkChannel: window.channel,
                os: 3,
                phoneOs: 3
            }
        }else if (config.method==='post') {
            config.data = {
                ...config.data,
                keyword: window.utm_term,
                apkChannel: window.channel,
                os: 3,
            }
        }else if (config.method==='put') {
            config.params = {
                ...config.params,
                keyword: window.utm_term,
                apkChannel: window.channel,
                os: 3
            }
        }

        console.log(config)
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    response => {
        if (localStorage.getItem('_chamspy_token') != response.headers._chamspy_token) {
            localStorage.setItem('_chamspy_token',response.headers._chamspy_token)
        }
        if (response.status === 200) {
            if (response.data.success == '20012') {
                // this.toast(response.data.msg)
                store.commit('setUserInfo',null)
                localStorage.removeItem('userInfo')
                if (window.location.pathname != '/login') {
                    setTimeout(()=>{
                        Router.push({
                            path: '/login'
                        })
                    }, 3000)
                }
                return Promise.reject(response.data)
            }
            return Promise.resolve(response.data)
        }else {
            return Promise.reject(response.data)
        }
    },
    error => {
        return Promise.reject(error.data)
    }
)

export default service
