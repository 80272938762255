<template>
    <footer class="footer-box">
        <div class="content">
            <div class="logo">
                {{$t("nav.logoName")}}
            </div>
            <div class="footer-content">
                <div>
                    <h5>{{$t("footer.ourService")}}</h5>
                    <ul>
                        <li>
                            <a class="scrollTop" data-scrollTop="Advantage" @click="goIndex('Advantage')">
                                <img :src="footer_icon_Advantage" alt="">
                                <span>{{$t("nav.advantage")}}</span>
                            </a>
                        </li>
                        <li>
                            <a class="scrollTop" data-scrollTop="Price" @click="goIndex('Price')">
                                <img :src="footer_icon_Price" alt="">
                                <span>{{$t("nav.price")}}</span>
                            </a>
                        </li>
                        <li>
                            <a class="scrollTop" data-scrollTop="Operating" @click="goIndex('Operating')">
                                <img :src="footer_icon_Operating" alt="">
                                <span>{{$t("nav.operating")}}</span>
                            </a>
                        </li>
                        <li>
                            <router-link tag="a" class="scrollTop" to="/recover-password">
                                <img :src="footer_icon_recover" alt="">
                                <span>{{$t("footer.recoverPassword")}}</span>
                            </router-link>
                        </li>
                    </ul>
                </div>
                <div class="footer-more">
                    <h5>{{$t("footer.more")}}</h5>
                    <ul>
                        <!-- <li>
                            <a class="Unsubscribe-footer" @click="goPage('/cancel-order')">
                                <img :src="footer_icon_Cancel" alt="">
                                <span>Unsubscribe</span>
                            </a>
                        </li> -->
                        <li>
                            <router-link tag="a" to="/contact">
                                <img :src="footer_icon_Contact" alt="">
                                <span>{{$t("nav.contact")}}</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link tag="a" to="/terms-of-use">
                                <img :src="footer_icon_TermsofUse" alt="">
                                <span>{{$t("footer.termsOfUse")}}</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link tag="a" to="/privacy-Policy">
                                <img :src="footer_icon_Privacy" alt="">
                                <span>{{$t("footer.privacyPolicy")}}</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link tag="a" to="/cookie">
                                <img :src="footer_icon_Cookie" alt="">
                                <span>{{$t("footer.cookiePolicy")}}</span>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="footer-bootom">
            <span>Copyright © 2020 Phone Locator. </span>
            <img src="@/assets/images/price-bank1.png" alt="">
        </div>
    </footer>
</template>

<script>
    import footer_logo from '../assets/images/logo.png'
    import footer_logo_text from '../assets/images/logo-text.png'
    import footer_icon_Advantage from '../assets/images/footer-icon-Advantage.png'
    import footer_icon_Price from '../assets/images/footer-icon-Price.png'
    import footer_icon_Operating from '../assets/images/footer-icon-Operating.png'
    import footer_icon_recover from '../assets/images/footer-icon-recover.png'
    import footer_icon_Cancel from '../assets/images/footer-icon-Cancel.png'
    import footer_icon_Contact from '../assets/images/footer-icon-Contact.png'
    import footer_icon_TermsofUse from '../assets/images/footer-icon-TermsofUse.png'
    import footer_icon_Privacy from '../assets/images/footer-icon-Privacy.png'
    import footer_icon_Cookie from '../assets/images/footer-icon-Cookie.png'
    export default {
        name: "page-footer",
        data() {
            return{
                footer_logo,
                footer_logo_text,
                footer_icon_Advantage,
                footer_icon_Price,
                footer_icon_Operating,
                footer_icon_recover,
                footer_icon_Cancel,
                footer_icon_Contact,
                footer_icon_TermsofUse,
                footer_icon_Privacy,
                footer_icon_Cookie,
            }
        },
        methods:{
            goPage(url) {
                // this.amplitude.getInstance().logEvent('UnSubscribeScreen',{
                //     Source: 'Footer'
                // })
                this.eventLog('UnSubscribeScreen-Footer')
                this.$router.push({
                    path: url
                })
            },
            goIndex(str) {
                this.$emit('scroll',str)
            }
        }
    }
</script>

<style scoped>

</style>
