<template>
    <div>
        <div class="explanation-view">
            <div class="map-box">
                <div class="img-box">
                    <img class="bg" src="@/assets/images/computer-map-img.png" alt="">
                    <img class="phone-map" src="@/assets/images/phone-map-img.png" alt="">
                </div>
                <div class="text-box">
                    <p class="min-text">{{$t("index.map.miniText")}}</p>
                    <h2 class="title">{{$t("index.map.title")}}</h2>
                    <p class="title-d">{{$t("index.map.titleDesc")}}</p>
                    <p class="text">{{$t("index.map.desc")}}</p>
                    <a href="" class="positioning-btn">
                        <img src="@/assets/images/positioning-icon.png" alt="">
                        <span>{{$t("index.nowBtn")}}</span>
                    </a>
                </div>
            </div>
            <div class="hiw-box scroll-view" data-scrollTop="Operating" id="Operating">
                <div class="center-box">
                    <h2 class="title">{{$t("index.how.title")}}</h2>
                    <p class="title-d">{{$t("index.how.titleD")}}</p>
                    <p>{{$t("index.how.oneDesc")}}</p>
                    <p>{{$t("index.how.twoDesc")}}</p>
                    <p>{{$t("index.how.threeDesc")}}</p>
                    <a href="" class="positioning-btn">
                        <img src="@/assets/images/positioning-icon.png" alt="">
                        <span>{{$t("index.nowBtn")}}</span>
                    </a>
                </div>
            </div>

            <div class="sfg-view">
                <h2 class="title">{{$t("index.sfg.title")}}</h2>
                <div class="step-list">
                    <div>
                        <h5>{{$t("index.sfg.step")}} 1</h5>
                        <p>{{$t("index.sfg.stepOne")}}</p>
                        <i></i>
                    </div>
                    <div>
                        <h5>{{$t("index.sfg.step")}} 2</h5>
                        <p>{{$t("index.sfg.stepTwo")}}</p>
                        <i></i>
                    </div>
                    <div>
                        <h5>{{$t("index.sfg.step")}} 3</h5>
                        <p>{{$t("index.sfg.stepthree")}}</p>
                        <i></i>
                    </div>
                    <div>
                        <h5>{{$t("index.sfg.step")}} 4</h5>
                        <p>{{$t("index.sfg.stepFour")}}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="fq-view">
            <div class="content">
                <div class="text-box">
                    <h3>{{$t("index.fq.title")}}</h3>
                    <p class="text">{{$t("index.fq.text")}}</p>
                    <p class="min-text">{{$t("index.fq.textM")}}</p>
                </div>
                <ul class="list">
                    <li>
                        <div class="title" @click="toggle(0)">
                            <img src="@/assets/images/drop-down-icon.png" alt="">
                            <span>{{$t("index.fq.oneTitle")}}</span>
                        </div>
                        <div class="text" v-show="fqShowIndex === 0">{{$t("index.fq.oneDesc")}} </div>
                    </li>
                    <li>
                        <div class="title" @click="toggle(1)">
                            <img src="@/assets/images/drop-down-icon.png" alt="">
                            <span>{{$t("index.fq.twoTitle")}}</span>
                        </div>
                        <div class="text" v-show="fqShowIndex === 1">{{$t("index.fq.twoDesc")}}</div>
                    </li>
                    <li>
                        <div class="title" @click="toggle(2)">
                            <img src="@/assets/images/drop-down-icon.png" alt="">
                            <span>{{$t("index.fq.threeTitle")}}</span>
                        </div>
                        <div class="text" v-show="fqShowIndex === 2">{{$t("index.fq.threeDesc")}} </div>
                    </li>
                    <li>
                        <div class="title" @click="toggle(3)">
                            <img src="@/assets/images/drop-down-icon.png" alt="">
                            <span>{{$t("index.fq.fourTitle")}}</span>
                        </div>
                        <div class="text" v-show="fqShowIndex === 3">{{$t("index.fq.fourDesc")}}</div>
                    </li>
                </ul>
            </div>
            <div class="fq-goPhone">
                <h4>{{$t("index.fq.goPhone")}}</h4>
                <a href="" class="positioning-btn">
                    <img src="@/assets/images/positioning-black-icon.png" alt="">
                    <span>{{$t("index.nowBtn")}}</span>
                </a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'three',
    data() {
        return {
            fqShowIndex: -1
        }
    },
    methods: {
        toggle(index) {
            if(index === this.fqShowIndex) {
                this.fqShowIndex = -1
                return
            }
            this.fqShowIndex = index
        }
    }
}
</script>


<style lang="scss" scoped>
    @import "../style/index";
</style>