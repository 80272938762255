<template>
    <div class="one-view">
        <div class="content">
            <div class="content-left">
                <h1 class="title">{{$t("index.title")}}</h1>
                <!-- <div class="phone-success-box" v-show="valid === 1">
                    <div class="phone-success">
                        <img src="@/assets/images/phone-success-icon.png" alt="">
                        <span class="phone-number">{{phoneNum}}</span>    
                    </div>
                    <div class="phone-success-txt">
                        Enter a real email to receive the result
                    </div>
                </div> -->
                <p class="title-tips" v-show="valid !== 1">
                    <img src="@/assets/images/next-step.png" alt="">
                    <span>{{$t("index.enterNumnerFind")}}</span>
                    <img src="@/assets/images/next-step.png" alt="">
                </p>
                <div class="input-group">
                    <div id="phone-error" class="phone-error" v-show="valid === 0">
                        {{$t("index.phoneNumberErrTips")}}
                    </div>
                    <div class="intl-tel-input allow-dropdown">
                        <input type="tel" id="phone" @focus="phone_error_status = false" @focusout="phoneFocusout" @keydown="phoneKeydown($event)">
                    </div>
                    <img v-show="valid === 0" class="inp-err" src="@/assets/images/inp-err.png" alt="">
                    <img v-show="valid === 1" class="correct" src="@/assets/images/index-correct-icon.png" alt="">
                </div>
                <div id="yt_11_26_fmp_001" class="input-group-btn" :style="{ display: valid === 1 ? 'none' : 'flex' }">
                    <span>{{$t("index.continueUP")}}</span>
                    <img src="@/assets/images/next-step-round.png" alt="">
                </div>
                <div class="email-box" v-show="valid === 1">
                    <p class="tips">
                        <img src="@/assets/images/index-tips.png" alt="">
                        <span>{{$t("index.emailErrTips")}}</span>
                    </p>
                    <div class="inp-email email-val">
                        <img src="@/assets/images/index-email-icon.png" alt="">
                        <input ref="email" type="text" placeholder="Enter your email" v-model="email" @blur="emailBlur" @keydown="emailKeydown($event)">
                    </div>
                    <!-- <div class="inp-email Re-email-val" v-show="re_email_status">
                        <img src="@/assets/images/index-email-icon.png" alt="">
                        <input type="text" placeholder="Re-enter email" ref="reEmail" v-model="re_email">
                    </div> -->
                    <div class="checkbox-policy">
                        <input id="checkbox" type="checkbox" class="checkbox" :checked="checkbox" @change="isProtocol($event)">
                        <label for="checkbox" v-html="$t('index.clause')">
                        </label>
                    </div>
                    <!-- <a class="pay-btn price-txt" id="stripe-checkout">START GEOLOCATION</a> -->
                    <a class="pay-btn price-txt" id="stripe-checkout" @click="deliverVal">{{ $t("index.startBtn") }}</a>
                </div>

                <p class="txt" v-show="valid === 0 || valid === -1">
                    {{$t("index.phoneNumberErrTipsDesc")}}
                </p>
            </div>
            <div class="map-img-box">
                <img src="@/assets/images/phone-map-img.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "one",
        data(){
            return{
                valid: -1,
                phone_error_status: false,
                EnterPhoneNum: 0,
                countryCode: "CN",
                email: '',
                re_email_status: false,
                re_email: '',
                phoneNum: '',
                checkbox: false,
                checkboxLog: true
            }
        },
        mounted() {
            let countryCode = 'US'
            if (!localStorage.getItem('countryCode')) {
                this.$axios('/other/ipInfo').then(res=> {
                    if (res.success == 1) {
                        countryCode = res.obj.countryCode
                    }
                    localStorage.setItem('countryCode', countryCode)
                    this.intlTelInput(countryCode)
                }).catch(()=> {
                    this.intlTelInput(countryCode)
                })
            }else {
                this.intlTelInput(localStorage.getItem('countryCode'))
            }
        },
        methods: {
            goPages(url){
                this.$router.push({
                    path: url
                })
            },
            intlTelInput(countryCode) {
                let input = document.querySelector("#phone");
                window.intlTelInput(input, {
                    initialCountry: countryCode,
                    preferredCountries: ['es'],
                    placeholderNumberType: "MOBILE",
                    formatOnDisplay: false,
                    utilsScript: "./js/intlTelInput-utils.js"
                });
            },
            deliverVal() {

                if (!this.isEmailAvailable(this.email)) {
                    // this.amplitude.getInstance().logEvent('ErrorTips',{
                    //     Type: 'WrongEmail'
                    // })
                    this.eventLog('ErrorTips-WrongEmail')
                    this.toast(this.$t("tips.enterVaildEmail"))
                    return
                }
                // if (this.email !== this.re_email) {
                //     this.amplitude.getInstance().logEvent('ErrorTips',{
                //         Type: 'WrongEmail'
                //     })
                //     this.eventLog('ErrorTips-WrongEmail')
                //     this.toast('The email addresses must match.')
                //     return
                // }
                if (!this.checkbox) {
                    // this.amplitude.getInstance().logEvent('ErrorTips',{
                    //     Type: 'Disagreement'
                    // })
                    this.eventLog('ErrorTips-Disagreement')
                    this.toast(this.$t("tips.agreeTermsOrPrivacy"))
                    return
                }
                // this.amplitude.setUserId(this.email);

                // this.amplitude.getInstance().logEvent('LocatePhoneNum',{
                //     Type: 'StartGeolocation'
                // })
                this.eventLog('LocatePhoneNum-StartGeolocation')

                this.gtag('event', 'ZHIFU_FMP', {
                    'event_category': 'DIANJI',
                    'event_label': 'ZHIFU'
                });
                window.fbq('track', 'Search');
                this.loading()
                this.$axios(`/portal/user/checkEmail/${this.email}`).then(res=> {
                    if (res.success == '20001') {
                        this.toast(res.msg)
                        if (localStorage.getItem('userInfo')) {
                            setTimeout(()=>{
                                this.$router.push({path: '/location-phone'})
                            }, 1500)
                        } else {
                            setTimeout(()=>{
                                this.$router.push({path: '/login',query: {email: this.email}})
                            }, 1500)
                        }
                        this.hideLoading()
                        return
                    }
                    if (res.success != 1) {
                        this.toast(res.msg)
                        this.hideLoading()
                        return
                    }
                    console.log({
                        phoneNumber: this.phoneNum,
                        // re_email: this.re_email,
                        email: this.email
                    })
                    this.$emit('deliver',{
                        phoneNumber: this.phoneNum,
                        // re_email: this.re_email,
                        email: this.email
                    })
                    this.hideLoading()
                }).catch(err=>{
                    console.log(err)
                    this.hideLoading()
                    this.toast(err.msg)
                })
            },
            isProtocol(e){
                this.checkbox = e.target.checked
                if (this.checkboxLog){
                    // this.amplitude.getInstance().logEvent('LocatePhoneNum',{
                    //     Type: 'AgreeAgreement'
                    // })
                    this.eventLog('LocatePhoneNum-AgreeAgreement')
                    this.checkboxLog = false
                }
            },
            emailBlur() {
                let email = this.email.trim()
                if (this.isEmailAvailable(email)) {
                    // this.re_email_status = true
                    // this.$nextTick(()=> {
                    //     this.$refs.reEmail.focus()
                    // })
                }else {
                    this.toast(this.$t("tips.enterVaildEmail"))
                }
            },
            emailKeydown(e){
                if (e.key == 'Enter') {
                    this.emailBlur()
                }
            },
            phoneKeydown(e) {
                if (e.key == 'Enter') {
                    e.preventDefault();
                    this.validatePhoneNumber(window.$("#phone"))

                    return false;
                }
            },
            phoneFocusout() {
               this.validatePhoneNumber(window.$("#phone"))
            },
            validatePhoneNumber(thes) {
                let phoneInput = thes;
                let iti = window.intlTelInputGlobals.getInstance(phoneInput[0]);
                let valid = iti.isValidNumber();
                // userObj.phoneNumber = iti.getNumber(intlTelInputUtils.numberFormat.E164)
                this.valid = valid? 1 : 0
                if (valid) {
                    this.phoneNum = iti.getNumber(window.intlTelInputUtils.numberFormat.E164)
                    this.$nextTick(()=>{
                        this.$refs.email.focus()
                    })
                    if (this.EnterPhoneNum === 0) {
                        // this.amplitude.getInstance().logEvent('LocatePhoneNum',{
                        //     Type: 'EnterPhoneNum'
                        // })
                        this.eventLog('LocatePhoneNum-EnterPhoneNum')
                        this.gtag('event', 'OK_FMP', {
                            'event_category': 'DIANJI',
                            'event_label': 'OK'
                        });
                        this.EnterPhoneNum += 1;

                    }
                    this.phone_error_status = false
                    // $('.email-val input').focus()
                    // phoneInput.prop('disabled', 'disabled')
                } else {
                    this.phone_error_status = true
                    // this.amplitude.getInstance().logEvent('ErrorTips',{
                    //     Type: 'WrongNumber'
                    // })
                    this.eventLog('ErrorTips-WrongNumber')
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../style/index";
    @media (min-width:960px) {
        /deep/ .iti.iti--allow-dropdown {
            width: 100%;
            /*padding-left: .2rem;*/
            border-radius: .4rem 0px 0px .4rem;
            box-sizing: border-box;
        }
        /deep/ .iti__flag-container {
            left: 10px !important;
        }
    }
    @media (max-width:960px) {
        /deep/ .iti__flag-container {
            left: .15rem !important;
        }
        /deep/ .iti.iti--allow-dropdown {
            width: 100%;
            /*padding-left: .2rem;*/
            border-radius: .4rem 0px 0px .4rem;
            box-sizing: border-box;
        }
    }
</style>
