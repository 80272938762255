module.exports = {
    nav:{
        logoName: 'Phone Locator',
        account: 'ACCOUNT',
        signIn: 'SIGN IN',
        advantage:'Advantage',
        price: 'Price',
        operating: 'Operating',
        contact: 'Contact us'
    },
    footer:{
        ourService: 'OUR SERVICE',
        recoverPassword: 'Recover password',
        more: 'MORE',
        termsOfUse: 'Terms of Use',
        privacyPolicy :"Privacy policy",
        cookiePolicy: 'Cookie policy'
    },
    index: {
        title: 'Locate the phone',
        enterNumnerFind :'Enter the number to find',
        continueUP :'CONTINUE',
        phoneNumberErrTips: 'Please enter the correct mobile phone number for your country',
        phoneNumberErrTipsDesc: 'No matter which telecommunications company the phone belongs to, you can use our geolocation service to locate the phone and find the person you are looking for. According to the requirements of the AEPD rules, the geographic location system requires the user\'s prior authorization.',
        emailErrTips: 'Please enter a valid email address and we will notify you of the search results through your email address.',
        clause: "<p>I have read and accept the <a href=\"/terms-of-use\">terms of use</a> and contract and the <a href=\"/privacy-Policy\">privacy policy</a>.</p>",
        startBtn: "START GEOLOCATION",
        nowBtn: "LOCATE MOBILE NOW",
        advantage: {
            title: 'Advantages of our services',
            oneTitle: 'All Carriers',
            oneDesc: 'Our location system is compatible with all mobile phone operators and companies.',
            twoTitle: 'All mobile brands',
            twoDesc: 'We are able to track a mobile phone of any brand thanks to our geolocation system.',
            threeTitle: 'Whole territory',
            threeDesc: 'The technology of our satellite geolocation service is accurate and accessible throughout the territory provided that the searched mobile has Internet.',
            fourTitle: 'According to the legislation',
            fourDesc: 'Our system works in such a way that geolocation will be activated and will be effective only with the prior acceptance of the person sought.',
        },
        comment:{
            title: 'ALL RIGHT',
            oneDesc: 'I hired the service and solved my problem almost instantly and as easily as possible. Now I can track cell phones whenever I want and for life, I can\'t be happier with the page!',
        },
        map:{
            miniText: 'MOBILE LOCATION',
            title: 'Precise geolocation',
            titleDesc: 'Tracking any mobile is now possible, any brand, model and operator. Find the person you are looking for in just a few moments.',
            desc: 'Once you have entered the number of the person in the interface, our technical solutions are deployed to allow a location with a minimum margin of error. The accuracy of our system is our strength. The searched mobile phone is located and you receive the precise location in the form of a map.'
        },
        how: {
            title: 'How it works',
            titleD: 'Our geolocation system will allow you to locate a mobile phone',
            oneDesc: 'For that, you only need to indicate the mobile phone number you wish to geolocate and enter the mobile number on which you want to receive the response by text message. To allow geolocation of the searched number, a URL will be sent per text message. The person who wants to geolocate must be connected to the Internet and have activated the location parameter on their phone. We then recover the user\'s authorization and their precise geographical position. The result is communicated directly to the complainant by text message. The answer is given via a mapping system that will locate the searched mobile within a few meters radius. The map is adapted to allow zooming or to show the name of the streets.',
            twoDesc: 'The service cannot work if the mobile is turned off. Once the process is finished, the location will be informed to the complainant.',
            threeDesc: 'It is necessary to clarify that according to the regulations applicable to the data collected, geolocation implies the collection and processing of personal data and, consequently, the service is subject to the regulations on the protection of privacy. In fact our service has to respect the legal obligations that are imposed, mainly obtaining prior authorization from the user to geolocate.'
        },
        sfg: {
            title: 'Stages for geolocation',
            step: 'STEP',
            stepOne: 'Enter the phone number of the person you want to locate',
            stepTwo: 'Make the payment of the service by credit card (secure payment)',
            stepthree: 'Enter your phone number to receive the geolocation result',
            stepFour: 'You will receive an SMS on your mobile indicating the result of the geolocation',
        },
        fq:{
            title: 'Frequent Questions',
            text: 'These are the most frequently asked questions that our users ask us',
            textM: 'If you still have doubts do not hesitate to contact us',
            oneTitle: 'IS IT LEGAL TO TRACK SOMEONE ?',
            oneDesc: 'One should have legitimate reasons to track someone. It’s illegal to locate someone without the prior consent of the person. However, it’s absolutely legal to track someone if you have ownership over the cell phone you wish to locate, get prior authorization from someone, or want to locate underage kids for their security.',
            twoTitle:'HOW LONG DOES IT TAKE TO LOCATE?',
            twoDesc: 'Location tracking is just a few clicks away. A SMS link is send to the person in less than one minute and you can check the location after the person gives their consent to pinpoint their location.',
            threeTitle: 'IS MY INFORMATION COMPLETELY SECURE?',
            threeDesc: 'Yes, this service does not store any data or information to its server. No one can access your information. You have complete control over your information.',
            fourTitle: 'HOW MUCH DOES IT COST TO LOCATE A MOBILE PHONE?',
            fourDesc: 'The market offers you various free services but most of them are malicious sites and they do not deliver guaranteed result. It’s best to get a reliable and affordable service that gives you accurate result. Phone Locator is such a trustworthy service that costs just $0.99 for 24 hours.',
            goPhone: 'Don\'t wait any longer and locate a mobile now'
        },
        price: {
            title: 'Our prices',
            trialTitle: 'Trial period',
            trialDay: '48 HOURS',
            trialDesc: 'Our geolocation service is a payment service with a $0.99 trial for two days. In case you do not cancel your subscription, it will be renewed automatically and you will be charged $39.99 per month.',
            buyTipsF: 'Our geolocation service is a payment service with a',
            buyTipsC: 'trial for two days. In case you do not cancel your subscription, it will be renewed automatically and you will be charged',
            buyTipsL: 'per month.',
            monthTitle: 'Monthly',
            month: 'MONTH',
            monthDesc: 'Monthly subscription service that you can cancel at any time'
        },
        pay:{
            TrialTime:'2-Day Trial - Without Permanence',
            zipUP: 'ZIP',
            pay: 'Pay'
        }
    },
    login: {
        title: 'Sign in',
        e_amil: 'E-MAIL',
        passwordUP: 'PASSWORD',
        forgetPassword: 'Forget password',
        enterUP :"ENTER",
        startLocating :"START LOCATING",
        rpDesc:"Don't you want to be here? - Back to",
        rpUP :"RECOVER PASSWORD",
        sendCodeUP: 'SEND CODE',
        codeUP: 'CODE',
        submitUP: 'SUBMIT',
        login: 'Login',
        backTo: 'Back to',
    },
    account:{
        title: 'My account',
        titleBtn: 'LOCATE THE PHONE',
        accountUP: 'ACCOUNT',
        historyUP: 'HISTORY',
        accountTitle: 'Account settings',
        accountBtn: 'SAVE CHANGES',
        historyOneTitle: 'Payment history',
        historyTwoTitle: 'Shipping history',
        date:"Date",
        payment :'Payment',
        noData: 'No data available',
        phoneNumber: 'Phone number',
        state:"State",
    },
    unsubscribe:{
        title: 'Unsubscribe',
        tipsErr: 'The email you entered does not exist in our customer database. Can you write the email you created the account?',
        success: 'The cancellation of the service has been successful. You will receive a confirmation email. The charges on your credit card will stop.',
        desc: 'To unsubscribe from location services, please click on the link below. We will consider your request immediately. Charges will stop and subscriptions will not be updated. We will send you a confirmation email (if you have not received the email, please check the "Spam" folder)',
        emailPlaceholder: 'Enter your email',
        cancelBtn:'CANCEL NOW'
    },
    contact:{
        time: 'Sun - Thu (09:00 - 19:00)'
    },
    getLocation:{
        title: 'Thank you!',
        desc:"Sharing is caring! Thanks for your contribution. Now start to track the phone number location!",
        textM :"Phone Number Tracker",
        btn: 'View'
    },
    locationPhone:{
        tips: 'Incorrect phone number format',
        oneLabel: 'The phone number you want to locate',
        towLabel: 'Enter your number to receive the result',
        threeLabel: 'SMS format',
        smsListOne: 'Hello, one of your loved ones is looking for you.',
        smsListTow: 'Hi, a person is looking for you.',
        smsListThree: 'Hello, your mother is looking for you.',
        smsListFour: 'Hello, your father is looking for you.',
        smsListFive: 'Hello, your wife is looking for you.',
        smsListSix: 'Hello, your parents are looking for you.',
        fourLabel:"Personalized preview",
        descTitle: 'Go through different stages of geolocation',
        descContent: "Our mobile location service allows you to know the exact location of people. The geographic location is confidential, and the plaintiff's number is anonymous. All processing is done through our system, there is no direct link. To obtain the geographic location, you only need to enter the number of the searched mobile device to receive the address of the location by mail. The result is completed by an email containing a link, which will send you to a map of geographic locations. You can then zoom in on the map and display the street names. Geolocation can only work when the phone is turned on. If the phone does not have a battery, then geolocation will not work immediately. But our location service will start when the phone is turned on. At that time, you'll receive the location and time stamps.",
        btn: 'FIND LOCATION'
    },
    map:{
        locaOf:'Location of ',
        desc: "The service requires you to send a message to the recipient to indicate that you want to know his exact location.",
        sendUP: 'SEND',
        smsUP: "SMS",
        email: 'Email',
        messenger:"Messenger",
        whatsApp:"WhatsApp",
        copyLink:"Copy Link",
        wait:'The location is being processed, and the result will be sent to your mailbox by email. If the phone you are looking for is active, the search process may take a few minutes. Otherwise, the service will only be activated when the phone is turned on again, and you will receive the email within 48 hours at most.'
    },
    success:{
        titleListOne:'Checking phone number',
        titleListTwo:'Connecting to database',
        titleListThree:'Detecting operator',
        titleListFour:'Receiving current location',
        titleTwo: 'Number is available to be located',
        errTitle: 'Pay with a different card',
        errBtn: 'PROCEED TO PAYMENT'
    },
    revealLocation: {
        successText: 'Geographic location ',
        errText: 'Geographic location not found',
        marker: 'Localisation du mobile'
    },
    cookie:{
        title: 'Cookies policy',
        update: 'Last update: April 6, 2017',
        summary:{
            title: 'Summary',
            one: "Cookies used: Own and Third Party",
            two:"Are they used to obtain statistics? Yes",
            three:"Are they used for advertising? Not",
            four: 'Third-party services that install cookies through this website: Google Analytics, Google Maps and Stripe'
        },
        how:{
            title: 'How to configure and disable cookies',
            desc: 'You can allow, block or delete the cookies installed on your device from your web browser. If you do not allow the installation of cookies on your device, the browsing experience may deteriorate. Below you can find how to configure cookies in each browser:',
            one: 'Edge',
            two: 'Google Chrome',
            three:'Google Android',
            four: 'Internet Explorer 7 and 8',
            five: 'Internet Explorer 9',
            six: "Internet Explorer 10",
            seven: 'Internet Explorer 11',
            eight:"Mozilla Firefox",
            nine: 'Opera',
            ten:"Safari",
            eleven: 'Safari Mobile'
        },
        type: {
            title: '1.- Types and uses',
            desc: 'The service uses its own and third-party cookies to improve navigation, provide the service and obtain statistics on the use of the website.'
        },
        what:{
            title: '2.- What is a cookie and what is it for?',
            desc:'A cookie is a text file that is sent to the web browser of your computer, mobile or tablet and that is used to store and retrieve information about the navigation carried out. For example, remembering your username and password or your profile preferences.'
        },
        whatType:{
            title:'3.- What types of cookies are there?',
            accordingTitle: 'According to who manages them:',
            accordingOne: '<u>Own cookies;</u>They are sent to your browser from our computers or web domains.',
            accordingTwo: '<u>Third party cookies;</u> They are sent to your browser from a computer or web domain that we do not manage directly, but a third entity such as Google.',
            dependingTitle: 'Depending on the time they are active, there are:',
            dependingOne: "<u>Session cookies;</u> they remain in your browser's cookie file until you leave the website, so that none remain on your device.",
            dependingTwo: "<u>Persistent cookies;</u> they remain on your device and our website reads them every time you make a new visit. These cookies stop working after a specific date.",
            finallyTitle: 'Finally, and according to its purpose, we have:',
            finallyOne: '<u>Technical cookies;</u> improve navigation and the proper functioning of the web.',
            finallyTwo: '<u>Personalization cookies;</u> They allow access to the service with predefined characteristics based on a series of criteria.',
            finallyThree: '<u>Analysis cookies;</u> allow statistically measuring and analyzing the use made of the service provided.',
        },
        used:{
            title: 'Cookies used by Phone Locator:',
            name: 'Name',
            origin: 'Origin',
            purpose: 'Purpose',
            type: 'Type',
            expiration:'Expiration',
            nameOne: '_ga',
            nameTwo: '_gat',
            nameThree: 'NID',
            namefour: 'SNID',
            nameFive: 'OGPC',
            nameSix: 'XSRF-TOKEN',
            nameSeven: 'laravel_session',
            nameEight: 'remember_web',
            originOne: 'Phone Locator',
            originTwo: 'Google com',
            originThree: 'Google is',
            originFour: 'Google com',
            originFive: 'OGPC',
            originSix: 'XSRF-TOKEN',
            originSeven: 'laravel_session',
            originEight: 'remember_web',
            purposeOne:'Analytics',
            purposeTwo:'Technique',
            typeOne: 'Third',
            typeTwo: 'Own',
            expirationOne:"2years",
            expirationTwo:"10 minutes",
            expirationThree:"4 months",
            expirationFour:"6 months",
            expirationFive:"---",
            expirationSix:"Session",
        },
        thirdParty :{
            title: 'List of third party cookies used by Phone Locator :',
            ga: 'Google Analytics:',
            gaDesc: 'Its cookies (_ga, _gat) allow to count the times that a user visits a website, how, when and from where they do so.',
            gaDescTwo: "In any case, you can disable Google Analytics cookies from",
            here: 'here.',
            google: 'Google:',
            googleDesc: 'Your cookies (NID) allow you to customize how ads are seen outside of Google or store information such as the preferred language when displaying search results.',
            googleDescTowFirst: 'In any case, here you can find ',
            googleDescTowC: 'more information',
            googleDescTowLast: 'about the types of cookies that Google uses in its multiple services.',
            googleMaps: 'Google Maps:',
            googleMapsDesc: 'Its cookies (SNID, OGPC) allow to remember preferences such as the zoom level and check how users use embedded maps on other websites.',
            stripe: 'Stripe:',
            stripeDesc: 'the service allows the subscription payment for the use of the locator.'
        }
    },
    privacyPolicy:{
        title: 'Privacy Policy',
        update: 'Last Updated: May 30, 2017',
        description:{
            title: '1.- Description',
            one: 'Acceptance of the Phone Locator privacy policy (hereinafter "Privacy Policy"), is a necessary condition for the use of our Phone Locator service (hereinafter "Service").',
            two: 'This Privacy Policy regulates the collection, treatment and use of your personal and non-personal information as a user of the Service, from the effective date that appears in the header.',
            three: 'Phone Locator does not sell your personal information to third parties and will always ask for your consent before using your data for any purpose other than those described in this Privacy Policy.',
            four: 'To process your personal data, Phone Locator complies with current applicable legislation, as well as its implementing regulations.',
            five: 'Therefore, it adopts the necessary technical and organizational measures to prevent the loss, misuse, alteration, unauthorized access and theft of the personal data provided. Always according to the state of technology, the nature of the data and the risks to which they are exposed.'
        },
        informationCollected:{
            title: '2.- Information collected',
            descOne: 'To use the Service, the user must create a profile at the time of requesting the Phone Locator of a mobile device.',
            descTwo: 'The information (personal or non-personal) collected by the Service will vary based on that.',
            descThree: 'The personal and non-personal information collected by the Service will come to us in three ways: 1) that collected automatically 2) that which you voluntarily provide us and 3) that provided by third parties.',
            one: {
                title: '2.1.- The one collected automatically',
                desc: 'This information will consist of:',
                one: 'The one collected through cookies or similar mechanisms stored on your device, always with your consent. Check our Cookies Policy for more information.',
                two: 'The IP from which the connection is made, the type of device used and its characteristics, the version of the operating system, the type of browser, the language, the date, the country, the time of the request, the referring URL or the mobile network used (among others).',
                three: 'Service use data and possible errors detected during its use.',
                four: 'Data about your physical location using different technologies: GPS signals sent by a mobile device or information about the closest Wi-Fi access points or mobile phone antennas. In any case, this data is always collected according to the privacy settings of your device.',
                five: 'In addition, Phone Locator uses Google Analytics, an analytical service provided by Google, Inc. domiciled in the United States with headquarters at 1600 Amphitheater Parkway, Mountain View, California 94043. For the provision of these services, they use cookies that collect the information, including the user\'s IP address, which will be transmitted, processed and stored by Google in the terms set out on the website <a href="www.google.com">www.google.com</a>. Including the possible transmission of said information to third parties for reasons of legal requirement or when said third parties process the information on behalf of Google.',
                six: 'In any case, you can disable Google Analytics cookies from <a href="https://tools.google.com/dlpage/gaoptout">here</a>.'
            },
            two:{
                title:'2.2.- The one you voluntarily provide',
                one: 'The one required by Phone Locator in the creation of a user profile in the Service, indicating at least the information marked with an asterisk on certain fields: email, telephone, name and surname and payment information.',
                two: 'You must also add the mobile phone number that you intend to locate. You agree that you have obtained that number that you intend to locate in a lawful way and with the prior consent of its owner.',
                three: 'In relation to the password, the User will be responsible for its protection. In this sense, it will assume any damages that may arise from its improper use or from the transfer of it to third parties.',
                four: 'The information, personal or not, that the messages sent through the contact or opinion channels established in the Service may contain, for example your name or email The email you provide to sign up for the newsletter. If you use the email of a third party, you acknowledge that you have their consent to do so.',
                five: 'The email you provide to sign up for the newsletter. If you use the email of a third party, you acknowledge that you have their consent to do so.'
            },
            three: {
                title: '2.3.- The one provided by third parties',
                one: 'The one provided by payment systems or credit card processors, such as the time of purchase or its amount.',
                two: 'The one provided by courier companies such as the time, place and day of delivery.'
            },
            four: {
                title: '2.4.- The third party who consents to the location, will transfer automated information about his device as indicated in section 2.1, his telephone number and his specific and specific geoposition for the moment in which he has consented to make it known.',
                one: 'In no case will the location go beyond the specific moment in which it has been accepted.',
                two: 'The third party who does not consent to the location, will give Phone Locator automated information about his device as indicated in section 2.1, his telephone number and the name with which he would have been associated.',
                three: 'You can exercise at any time the rights of access, rectification, cancellation and opposition of your personal data by email to:',
                four: 'In both cases you must identify yourself with your name and surname, as well as a copy of your DNI or national ID.',
                five: '<a href="https://www.google.com/url?q=http://www.agpd.es/portalwebAGPD/CanalDelCiudadano/derechos/principales_derchos/index-ides-idphp.php&sa=D&ust=1496145830315000&usg=AFQjCNGfpKOUK22rT9u5bvVklQ1ES-R_cQ">Here</a> you can find the different models to exercise such rights.'
            },
        },
        rights:{
            title: '3.- Rights and purposes',
            one: 'We inform you that the completion of the forms is voluntary. However, if you do not fill in the required fields (marked with an asterisk) the use of the Service will not be possible or will be limited',
            two: 'The personal data that you provide us is incorporated and will be processed in the files, in order to be able to attend to your requests, provide the requested service and keep you informed about issues related to the activity of the company and its services.',
            three: 'You can exercise at any time the rights of access, rectification, cancellation and opposition of your personal data by email to:',
            four: 'In both cases you must identify yourself with your name and surname, as well as a copy of your DNI or national ID.',
            five: 'Phone Locator undertakes to respect the confidentiality of the data collected in the file, to use them in accordance with its purpose, to comply with its custody obligation and to adopt all the measures required to avoid the alteration, loss, treatment or access not authorized data, in accordance with applicable regulations.',
            six: '<a href="https://www.google.com/url?q=http://www.agpd.es/portalwebAGPD/CanalDelCiudadano/derechos/principales_derchos/index-ides-idphp.php&sa=D&ust=1493218316003000&usg=AFQjCNEe-8VXcChQpwRKlbVrVL2P_ynmEw">Here</a> you can find the different models to exercise such rights.'
        },
        use:{
            title: '4.- Use of data',
            one: 'Phone Locator will use the collected data to:',
            two: 'Manage, provide and update the Service.',
            three: 'Carry out the requested location, provided that it is consented to by the receiver',
            four: 'Address the questions you ask.',
            five: 'Process the payments you make.',
            six: 'Send you by email, if you have authorized it or as provided in the applicable regulations, our newsletter and / or offers from our business partners.',
            seven: 'You can unsubscribe from them from the email received, from your user account or by contacting us at <a href="mailto:Findmyphone@chamspy.com">Findmyphone@chamspy.com</a>. However, you will not be able to unsubscribe from certain correspondence from us, such as messages related to the security of your data or the terms and conditions of the Service.',
            eight: 'Plan and develop the commercial activities of the Service, such as advertising, directly or through collaborating third parties.',
            nine: 'Maintain the security of the Service, investigate illegal activities, enforce our terms and conditions, and assist state security forces in the framework of their possible investigations.',
            ten: "Likewise, Phone Locator may use users' personal and non-personal information in the form of aggregated and anonymous data to show them to third parties. You may also share statistics and demographic information about users and their use of the Service with third parties. None of this will allow those third parties to personally identify you."
        },
        delete: {
            title: '5.- Delete account',
            one: 'You can delete your account by requesting its deletion at ',
            two: 'If you decide to delete your account, all your data will be deleted, including backup copies, within a maximum period of 7 days.'
        },
        data:{
            title: '6.- Data shared with third parties',
            one: 'There are third parties that manage part of the Service.',
            two: 'Phone Locator requires them to comply with this Privacy Policy in what is applicable to them, in addition to having their own. However, Phone Locator will not be responsible for compliance with said policy.',
            three:'Under some circumstances, Phone Locator may share, use or preserve some of the personal information collected with third parties:',
            four: 'To provide the Service:',
            five: 'Service providers who perform functions on our behalf, such as credit card processing, localization process, web hosting, business analytics, customer service or marketing. These service providers may collect and have access to information that is necessary for them to perform their functions, but they are not allowed to share or use the information for any other purpose.',
            six: "To cooperate with the competent authorities:",
            seven: 'If we believe that it is reasonably necessary to satisfy any law, legal process or legitimate interest in any part of the world. In any case, we will only provide the information strictly required.',
            eight:"If we believe that this action is appropriate to enforce the terms and conditions of Phone Locator",
            nine: 'If necessary to detect or prevent fraud or issues related to the security of Phone Locator, its providers and users.',
        },
        security:{
            title: '7.- Security measures',
            one: 'Phone Locator adopts all the technical and organizational measures necessary to protect the security and integrity of the personal and non-personal information collected. Both against unauthorized access and accidental alteration, loss or destruction.',
            two: 'The sending and remission of data that you make through the Service is protected by electronic security techniques on the network such as the SSL protocol. Likewise, the information supplied and stored in the Phone Locator databases is also protected by security systems that prevent access to them by unauthorized third parties.',
            three: 'Phone Locator makes its best efforts to have the most up-to-date systems for the effectiveness of these security mechanisms. In addition, Phone Locator stores personal and non-personal information for as long as the regulations allow and require. The one that is not needed is periodically destroyed.',
            four: 'Despite the foregoing, Phone Locator cannot guarantee the absolute security of the information collected, so you must collaborate and use common sense about the information shared at all times.',
        },
        changes:{
            title: '8.- Changes in the Privacy Policy',
            one: 'We may update this Privacy Policy in the future.',
            two: 'We will inform you of its changes by sending a notice to the email address provided and / or by placing a notice in a prominent place on our website.'
        },
        contact:{
            title: '9.- Contact',
            one: 'If you have questions about this Privacy Policy, contact us at:',
            two:'Address: Unit 1318-19, Hollywood Centre, 610 Nathan Road, Mongkok, Kowloon, Hong Kong'
        }

    },
    terms:{
        title: 'Conditions of Use and Contract',
        update: 'Last update: November 10, 2018',
        ownership:{
            title: '1. Ownership',
            desc: 'In compliance with Law 34/2002 on Services of the Information Society and Electronic Commerce (LSSICE), we inform that this site is owned and operated by SOOUYA TECHNOLOGY with registered office in Unit 1318-19, Hollywood Centre, 610 Nathan Road, Mongkok, Kowloon, Hong Kong'
        },
        acceptance:{
            title: '2. Acceptance',
            one: 'This website and the SMS location service called Phone Locator (hereinafter the Service) is available to any user, for their own consumption, and subject to the following terms and conditions: these Conditions of Use and Contract (hereinafter CUC ), our',
            two: 'When you use the Service, you accept these CUC and the rest of the terms and conditions.',
            three: 'By doing this, you agree not to use this site for illegal purposes.'
        },
        description:{
            title: '3. Description of the Service',
            one: 'Through the Service, Phone Locator allows you to locate the current position of a person who consents to be located. The Service allows you to send an SMS to the recipient to indicate that someone wants to know their location. If the recipient of the message expressly accepts, the geoposition of the recipient at that specific time is shared by the requestor.',
            two: 'In no case does Phone Locator go beyond the specific moment in which it has been accepted.',
            three: 'The monthly payment of the service does not guarantee that the person sought can be located, it only provides the means to achieve that end but in the event that the person is missing, with the mobile phone stolen, turned off or does not accept the link that we send in the SMS, we cannot get your GPS position.'
        },
        external:{
            title: '4. External links',
            one: 'You may be sent from the Service to other websites through links.',
            two: 'However, Phone Locator does not control those sites or their content, which are in fact subject to their own terms and conditions. Therefore, Phone Locator is NOT responsible for the quality, truthfulness or accuracy of those sites.',
            three: "When you regiester to our app, you agree that we can send you our updates and promotional messages. If you don't want to receive them anymore, you can send us email to Findmyphone@chamspy.com"
        },
        age:{
            title: '5. Age',
            one: 'Regarding the use of the Service, you declare that you are of legal age and that you have the necessary legal capacity to be bound by this agreement and to use it in accordance with its terms and conditions, which you fully understand and acknowledge.',
            two: 'You declare that all the information you provide to access the Service, before and during its use, is true, complete and accurate.',
        },
        intellectual:{
            title: '6. Intellectual and industrial property',
            one: 'The content and information of the Service (among other data, text, sound, image or computer code), as well as the infrastructure used to provide such content and information, is the property of Phone Locator or has the corresponding authorizations for its use.',
            two: 'In addition, the modification, reproduction, duplication, copying, distribution, sale, resale and other forms of exploitation for commercial or equivalent purposes of the Service are prohibited.',
            three: 'For any other use of the content of the Service you need our prior written consent.'
        },
        user:{
            title: '7. User Content',
            one: 'You can contribute to the Service by sending us messages to our email address or through the contact form (hereinafter "Content").',
            two: 'We may use your Content in different ways, such as: displaying it on the website, reformatting it, translating it into other languages, editing it for clarity, correcting errors, promoting or distributing it. Therefore, by submitting Content to us, you grant Phone Locator a free, non-exclusive, worldwide use license, pending removal of the content, transferable and sublicensable on that Content.',
            three: 'Therefore, by submitting Content to us, you grant Phone Locator a free, non-exclusive, worldwide use license, pending removal of the content, transferable and sublicensable on that Content.',
            four: 'That means that the content remains yours, but Phone Locator, thanks to that license of use, can: a) use, reproduce, modify, adapt, translate, distribute and publish the Content, create derivative works from it, display it and display it around the world, by any known means and for any legitimate purpose; and b) use the name you submit in connection with that Content.',
            five: 'However, Phone Locator reserves the right not to publish content or information that is false, illegal, misleading or contrary to the rights of third parties.'
        },
        price:{
            title: '8. Price and taxes',
            one: 'The prices of the plans offered on Phone Locator are indicated in dollar($) and include the value added tax (VAT) applicable to these services.',
        },
        payment:{
            title: '9. Payment methods',
            one: 'The payment of the contracted Service can be made:',
            two: '- By credit or debit card VISA or MasterCard, charging the amount at the time of purchase of the products.',
            three: 'In this sense, Phone Locator informs credit and debit card holders that it is responsible for transactions in the online store. They are carried out on a secure page, using SSL technology to guarantee the security of data transmission.'
        },
        validity:{
            title: '10. Validity of offers',
            one: 'The Service offered, and the prices of its eventual plans or modalities, will be available for purchase as long as they are accessible through this website.',
            two: 'In any case, Phone Locator reserves the right to make the modifications it deems appropriate in the Service, being able to update products and services depending on the market.',
            three: 'In relation to the prices that appear on the web, Phone Locator reserves the right to change them without prior notice.',
            four: 'We inform you that despite the updates that are made to the prices of the Service, they could contain mistakes. We will promptly correct any errors that appear, but they will not be binding on the Service.'
        },
        guarantees:{
            title: '11. Guarantees',
            one: 'All the rights that the laws in force guarantee you as a consumer and user are guaranteed.',
            two: "In any case, although Phone Locator will make all reasonable commercial efforts to provide the Service, you understand that: a) the user's Phone Locator requires the express consent of the recipient and; b) that even with your consent, the state of technology does not guarantee that your location will always be obtained or that it can be done with sufficient precision.",
            three: 'In any case, and to the extent permitted by law, Phone Locator reserves the right to reject, suspend or cancel the provision of the Service at its sole discretion.'
        },
        responsibility:{
            title: '12. Responsibility',
            one: 'By using the Service you confirm that: a) you have obtained the telephone number that you intend to locate in a lawful way and with the prior consent of its owner and; b) that you do not intend to use the Service for any illicit purpose.',
            two: 'On the other hand, to the extent permitted by applicable legislation, Phone Locator will not be responsible for the eventual loss of data or programs derived from the use of the Service.',
        },
        security:{
            title: '13. Security',
            one: 'On the other hand, Phone Locator guarantees the security of the Service in accordance with the present technological knowledge.',
            two: 'However, Phone Locator cannot guarantee the total security of the Service, although it undertakes to correct and implement the appropriate corrective measures to correct a possible security breach as soon as possible.',
            three: 'The user agrees to notify the Service, immediately and via email to <a href="mailto:Findmyphone@chamspy.com">Findmyphone@chamspy.com</a>, of any loss of information, unauthorized access or use by third parties or any situation that could lead to the impersonation of the identity of other users.',
            four: 'Phone Locator will not be responsible for the damage that may arise from the use of the Service if it is caused by unforeseeable technical problems or, being foreseeable, are unavoidable. Similarly, ',
            five: 'Phone Locator will not be responsible when the faulty performance of the user, whether the sender or receiver of the message, causes the damage.'
        },
        subscription:{
            title: '14. Subscription and refund',
            one: 'If you subscribe to our geolocation service, you will get a 48-hour trial for $0.99. In case you did not cancel the account, your subscription to the service is monthly for an amount of $39.99 and will be automatically renewed for the same period of time until its termination.',
            two: 'Unless you cancel your subscription, you authorize us to charge the corresponding monthly subscription fee to your payment method.',
            three: 'You can cancel your subscription to the Service at any time, and you will continue to have access to it until the end of your monthly billing period. ',
            four: 'Refunds may be granted if the plan is cancelled prior to the end of the billing cycle and service have not been used or performed. To the extent permitted by law, if a service has already been performed during the month, then that month is non-refundabled and we do not provide refunds or payments for partial monthly subscription periods. If not yet performed and you have followed the terms, you can request a refund within 14 days of the date of the transaction.',
            five:'To cancel the Service or request a refund, access your profile or contact us at: ',
            six:'In the event that the credit card with which you signed up for the service does not have funds at the time of payment, the account will be canceled at the time'
        },
        withdrawal:{
            title: '15. Withdrawal',
            one: 'If you contract the Service, you will be contracting the supply of digital content not provided in material support.',
            two: 'Therefore, and from the moment in which the content has begun to be executed, you accept and understand that the right of withdrawal as established in art. 103 m) of the General Law for the Defense of Consumers and Users, text approved by Royal Legislative Decree 1/2007, of November 16.',
        },
        modifications:{
            title: '16. Modifications and invalidity',
            one: 'We may update the terms and conditions of the Service in the future, as well as the features and functions of the Service itself.',
            two: 'We will inform you about the changes in terms and conditions by placing a notice in a prominent place on our website and / or by email.',
            three: 'If any clause included in our terms and conditions is declared, totally or partially, null or ineffective, it will only affect said provision or the part of it that is null or ineffective. Our terms and conditions will subsist in everything else, having such provision, or the part of it that is affected, by not placing it.'
        },
        claims:{
            title: '17. Claims and actions derived from the contract',
            one: 'In order to simplify the resolution of claims through civil proceedings and reduce costs, we do not exclude the possibility of submitting to an Equity Arbitration of the Court of Arbitration of the Chambers of Commerce and Industry.',
        },
        contact:{
            title: '18. Contact',
            one: 'For any clarification, incident or claim, you can contact us through: E) mail:',
            two: 'Address: Unit 1318-19, Hollywood Centre, 610 Nathan Road, Mongkok, Kowloon, Hong Kong',
            three: 'Telephone: '
        }
    },
    tips: {
        enterVaildEmail: "please enter your vaild email",
        agreeTermsOrPrivacy :"Please agree to our terms of use and contract and privacy policy.",
        passWordNoSpaces: "Password without spaces",
        passWordNoSix: "Password cannot be less than 6 digits",
        successModified: "Successfully modified",
        notLogin: "You are not logged in",
        emailFormatErr: "Email format error",
        inputNoEmpty: "The input content cannot be empty",
        enterCorrectCode: "Please enter the correct code",
        emailNoEmpty: "E-mail can not be empty",
        emailIncorrect: "The email address entered is incorrect",
        codeSendEmail: "The verification code has been sent to your email!",
        incorrectCode: "Incorrect postal code",
        paymentErr: "Payment error",
        cradNameNoEmpty: "Crad name cannot be empty",
        addressNoEmpty: "Address cannot be empty",
        cityNoEmpty: "City cannot be empty",
        zipNoEmpty: "Zip code cannot be empty",
        zipIncorrect: "Zip code is incorrect",
        err: "error",
        fillCorrectPostalCode: "Please fill in the correct postal code",
        notLoginPleaseLogin: "You are not logged in, please login",
        reBuy: "Please re-purchase after the account has expired!",
        phoneNumberNotBlank: "Phone number can not be blank",
        phoneNumberDifferent: "Please enter two different mobile phone numbers",
        emailAndPasswordNoEmpty: "Email and password cannot be empty",
        rePhoneNumberToLocate: "Please re-enter your mobile phone number to locate",
        noDownApp: "You may not download this app",
        shareLinkSuccess: "Share link copied successfully",
        copyFailed: "Copy failed, please use other methods to share",
        rePasswordSuccess: "Your password has been successfully reset.",
    }
}