<template>
    <div class="two-view scroll-view" data-scrollTop="Advantage" id="Advantage">
            <div class="content">
                <div class="img-list-box">
                    <h2 class="title">{{$t("index.advantage.title")}}</h2>
                    <ul class="img-list">
                        <li>
                            <img src="@/assets/images/index-telecommunications.png" alt="">
                            <h6>{{$t("index.advantage.oneTitle")}}</h6>
                            <p>{{$t("index.advantage.oneDesc")}}</p>
                        </li>
                        <li>
                            <img src="@/assets/images/index-phone-brand.png" alt="">
                            <h6>{{$t("index.advantage.twoTitle")}}</h6>
                            <p>{{$t("index.advantage.twoDesc")}}</p>
                        </li>
                        <li>
                            <img src="@/assets/images/index-All-regions.png" alt="">
                            <h6>{{$t("index.advantage.threeTitle")}}</h6>
                            <p>{{$t("index.advantage.threeDesc")}}</p>
                        </li>
                        <li>
                            <img src="@/assets/images/index-legislation.png" alt="">
                            <h6>{{$t("index.advantage.fourTitle")}}</h6>
                            <p>{{$t("index.advantage.fourDesc")}}</p>
                        </li>
                    </ul>
                </div>
                <a href="" class="positioning-btn">
                    <img src="@/assets/images/positioning-icon.png" alt="">
                    <span>{{$t("index.nowBtn")}}</span>
                </a>
            </div>
            <div class="swiper-wrap">
                <div class="swiper-container">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" data-swiper-slide-index="0">
                            <h5>{{$t("index.comment.title")}}</h5>
                            <p>{{$t("index.comment.oneDesc")}}</p>
                        </div>
                    </div>
                    <div class="swiper-pagination"></div>
                </div>
            </div>
        </div>
</template>

<script>
    export default {
        name: "two",
        mounted() {
            new window.Swiper('.swiper-container', {
                speed: 2000,
                longSwipes: false,
                loop: true,
                autoplay: {
                    disableOnInteraction: false,
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                on: {
                    autoplayStop: function () {
                        this.$el.find(".ups-icon-videoplay").addClass('stop-status');
                    },
                    autoplayStart: function () {
                        this.$el.find(".ups-icon-videoplay").removeClass('stop-status');
                    },

                }
            });
        },
    }
</script>

<style lang="scss" scoped>
    @import "../style/index";
</style>
