import Vue from 'vue'
import VueI18n from 'vue-i18n'


Vue.use(VueI18n)

// 以下为语言包单独设置的场景，单独设置时语言包需单独引入
const messages = {
    'en-US': require('./en-US'), // 英文语言包
    'fr': require('./fr') // 英文语言包
}
let lan = navigator.language.split('-')[0]
let locale = "en-US";
switch (lan) {
    case "en":
        locale = "en-US";
        break;
    case "fr":
        locale = "fr";
        break;
    default:
        locale = "en-US";
}
// 最后 export default，这一步肯定要写的。
export default new VueI18n({
    locale: locale, // set locale 默认显示英文
    messages: messages // set locale messages
})