export default {
    "state": {
        "AD": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "AE": {
            "cityLabel": "City",
            "line1Label": "PO Box",
            "line2Label": "Additional information",
            "optionalLabel": "(optional)",
            "stateLabel": "Emirate",
            "stateList": [
                {
                    "$elt": "Abu Dhabi",
                    "$id": "Abu Dhabi"
                },
                {
                    "$elt": "Ajman",
                    "$id": "Ajman"
                },
                {
                    "$elt": "Dubai",
                    "$id": "Dubai"
                },
                {
                    "$elt": "Fujairah",
                    "$id": "Fujairah"
                },
                {
                    "$elt": "Ras al-Khaimah",
                    "$id": "Ras al-Khaimah"
                },
                {
                    "$elt": "Sharjah",
                    "$id": "Sharjah"
                },
                {
                    "$elt": "Umm al-Quwain",
                    "$id": "Umm al-Quwain"
                }
            ]
        },
        "AG": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "AI": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "AL": {
            "cityLabel": "City",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 4 digits."
        },
        "AM": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "AN": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "AO": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "AR": {
            "cityLabel": "City",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "selectLabel": "Select",
            "stateLabel": "Province",
            "stateList": [
                {
                    "$elt": "Buenos Aires (Ciudad)",
                    "$id": "CIUDAD AUTÓNOMA DE BUENOS AIRES"
                },
                {
                    "$elt": "Buenos Aires (Provincia)",
                    "$id": "BUENOS AIRES"
                },
                {
                    "$elt": "Catamarca",
                    "$id": "CATAMARCA"
                },
                {
                    "$elt": "Chaco",
                    "$id": "CHACO"
                },
                {
                    "$elt": "Chubut",
                    "$id": "CHUBUT"
                },
                {
                    "$elt": "Córdoba",
                    "$id": "CÓRDOBA"
                },
                {
                    "$elt": "Corrientes",
                    "$id": "CORRIENTES"
                },
                {
                    "$elt": "Entre Ríos",
                    "$id": "ENTRE RÍOS"
                },
                {
                    "$elt": "Formosa",
                    "$id": "FORMOSA"
                },
                {
                    "$elt": "Jujuy",
                    "$id": "JUJUY"
                },
                {
                    "$elt": "La Pampa",
                    "$id": "LA PAMPA"
                },
                {
                    "$elt": "La Rioja",
                    "$id": "LA RIOJA"
                },
                {
                    "$elt": "Mendoza",
                    "$id": "MENDOZA"
                },
                {
                    "$elt": "Misiones",
                    "$id": "MISIONES"
                },
                {
                    "$elt": "Neuquén",
                    "$id": "NEUQUÉN"
                },
                {
                    "$elt": "Río Negro",
                    "$id": "RÍO NEGRO"
                },
                {
                    "$elt": "Salta",
                    "$id": "SALTA"
                },
                {
                    "$elt": "San Juan",
                    "$id": "SAN JUAN"
                },
                {
                    "$elt": "San Luis",
                    "$id": "SAN LUIS"
                },
                {
                    "$elt": "Santa Cruz",
                    "$id": "SANTA CRUZ"
                },
                {
                    "$elt": "Santa Fe",
                    "$id": "SANTA FE"
                },
                {
                    "$elt": "Santiago del Estero",
                    "$id": "SANTIAGO DEL ESTERO"
                },
                {
                    "$elt": "Tierra del Fuego",
                    "$id": "TIERRA DEL FUEGO"
                },
                {
                    "$elt": "Tucumán",
                    "$id": "TUCUMÁN"
                }
            ],
            "validationError": "Your postcode must be 8 characters in the following format: A9999AAA"
        },
        "AT": {
            "cityLabel": "City",
            "line1Label": "Street name and house number",
            "line2Label": "Additional information",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 4 digits."
        },
        "AU": {
            "cityLabel": "City/Suburb",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "selectLabel": "Select",
            "stateLabel": "State/territory",
            "stateList": [
                {
                    "$elt": "Australian Capital Territory",
                    "$id": "ACT"
                },
                {
                    "$elt": "New South Wales",
                    "$id": "NSW"
                },
                {
                    "$elt": "Northern Territory",
                    "$id": "NT"
                },
                {
                    "$elt": "Queensland",
                    "$id": "QLD"
                },
                {
                    "$elt": "South Australia",
                    "$id": "SA"
                },
                {
                    "$elt": "Tasmania",
                    "$id": "TAS"
                },
                {
                    "$elt": "Victoria",
                    "$id": "VIC"
                },
                {
                    "$elt": "Western Australia",
                    "$id": "WA"
                }
            ],
            "validationError": "Your postcode must be 4 digits."
        },
        "AW": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "AZ": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "BA": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "BB": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "BE": {
            "cityLabel": "City",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 4 digits long and cannot begin with 0."
        },
        "BF": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "BG": {
            "cityLabel": "City",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 4 digits long."
        },
        "BH": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "BI": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "BJ": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "BM": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "BN": {
            "cityLabel": "Locality",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "District / Capital",
            "stateList": [
                {
                    "$elt": "Bandar Seri Begawan",
                    "$id": "Bandar Seri Begawan"
                },
                {
                    "$elt": "Belait",
                    "$id": "Belait"
                },
                {
                    "$elt": "Brunei-Muara",
                    "$id": "Brunei-Muara"
                },
                {
                    "$elt": "Temburong",
                    "$id": "Temburong"
                },
                {
                    "$elt": "Tutong",
                    "$id": "Tutong"
                }
            ],
            "validationError": "Your postcode must consist of 2 letters followed by 4 digits."
        },
        "BO": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "BR": {
            "cityLabel": "City",
            "line1Label": "Address",
            "line2Label": "District/Neighbourhood",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "selectLabel": "Select",
            "stateLabel": "State",
            "stateList": [
                {
                    "$elt": "Acre",
                    "$id": "AC"
                },
                {
                    "$elt": "Alagoas",
                    "$id": "AL"
                },
                {
                    "$elt": "Amapá",
                    "$id": "AP"
                },
                {
                    "$elt": "Amazonas",
                    "$id": "AM"
                },
                {
                    "$elt": "Bahia",
                    "$id": "BA"
                },
                {
                    "$elt": "Ceará",
                    "$id": "CE"
                },
                {
                    "$elt": "Distrito Federal",
                    "$id": "DF"
                },
                {
                    "$elt": "Espírito Santo",
                    "$id": "ES"
                },
                {
                    "$elt": "Goiás",
                    "$id": "GO"
                },
                {
                    "$elt": "Maranhão",
                    "$id": "MA"
                },
                {
                    "$elt": "Mato Grosso",
                    "$id": "MT"
                },
                {
                    "$elt": "Mato Grosso do Sul",
                    "$id": "MS"
                },
                {
                    "$elt": "Minas Gerais",
                    "$id": "MG"
                },
                {
                    "$elt": "Pará",
                    "$id": "PA"
                },
                {
                    "$elt": "Paraíba",
                    "$id": "PB"
                },
                {
                    "$elt": "Paraná",
                    "$id": "PR"
                },
                {
                    "$elt": "Pernambuco",
                    "$id": "PE"
                },
                {
                    "$elt": "Piauí",
                    "$id": "PI"
                },
                {
                    "$elt": "Rio de Janeiro",
                    "$id": "RJ"
                },
                {
                    "$elt": "Rio Grande do Norte",
                    "$id": "RN"
                },
                {
                    "$elt": "Rio Grande do Sul",
                    "$id": "RS"
                },
                {
                    "$elt": "Rondônia",
                    "$id": "RO"
                },
                {
                    "$elt": "Roraima",
                    "$id": "RR"
                },
                {
                    "$elt": "Santa Catarina",
                    "$id": "SC"
                },
                {
                    "$elt": "São Paulo",
                    "$id": "SP"
                },
                {
                    "$elt": "Sergipe",
                    "$id": "SE"
                },
                {
                    "$elt": "Tocantins",
                    "$id": "TO"
                }
            ],
            "validationError": "Your postcode must be 8 digits long."
        },
        "BS": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "BT": {
            "cityLabel": "City",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 5 digits."
        },
        "BW": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "BY": {
            "cityLabel": "Locality",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province",
            "stateList": [
                {
                    "$elt": "Brest Region",
                    "$id": "Brestskaya Region"
                },
                {
                    "$elt": "Gomel Region",
                    "$id": "Gomel'skaya Region"
                },
                {
                    "$elt": "Grodno Region",
                    "$id": "Grodnenskaya Region"
                },
                {
                    "$elt": "Minsk",
                    "$id": "Minsk"
                },
                {
                    "$elt": "Minsk Region",
                    "$id": "Minskaya Region"
                },
                {
                    "$elt": "Mogilev Region",
                    "$id": "Mogilevskaya Region"
                },
                {
                    "$elt": "Vitebsk Region",
                    "$id": "Vitebskaya Region"
                }
            ],
            "validationError": "Your postcode must be 6 digits."
        },
        "BZ": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "C2": {
            "cityLabel": "City",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "selectLabel": "Select",
            "stateLabel": "Province/Municipality",
            "stateList": [
                {
                    "$elt": "Anhui",
                    "$id": "ANHUI"
                },
                {
                    "$elt": "Beijing",
                    "$id": "BEIJING"
                },
                {
                    "$elt": "Chongqing",
                    "$id": "CHONGQING"
                },
                {
                    "$elt": "Fujian",
                    "$id": "FUJIAN"
                },
                {
                    "$elt": "Gansu",
                    "$id": "GANSU"
                },
                {
                    "$elt": "Guangdong",
                    "$id": "GUANGDONG"
                },
                {
                    "$elt": "Guangxi",
                    "$id": "GUANGXI"
                },
                {
                    "$elt": "Guizhou",
                    "$id": "GUIZHOU"
                },
                {
                    "$elt": "Hainan",
                    "$id": "HAINAN"
                },
                {
                    "$elt": "Hebei",
                    "$id": "HEBEI"
                },
                {
                    "$elt": "Heilongjiang",
                    "$id": "HEILONGJIANG"
                },
                {
                    "$elt": "Henan",
                    "$id": "HENAN"
                },
                {
                    "$elt": "Hong Kong",
                    "$id": "XIANGGANG"
                },
                {
                    "$elt": "Hubei",
                    "$id": "HUBEI"
                },
                {
                    "$elt": "Hunan",
                    "$id": "HUNAN"
                },
                {
                    "$elt": "Inner Mongolia",
                    "$id": "NEIMENGGU"
                },
                {
                    "$elt": "Jiangsu",
                    "$id": "JIANGSU"
                },
                {
                    "$elt": "Jiangxi",
                    "$id": "JIANGXI"
                },
                {
                    "$elt": "Jilin",
                    "$id": "JILIN"
                },
                {
                    "$elt": "Liaoning",
                    "$id": "LIAONING"
                },
                {
                    "$elt": "Macau",
                    "$id": "AOMEN"
                },
                {
                    "$elt": "Ningxia",
                    "$id": "NINGXIA"
                },
                {
                    "$elt": "Qinghai",
                    "$id": "QINGHAI"
                },
                {
                    "$elt": "Shaanxi",
                    "$id": "SHAANXI"
                },
                {
                    "$elt": "Shandong",
                    "$id": "SHANDONG"
                },
                {
                    "$elt": "Shanghai",
                    "$id": "SHANGHAI"
                },
                {
                    "$elt": "Shanxi",
                    "$id": "SHANXI"
                },
                {
                    "$elt": "Sichuan",
                    "$id": "SICHUAN"
                },
                {
                    "$elt": "Taiwan",
                    "$id": "TAIWAN"
                },
                {
                    "$elt": "Tianjin",
                    "$id": "TIANJIN"
                },
                {
                    "$elt": "Tibet",
                    "$id": "XIZANG"
                },
                {
                    "$elt": "Xinjiang",
                    "$id": "XINJIANG"
                },
                {
                    "$elt": "Yunnan",
                    "$id": "YUNNAN"
                },
                {
                    "$elt": "Zhejiang",
                    "$id": "ZHEJIANG"
                }
            ],
            "validationError": "Your postcode must be 6 digits long."
        },
        "CA": {
            "cityLabel": "City",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "selectLabel": "Select",
            "stateLabel": "Province",
            "stateList": [
                {
                    "$elt": "Alberta",
                    "$id": "AB"
                },
                {
                    "$elt": "British Columbia",
                    "$id": "BC"
                },
                {
                    "$elt": "Manitoba",
                    "$id": "MB"
                },
                {
                    "$elt": "New Brunswick",
                    "$id": "NB"
                },
                {
                    "$elt": "Newfoundland and Labrador",
                    "$id": "NL"
                },
                {
                    "$elt": "Northwest Territories",
                    "$id": "NT"
                },
                {
                    "$elt": "Nova Scotia",
                    "$id": "NS"
                },
                {
                    "$elt": "Nunavut",
                    "$id": "NU"
                },
                {
                    "$elt": "Ontario",
                    "$id": "ON"
                },
                {
                    "$elt": "Prince Edward Island",
                    "$id": "PE"
                },
                {
                    "$elt": "Quebec",
                    "$id": "QC"
                },
                {
                    "$elt": "Saskatchewan",
                    "$id": "SK"
                },
                {
                    "$elt": "Yukon",
                    "$id": "YT"
                }
            ],
            "validationError": "Your postcode must be in the format A9A or A9A9A9."
        },
        "CD": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "CG": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "CH": {
            "cityLabel": "City",
            "line1Label": "Street name and house number",
            "line2Label": "Additional information",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "selectLabel": "Select",
            "stateLabel": "Canton",
            "stateList": [
                {
                    "$elt": "Aargau",
                    "$id": "AG"
                },
                {
                    "$elt": "Appenzell Ausserrhoden",
                    "$id": "AR"
                },
                {
                    "$elt": "Appenzell Innerrhoden",
                    "$id": "AI"
                },
                {
                    "$elt": "Basel-Landschaft",
                    "$id": "BL"
                },
                {
                    "$elt": "Basel-Stadt",
                    "$id": "BS"
                },
                {
                    "$elt": "Bern",
                    "$id": "BE"
                },
                {
                    "$elt": "Fribourg",
                    "$id": "FR"
                },
                {
                    "$elt": "Geneva",
                    "$id": "GE"
                },
                {
                    "$elt": "Glarus",
                    "$id": "GL"
                },
                {
                    "$elt": "Graubünden",
                    "$id": "GR"
                },
                {
                    "$elt": "Jura",
                    "$id": "JU"
                },
                {
                    "$elt": "Lucerne",
                    "$id": "LU"
                },
                {
                    "$elt": "Neuchâtel",
                    "$id": "NE"
                },
                {
                    "$elt": "Nidwalden",
                    "$id": "NW"
                },
                {
                    "$elt": "Obwalden",
                    "$id": "OW"
                },
                {
                    "$elt": "Schaffhausen",
                    "$id": "SH"
                },
                {
                    "$elt": "Schwyz",
                    "$id": "SZ"
                },
                {
                    "$elt": "Solothurn",
                    "$id": "SO"
                },
                {
                    "$elt": "St. Gallen",
                    "$id": "SG"
                },
                {
                    "$elt": "Thurgau",
                    "$id": "TG"
                },
                {
                    "$elt": "Ticino",
                    "$id": "TI"
                },
                {
                    "$elt": "Uri",
                    "$id": "UR"
                },
                {
                    "$elt": "Valais",
                    "$id": "VS"
                },
                {
                    "$elt": "Vaud",
                    "$id": "VD"
                },
                {
                    "$elt": "Zug",
                    "$id": "ZG"
                },
                {
                    "$elt": "Zürich",
                    "$id": "ZH"
                }
            ],
            "validationError": "Your postcode must be 4 digits long."
        },
        "CI": {
            "cityLabel": "City",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode"
        },
        "CK": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "CL": {
            "cityLabel": "Commune/Locality",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Region",
            "stateList": [
                {
                    "$elt": "Región Metropolitana de Santiago",
                    "$id": "REGIÓN METROPOLITANA"
                },
                {
                    "$elt": "I Región de Tarapacá",
                    "$id": "I REGIÓN DE TARAPACÁ"
                },
                {
                    "$elt": "II Región de Antofagasta",
                    "$id": "II REGIÓN DE ANTOFAGASTA"
                },
                {
                    "$elt": "III Región de Atacama",
                    "$id": "III REGIÓN DE ATACAMA"
                },
                {
                    "$elt": "IV Región de Coquimbo",
                    "$id": "IV REGIÓN DE COQUIMBO"
                },
                {
                    "$elt": "V Región de Valparaíso",
                    "$id": "V REGIÓN DE VALPARAÍSO"
                },
                {
                    "$elt": "VI Región de O'Higgins",
                    "$id": "VI REGIÓN DE O'HIGGINS"
                },
                {
                    "$elt": "VII Región del Maule",
                    "$id": "VII REGIÓN DEL MAULE"
                },
                {
                    "$elt": "VIII Región del Bío Bío",
                    "$id": "VIII REGIÓN DEL BIOBÍO"
                },
                {
                    "$elt": "IX Región de la Araucanía",
                    "$id": "IX REGIÓN DE LA ARAUCANÍA"
                },
                {
                    "$elt": "X Región de Los Lagos",
                    "$id": "X REGIÓN DE LOS LAGOS"
                },
                {
                    "$elt": "XI Región de Aysén",
                    "$id": "XI REGIÓN DE AYSÉN"
                },
                {
                    "$elt": "XII Región de Magallanes",
                    "$id": "XII REGIÓN DE MAGALLANES"
                },
                {
                    "$elt": "XIV Región de Los Ríos",
                    "$id": "XIV REGIÓN DE LOS RÍOS"
                },
                {
                    "$elt": "XV Región de Arica y Parinacota",
                    "$id": "XV REGIÓN DE ARICA Y PARINACOTA"
                }
            ],
            "validationError": "Your postcode must be 7 digits long."
        },
        "CM": {
            "cityLabel": "Locality",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)"
        },
        "CN": {
            "cityLabel": "City",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "selectLabel": "Select",
            "stateLabel": "Province/Municipality",
            "stateList": [
                {
                    "$elt": "Anhui",
                    "$id": "ANHUI"
                },
                {
                    "$elt": "Beijing",
                    "$id": "BEIJING"
                },
                {
                    "$elt": "Chongqing",
                    "$id": "CHONGQING"
                },
                {
                    "$elt": "Fujian",
                    "$id": "FUJIAN"
                },
                {
                    "$elt": "Gansu",
                    "$id": "GANSU"
                },
                {
                    "$elt": "Guangdong",
                    "$id": "GUANGDONG"
                },
                {
                    "$elt": "Guangxi",
                    "$id": "GUANGXI"
                },
                {
                    "$elt": "Guizhou",
                    "$id": "GUIZHOU"
                },
                {
                    "$elt": "Hainan",
                    "$id": "HAINAN"
                },
                {
                    "$elt": "Hebei",
                    "$id": "HEBEI"
                },
                {
                    "$elt": "Heilongjiang",
                    "$id": "HEILONGJIANG"
                },
                {
                    "$elt": "Henan",
                    "$id": "HENAN"
                },
                {
                    "$elt": "Hong Kong",
                    "$id": "XIANGGANG"
                },
                {
                    "$elt": "Hubei",
                    "$id": "HUBEI"
                },
                {
                    "$elt": "Hunan",
                    "$id": "HUNAN"
                },
                {
                    "$elt": "Inner Mongolia",
                    "$id": "NEIMENGGU"
                },
                {
                    "$elt": "Jiangsu",
                    "$id": "JIANGSU"
                },
                {
                    "$elt": "Jiangxi",
                    "$id": "JIANGXI"
                },
                {
                    "$elt": "Jilin",
                    "$id": "JILIN"
                },
                {
                    "$elt": "Liaoning",
                    "$id": "LIAONING"
                },
                {
                    "$elt": "Macau",
                    "$id": "AOMEN"
                },
                {
                    "$elt": "Ningxia",
                    "$id": "NINGXIA"
                },
                {
                    "$elt": "Qinghai",
                    "$id": "QINGHAI"
                },
                {
                    "$elt": "Shaanxi",
                    "$id": "SHAANXI"
                },
                {
                    "$elt": "Shandong",
                    "$id": "SHANDONG"
                },
                {
                    "$elt": "Shanghai",
                    "$id": "SHANGHAI"
                },
                {
                    "$elt": "Shanxi",
                    "$id": "SHANXI"
                },
                {
                    "$elt": "Sichuan",
                    "$id": "SICHUAN"
                },
                {
                    "$elt": "Taiwan",
                    "$id": "TAIWAN"
                },
                {
                    "$elt": "Tianjin",
                    "$id": "TIANJIN"
                },
                {
                    "$elt": "Tibet",
                    "$id": "XIZANG"
                },
                {
                    "$elt": "Xinjiang",
                    "$id": "XINJIANG"
                },
                {
                    "$elt": "Yunnan",
                    "$id": "YUNNAN"
                },
                {
                    "$elt": "Zhejiang",
                    "$id": "ZHEJIANG"
                }
            ],
            "validationError": "Your postcode must be 6 digits long."
        },
        "CO": {
            "cityLabel": "City",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Department/Capital District",
            "stateList": [
                {
                    "$elt": "Amazonas",
                    "$id": "AMAZONAS"
                },
                {
                    "$elt": "Antioquia",
                    "$id": "ANTIOQUIA"
                },
                {
                    "$elt": "Arauca",
                    "$id": "ARAUCA"
                },
                {
                    "$elt": "Archipiélago de San Andrés, Providencia y Santa Catalina",
                    "$id": "ARCHIPIÉLAGO DE SAN ANDRÉS"
                },
                {
                    "$elt": "Atlántico",
                    "$id": "ATLÁNTICO"
                },
                {
                    "$elt": "Bolívar",
                    "$id": "BOLÍVAR"
                },
                {
                    "$elt": "Boyacá",
                    "$id": "BOYACÁ"
                },
                {
                    "$elt": "Caldas",
                    "$id": "CALDAS"
                },
                {
                    "$elt": "Caquetá",
                    "$id": "CAQUETÁ"
                },
                {
                    "$elt": "Casanare",
                    "$id": "CASANARE"
                },
                {
                    "$elt": "Cauca",
                    "$id": "CAUCA"
                },
                {
                    "$elt": "Cesar",
                    "$id": "CESAR"
                },
                {
                    "$elt": "Chocó",
                    "$id": "CHOCÓ"
                },
                {
                    "$elt": "Córdoba",
                    "$id": "CÓRDOBA"
                },
                {
                    "$elt": "Cundinamarca",
                    "$id": "CUNDINAMARCA"
                },
                {
                    "$elt": "Distrito Capital de Bogotá",
                    "$id": "DISTRITO CAPITAL DE BOGOTÁ"
                },
                {
                    "$elt": "Guainía",
                    "$id": "GUAINÍA"
                },
                {
                    "$elt": "Guaviare",
                    "$id": "GUAVIARE"
                },
                {
                    "$elt": "Huila",
                    "$id": "HUILA"
                },
                {
                    "$elt": "La Guajira",
                    "$id": "LA GUAJIRA"
                },
                {
                    "$elt": "Magdalena",
                    "$id": "MAGDALENA"
                },
                {
                    "$elt": "Meta",
                    "$id": "META"
                },
                {
                    "$elt": "Nariño",
                    "$id": "NARIÑO"
                },
                {
                    "$elt": "Norte de Santander",
                    "$id": "NORTE DE SANTANDER"
                },
                {
                    "$elt": "Putumayo",
                    "$id": "PUTUMAYO"
                },
                {
                    "$elt": "Quindío",
                    "$id": "QUINDÍO"
                },
                {
                    "$elt": "Risaralda",
                    "$id": "RISARALDA"
                },
                {
                    "$elt": "Santander",
                    "$id": "SANTANDER"
                },
                {
                    "$elt": "Sucre",
                    "$id": "SUCRE"
                },
                {
                    "$elt": "Tolima",
                    "$id": "TOLIMA"
                },
                {
                    "$elt": "Valle del Cauca",
                    "$id": "VALLE DEL CAUCA"
                },
                {
                    "$elt": "Vaupés",
                    "$id": "VAUPÉS"
                },
                {
                    "$elt": "Vichada",
                    "$id": "VICHADA"
                }
            ],
            "validationError": "Your postcode must be 6 digits long."
        },
        "CR": {
            "cityLabel": "Canton",
            "line1Label": "Address line 1",
            "line2Label": "District",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province",
            "stateList": [
                {
                    "$elt": "Alajuela",
                    "$id": "Alajuela"
                },
                {
                    "$elt": "Cartago",
                    "$id": "Cartago"
                },
                {
                    "$elt": "Guanacaste",
                    "$id": "Guanacaste"
                },
                {
                    "$elt": "Heredia",
                    "$id": "Heredia"
                },
                {
                    "$elt": "Limón",
                    "$id": "Limón"
                },
                {
                    "$elt": "Puntarenas",
                    "$id": "Puntarenas"
                },
                {
                    "$elt": "San José",
                    "$id": "San José"
                }
            ],
            "validationError": "Your postcode must be formatted as: 9999, 99999 or 999-9999."
        },
        "CV": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "CY": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 4 digits."
        },
        "CZ": {
            "cityLabel": "Town/City/Delivery post office",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 5 digits with an optional space after the first 3 digits."
        },
        "DE": {
            "cityLabel": "City",
            "line1Label": "Street name and house number",
            "line2Label": "Additional information",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 5 digits long and cannot begin with 00."
        },
        "DJ": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "DK": {
            "cityLabel": "City",
            "line1Label": "Street name and house number",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 4 digits."
        },
        "DM": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "DO": {
            "cityLabel": "Municipality",
            "line1Label": "Street name and house number",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/National District",
            "stateList": [
                {
                    "$elt": "Azua",
                    "$id": "AZUA"
                },
                {
                    "$elt": "Bahoruco",
                    "$id": "BAHORUCO"
                },
                {
                    "$elt": "Barahona",
                    "$id": "BARAHONA"
                },
                {
                    "$elt": "Dajabón",
                    "$id": "DAJABÓN"
                },
                {
                    "$elt": "Distrito Nacional (Santo Domingo)",
                    "$id": "DN"
                },
                {
                    "$elt": "Duarte",
                    "$id": "DUARTE"
                },
                {
                    "$elt": "El Seibo",
                    "$id": "EL SEIBO"
                },
                {
                    "$elt": "Elías Piña",
                    "$id": "ELÍAS PIÑA"
                },
                {
                    "$elt": "Espaillat",
                    "$id": "ESPAILLAT"
                },
                {
                    "$elt": "Hato Mayor",
                    "$id": "HATO MAYOR"
                },
                {
                    "$elt": "Hermanas Mirabal",
                    "$id": "HERMANAS MIRABAL"
                },
                {
                    "$elt": "Independencia",
                    "$id": "INDEPENDENCIA"
                },
                {
                    "$elt": "La Altagracia",
                    "$id": "LA ALTAGRACIA"
                },
                {
                    "$elt": "La Romana",
                    "$id": "LA ROMANA"
                },
                {
                    "$elt": "La Vega",
                    "$id": "LA VEGA"
                },
                {
                    "$elt": "María Trinidad Sánchez",
                    "$id": "MARÍA TRINIDAD SÁNCHEZ"
                },
                {
                    "$elt": "Monseñor Nouel",
                    "$id": "MONSEÑOR NOUEL"
                },
                {
                    "$elt": "Monte Cristi",
                    "$id": "MONTE CRISTI"
                },
                {
                    "$elt": "Monte Plata",
                    "$id": "MONTE PLATA"
                },
                {
                    "$elt": "Pedernales",
                    "$id": "PEDERNALES"
                },
                {
                    "$elt": "Peravia",
                    "$id": "PERAVIA"
                },
                {
                    "$elt": "Puerto Plata",
                    "$id": "PUERTO PLATA"
                },
                {
                    "$elt": "Samaná",
                    "$id": "SAMANÁ"
                },
                {
                    "$elt": "San Cristóbal",
                    "$id": "SAN CRISTÓBAL"
                },
                {
                    "$elt": "San José de Ocoa",
                    "$id": "SAN JOSÉ DE OCOA"
                },
                {
                    "$elt": "San Juan",
                    "$id": "SAN JUAN"
                },
                {
                    "$elt": "San Pedro de Macorís",
                    "$id": "SAN PEDRO DE MACORÍS"
                },
                {
                    "$elt": "Sánchez Ramírez",
                    "$id": "SÁNCHEZ RAMÍREZ"
                },
                {
                    "$elt": "Santiago",
                    "$id": "SANTIAGO"
                },
                {
                    "$elt": "Santiago Rodríguez",
                    "$id": "SANTIAGO RODRÍGUEZ"
                },
                {
                    "$elt": "Santo Domingo",
                    "$id": "SANTO DOMINGO"
                },
                {
                    "$elt": "Valverde",
                    "$id": "VALVERDE"
                }
            ],
            "validationError": "Your postcode must be 5 digits long."
        },
        "DZ": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "EC": {
            "cityLabel": "Town/City",
            "line1Label": "Street name and house number",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 6 digits long."
        },
        "EE": {
            "cityLabel": "District/Parish",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Town/City/County",
            "validationError": "Your postcode must be 5 digits."
        },
        "EG": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "ER": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "ES": {
            "cityLabel": "City",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "selectLabel": "Select",
            "stateLabel": "Province/Autonomous city",
            "stateList": [
                {
                    "$elt": "A Coruña",
                    "$id": "A CORUÑA"
                },
                {
                    "$elt": "Albacete",
                    "$id": "ALBACETE"
                },
                {
                    "$elt": "Alicante",
                    "$id": "ALICANTE"
                },
                {
                    "$elt": "Almería",
                    "$id": "ALMERÍA"
                },
                {
                    "$elt": "Araba/Álava",
                    "$id": "ARABA/ÁLAVA"
                },
                {
                    "$elt": "Asturias",
                    "$id": "ASTURIAS"
                },
                {
                    "$elt": "Ávila",
                    "$id": "ÁVILA"
                },
                {
                    "$elt": "Badajoz",
                    "$id": "BADAJOZ"
                },
                {
                    "$elt": "Barcelona",
                    "$id": "BARCELONA"
                },
                {
                    "$elt": "Bizkaia",
                    "$id": "BIZKAIA"
                },
                {
                    "$elt": "Burgos",
                    "$id": "BURGOS"
                },
                {
                    "$elt": "Cáceres",
                    "$id": "CÁCERES"
                },
                {
                    "$elt": "Cádiz",
                    "$id": "CÁDIZ"
                },
                {
                    "$elt": "Cantabria",
                    "$id": "CANTABRIA"
                },
                {
                    "$elt": "Castellón",
                    "$id": "CASTELLÓN"
                },
                {
                    "$elt": "Ceuta",
                    "$id": "CEUTA"
                },
                {
                    "$elt": "Ciudad Real",
                    "$id": "CIUDAD REAL"
                },
                {
                    "$elt": "Córdoba",
                    "$id": "CÓRDOBA"
                },
                {
                    "$elt": "Cuenca",
                    "$id": "CUENCA"
                },
                {
                    "$elt": "Gipuzkoa",
                    "$id": "GIPUZKOA"
                },
                {
                    "$elt": "Girona",
                    "$id": "GIRONA"
                },
                {
                    "$elt": "Granada",
                    "$id": "GRANADA"
                },
                {
                    "$elt": "Guadalajara",
                    "$id": "GUADALAJARA"
                },
                {
                    "$elt": "Huelva",
                    "$id": "HUELVA"
                },
                {
                    "$elt": "Huesca",
                    "$id": "HUESCA"
                },
                {
                    "$elt": "Illes Balears",
                    "$id": "ILLES BALEARS"
                },
                {
                    "$elt": "Jaén",
                    "$id": "JAÉN"
                },
                {
                    "$elt": "La Rioja",
                    "$id": "LA RIOJA"
                },
                {
                    "$elt": "Las Palmas",
                    "$id": "LAS PALMAS"
                },
                {
                    "$elt": "León",
                    "$id": "LEÓN"
                },
                {
                    "$elt": "Lleida",
                    "$id": "LLEIDA"
                },
                {
                    "$elt": "Lugo",
                    "$id": "LUGO"
                },
                {
                    "$elt": "Madrid",
                    "$id": "MADRID"
                },
                {
                    "$elt": "Málaga",
                    "$id": "MÁLAGA"
                },
                {
                    "$elt": "Melilla",
                    "$id": "MELILLA"
                },
                {
                    "$elt": "Murcia",
                    "$id": "MURCIA"
                },
                {
                    "$elt": "Navarra",
                    "$id": "NAVARRA"
                },
                {
                    "$elt": "Ourense",
                    "$id": "OURENSE"
                },
                {
                    "$elt": "Palencia",
                    "$id": "PALENCIA"
                },
                {
                    "$elt": "Pontevedra",
                    "$id": "PONTEVEDRA"
                },
                {
                    "$elt": "Salamanca",
                    "$id": "SALAMANCA"
                },
                {
                    "$elt": "Santa Cruz de Tenerife",
                    "$id": "SANTA CRUZ DE TENERIFE"
                },
                {
                    "$elt": "Segovia",
                    "$id": "SEGOVIA"
                },
                {
                    "$elt": "Sevilla",
                    "$id": "SEVILLA"
                },
                {
                    "$elt": "Soria",
                    "$id": "SORIA"
                },
                {
                    "$elt": "Tarragona",
                    "$id": "TARRAGONA"
                },
                {
                    "$elt": "Teruel",
                    "$id": "TERUEL"
                },
                {
                    "$elt": "Toledo",
                    "$id": "TOLEDO"
                },
                {
                    "$elt": "Valencia",
                    "$id": "VALENCIA"
                },
                {
                    "$elt": "Valladolid",
                    "$id": "VALLADOLID"
                },
                {
                    "$elt": "Zamora",
                    "$id": "ZAMORA"
                },
                {
                    "$elt": "Zaragoza",
                    "$id": "ZARAGOZA"
                }
            ],
            "validationError": "Your postcode must be 5 digits."
        },
        "ET": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "FI": {
            "cityLabel": "City",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 5 digits."
        },
        "FJ": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "FK": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "FM": {
            "cityLabel": "City",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "State",
            "validationError": "Your postcode must be 5 digits long and can be followed by a dash and 4 digits."
        },
        "FO": {
            "cityLabel": "City",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must begin with \"FO-\" and be followed by 3 digits."
        },
        "FR": {
            "cityLabel": "City",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 5 digits."
        },
        "GA": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "GB": {
            "cityLabel": "Town/City",
            "groupStateList": [
                {
                    "$id": "EnglandCountyList",
                    "group": [
                        {
                            "$elt": "Avon",
                            "$id": "AVON"
                        },
                        {
                            "$elt": "Bedfordshire",
                            "$id": "BEDFORDSHIRE"
                        },
                        {
                            "$elt": "Berkshire",
                            "$id": "BERKSHIRE"
                        },
                        {
                            "$elt": "Buckinghamshire",
                            "$id": "BUCKINGHAMSHIRE"
                        },
                        {
                            "$elt": "Cambridgeshire",
                            "$id": "CAMBRIDGESHIRE"
                        },
                        {
                            "$elt": "Cheshire",
                            "$id": "CHESHIRE"
                        },
                        {
                            "$elt": "Cleveland",
                            "$id": "CLEVELAND"
                        },
                        {
                            "$elt": "Cornwall",
                            "$id": "CORNWALL"
                        },
                        {
                            "$elt": "County Durham",
                            "$id": "COUNTY DURHAM"
                        },
                        {
                            "$elt": "Cumbria",
                            "$id": "CUMBRIA"
                        },
                        {
                            "$elt": "Derbyshire",
                            "$id": "DERBYSHIRE"
                        },
                        {
                            "$elt": "Devon",
                            "$id": "DEVON"
                        },
                        {
                            "$elt": "Dorset",
                            "$id": "DORSET"
                        },
                        {
                            "$elt": "East Riding of Yorkshire",
                            "$id": "EAST RIDING OF YORKSHIRE"
                        },
                        {
                            "$elt": "East Sussex",
                            "$id": "EAST SUSSEX"
                        },
                        {
                            "$elt": "Essex",
                            "$id": "ESSEX"
                        },
                        {
                            "$elt": "Gloucestershire",
                            "$id": "GLOUCESTERSHIRE"
                        },
                        {
                            "$elt": "Greater Manchester",
                            "$id": "GREATER MANCHESTER"
                        },
                        {
                            "$elt": "Hampshire",
                            "$id": "HAMPSHIRE"
                        },
                        {
                            "$elt": "Herefordshire",
                            "$id": "HEREFORDSHIRE"
                        },
                        {
                            "$elt": "Hertfordshire",
                            "$id": "HERTFORDSHIRE"
                        },
                        {
                            "$elt": "Isle of Wight",
                            "$id": "ISLE OF WIGHT"
                        },
                        {
                            "$elt": "Isles of Scilly",
                            "$id": "ISLES OF SCILLY"
                        },
                        {
                            "$elt": "Kent",
                            "$id": "KENT"
                        },
                        {
                            "$elt": "Lancashire",
                            "$id": "LANCASHIRE"
                        },
                        {
                            "$elt": "Leicestershire",
                            "$id": "LEICESTERSHIRE"
                        },
                        {
                            "$elt": "Lincolnshire",
                            "$id": "LINCOLNSHIRE"
                        },
                        {
                            "$elt": "London",
                            "$id": "LONDON"
                        },
                        {
                            "$elt": "Merseyside",
                            "$id": "MERSEYSIDE"
                        },
                        {
                            "$elt": "Middlesex",
                            "$id": "MIDDLESEX"
                        },
                        {
                            "$elt": "Norfolk",
                            "$id": "NORFOLK"
                        },
                        {
                            "$elt": "North East Lincolnshire",
                            "$id": "NORTH EAST LINCOLNSHIRE"
                        },
                        {
                            "$elt": "North Humberside",
                            "$id": "NORTH HUMBERSIDE"
                        },
                        {
                            "$elt": "North Yorkshire",
                            "$id": "NORTH YORKSHIRE"
                        },
                        {
                            "$elt": "Northamptonshire",
                            "$id": "NORTHAMPTONSHIRE"
                        },
                        {
                            "$elt": "Northumberland",
                            "$id": "NORTHUMBERLAND"
                        },
                        {
                            "$elt": "Nottinghamshire",
                            "$id": "NOTTINGHAMSHIRE"
                        },
                        {
                            "$elt": "Oxfordshire",
                            "$id": "OXFORDSHIRE"
                        },
                        {
                            "$elt": "Rutland",
                            "$id": "RUTLAND"
                        },
                        {
                            "$elt": "Shropshire",
                            "$id": "SHROPSHIRE"
                        },
                        {
                            "$elt": "Somerset",
                            "$id": "SOMERSET"
                        },
                        {
                            "$elt": "South Humberside",
                            "$id": "SOUTH HUMBERSIDE"
                        },
                        {
                            "$elt": "South Yorkshire",
                            "$id": "SOUTH YORKSHIRE"
                        },
                        {
                            "$elt": "Staffordshire",
                            "$id": "STAFFORDSHIRE"
                        },
                        {
                            "$elt": "Suffolk",
                            "$id": "SUFFOLK"
                        },
                        {
                            "$elt": "Surrey",
                            "$id": "SURREY"
                        },
                        {
                            "$elt": "Sussex",
                            "$id": "SUSSEX"
                        },
                        {
                            "$elt": "Tyne and Wear",
                            "$id": "TYNE AND WEAR"
                        },
                        {
                            "$elt": "Warwickshire",
                            "$id": "WARWICKSHIRE"
                        },
                        {
                            "$elt": "West Midlands",
                            "$id": "WEST MIDLANDS"
                        },
                        {
                            "$elt": "West Sussex",
                            "$id": "WEST SUSSEX"
                        },
                        {
                            "$elt": "West Yorkshire",
                            "$id": "WEST YORKSHIRE"
                        },
                        {
                            "$elt": "Wiltshire",
                            "$id": "WILTSHIRE"
                        },
                        {
                            "$elt": "Worcestershire",
                            "$id": "WORCESTERSHIRE"
                        }
                    ],
                    "label": "England"
                },
                {
                    "$id": "ScotlandCountyList",
                    "group": [
                        {
                            "$elt": "Aberdeen City",
                            "$id": "ABERDEEN CITY"
                        },
                        {
                            "$elt": "Aberdeenshire",
                            "$id": "ABERDEENSHIRE"
                        },
                        {
                            "$elt": "Angus",
                            "$id": "ANGUS"
                        },
                        {
                            "$elt": "Argyll",
                            "$id": "ARGYLL"
                        },
                        {
                            "$elt": "Argyll and Bute",
                            "$id": "ARGYLL AND BUTE"
                        },
                        {
                            "$elt": "Ayrshire",
                            "$id": "AYRSHIRE"
                        },
                        {
                            "$elt": "Banffshire",
                            "$id": "BANFFSHIRE"
                        },
                        {
                            "$elt": "Berwickshire",
                            "$id": "BERWICKSHIRE"
                        },
                        {
                            "$elt": "Borders",
                            "$id": "BORDERS"
                        },
                        {
                            "$elt": "Bute",
                            "$id": "BUTE"
                        },
                        {
                            "$elt": "Caithness",
                            "$id": "CAITHNESS"
                        },
                        {
                            "$elt": "Central",
                            "$id": "CENTRAL"
                        },
                        {
                            "$elt": "Clackmannanshire",
                            "$id": "CLACKMANNANSHIRE"
                        },
                        {
                            "$elt": "Dumfries and Galloway",
                            "$id": "DUMFRIES AND GALLOWAY"
                        },
                        {
                            "$elt": "Dumfriesshire",
                            "$id": "DUMFRIESSHIRE"
                        },
                        {
                            "$elt": "Dunbartonshire",
                            "$id": "DUNBARTONSHIRE"
                        },
                        {
                            "$elt": "Dundee City",
                            "$id": "DUNDEE CITY"
                        },
                        {
                            "$elt": "East Ayrshire",
                            "$id": "EAST AYRSHIRE"
                        },
                        {
                            "$elt": "East Dunbartonshire",
                            "$id": "EAST DUNBARTONSHIRE"
                        },
                        {
                            "$elt": "East Lothian",
                            "$id": "EAST LOTHIAN"
                        },
                        {
                            "$elt": "East Renfrewshire",
                            "$id": "EAST RENFREWSHIRE"
                        },
                        {
                            "$elt": "Edinburgh City",
                            "$id": "EDINBURGH CITY"
                        },
                        {
                            "$elt": "Falkirk",
                            "$id": "FALKIRK"
                        },
                        {
                            "$elt": "Fife",
                            "$id": "FIFE"
                        },
                        {
                            "$elt": "Glasgow City",
                            "$id": "GLASGOW CITY"
                        },
                        {
                            "$elt": "Grampian",
                            "$id": "GRAMPIAN"
                        },
                        {
                            "$elt": "Highland",
                            "$id": "HIGHLAND"
                        },
                        {
                            "$elt": "Inverclyde",
                            "$id": "INVERCLYDE"
                        },
                        {
                            "$elt": "Inverness-shire",
                            "$id": "INVERNESS-SHIRE"
                        },
                        {
                            "$elt": "Isle of Lewis",
                            "$id": "ISLE OF LEWIS"
                        },
                        {
                            "$elt": "Isle of Skye",
                            "$id": "ISLE OF SKYE"
                        },
                        {
                            "$elt": "Kincardineshire",
                            "$id": "KINCARDINESHIRE"
                        },
                        {
                            "$elt": "Kinross-shire",
                            "$id": "KINROSS-SHIRE"
                        },
                        {
                            "$elt": "Kirkcudbrightshire",
                            "$id": "KIRKCUDBRIGHTSHIRE"
                        },
                        {
                            "$elt": "Lanarkshire",
                            "$id": "LANARKSHIRE"
                        },
                        {
                            "$elt": "Lothian",
                            "$id": "LOTHIAN"
                        },
                        {
                            "$elt": "Midlothian",
                            "$id": "MIDLOTHIAN"
                        },
                        {
                            "$elt": "Moray",
                            "$id": "MORAY"
                        },
                        {
                            "$elt": "Nairn",
                            "$id": "NAIRN"
                        },
                        {
                            "$elt": "North Ayrshire",
                            "$id": "NORTH AYRSHIRE"
                        },
                        {
                            "$elt": "North Lanarkshire",
                            "$id": "NORTH LANARKSHIRE"
                        },
                        {
                            "$elt": "Orkney Islands",
                            "$id": "ORKNEY ISLANDS"
                        },
                        {
                            "$elt": "Peeblesshire",
                            "$id": "PEEBLESSHIRE"
                        },
                        {
                            "$elt": "Perth and Kinross",
                            "$id": "PERTH AND KINROSS"
                        },
                        {
                            "$elt": "Perthshire",
                            "$id": "PERTHSHIRE"
                        },
                        {
                            "$elt": "Renfrewshire",
                            "$id": "RENFREWSHIRE"
                        },
                        {
                            "$elt": "Ross-shire",
                            "$id": "ROSS-SHIRE"
                        },
                        {
                            "$elt": "Roxburghshire",
                            "$id": "ROXBURGHSHIRE"
                        },
                        {
                            "$elt": "Selkirkshire",
                            "$id": "SELKIRKSHIRE"
                        },
                        {
                            "$elt": "Shetland Islands",
                            "$id": "SHETLAND ISLANDS"
                        },
                        {
                            "$elt": "South Ayrshire",
                            "$id": "SOUTH AYRSHIRE"
                        },
                        {
                            "$elt": "South Lanarkshire",
                            "$id": "SOUTH LANARKSHIRE"
                        },
                        {
                            "$elt": "Stirling",
                            "$id": "STIRLING"
                        },
                        {
                            "$elt": "Strathclyde",
                            "$id": "STRATHCLYDE"
                        },
                        {
                            "$elt": "Sutherland",
                            "$id": "SUTHERLAND"
                        },
                        {
                            "$elt": "Tayside",
                            "$id": "TAYSIDE"
                        },
                        {
                            "$elt": "West Dunbartonshire",
                            "$id": "WEST DUNBARTONSHIRE"
                        },
                        {
                            "$elt": "West Lothian",
                            "$id": "WEST LOTHIAN"
                        },
                        {
                            "$elt": "Western Isles",
                            "$id": "WESTERN ISLES"
                        },
                        {
                            "$elt": "Wigtownshire",
                            "$id": "WIGTOWNSHIRE"
                        }
                    ],
                    "label": "Scotland"
                },
                {
                    "$id": "WalesCountyList",
                    "group": [
                        {
                            "$elt": "Blaenau Gwent",
                            "$id": "BLAENAU GWENT"
                        },
                        {
                            "$elt": "Bridgend",
                            "$id": "BRIDGEND"
                        },
                        {
                            "$elt": "Caerphilly",
                            "$id": "CAERPHILLY"
                        },
                        {
                            "$elt": "Cardiff",
                            "$id": "CARDIFF"
                        },
                        {
                            "$elt": "Carmarthenshire",
                            "$id": "CARMARTHENSHIRE"
                        },
                        {
                            "$elt": "Ceredigion",
                            "$id": "CEREDIGION"
                        },
                        {
                            "$elt": "Clwyd",
                            "$id": "CLWYD"
                        },
                        {
                            "$elt": "Conwy",
                            "$id": "CONWY"
                        },
                        {
                            "$elt": "Denbighshire",
                            "$id": "DENBIGHSHIRE"
                        },
                        {
                            "$elt": "Dyfed",
                            "$id": "DYFED"
                        },
                        {
                            "$elt": "Flintshire",
                            "$id": "FLINTSHIRE"
                        },
                        {
                            "$elt": "Gwent",
                            "$id": "GWENT"
                        },
                        {
                            "$elt": "Gwynedd",
                            "$id": "GWYNEDD"
                        },
                        {
                            "$elt": "Isle of Anglesey",
                            "$id": "ISLE OF ANGLESEY"
                        },
                        {
                            "$elt": "Merthyr Tydfil",
                            "$id": "MERTHYR TYDFIL"
                        },
                        {
                            "$elt": "Mid Glamorgan",
                            "$id": "MID GLAMORGAN"
                        },
                        {
                            "$elt": "Monmouthshire",
                            "$id": "MONMOUTHSHIRE"
                        },
                        {
                            "$elt": "Neath Port Talbot",
                            "$id": "NEATH PORT TALBOT"
                        },
                        {
                            "$elt": "Newport",
                            "$id": "NEWPORT"
                        },
                        {
                            "$elt": "Pembrokeshire",
                            "$id": "PEMBROKESHIRE"
                        },
                        {
                            "$elt": "Powys",
                            "$id": "POWYS"
                        },
                        {
                            "$elt": "Rhondda Cynon Taf",
                            "$id": "RHONDDA CYNON TAF"
                        },
                        {
                            "$elt": "South Glamorgan",
                            "$id": "SOUTH GLAMORGAN"
                        },
                        {
                            "$elt": "Swansea",
                            "$id": "SWANSEA"
                        },
                        {
                            "$elt": "Torfaen",
                            "$id": "TORFAEN"
                        },
                        {
                            "$elt": "Vale of Glamorgan",
                            "$id": "VALE OF GLAMORGAN"
                        },
                        {
                            "$elt": "West Glamorgan",
                            "$id": "WEST GLAMORGAN"
                        },
                        {
                            "$elt": "Wrexham",
                            "$id": "WREXHAM"
                        }
                    ],
                    "label": "Wales"
                },
                {
                    "$id": "NorthernIrelandCountyList",
                    "group": [
                        {
                            "$elt": "Belfast City",
                            "$id": "BELFAST CITY"
                        },
                        {
                            "$elt": "County Antrim",
                            "$id": "COUNTY ANTRIM"
                        },
                        {
                            "$elt": "County Armagh",
                            "$id": "COUNTY ARMAGH"
                        },
                        {
                            "$elt": "County Down",
                            "$id": "COUNTY DOWN"
                        },
                        {
                            "$elt": "County Fermanagh",
                            "$id": "COUNTY FERMANAGH"
                        },
                        {
                            "$elt": "County Londonderry",
                            "$id": "COUNTY LONDONDERRY"
                        },
                        {
                            "$elt": "County Tyrone",
                            "$id": "COUNTY TYRONE"
                        }
                    ],
                    "label": "Northern Ireland"
                },
                {
                    "$id": "UKOffshoreCountyList",
                    "group": [
                        {
                            "$elt": "Guernsey",
                            "$id": "GUERNSEY"
                        },
                        {
                            "$elt": "Isle of Man",
                            "$id": "ISLE OF MAN"
                        },
                        {
                            "$elt": "Jersey",
                            "$id": "JERSEY"
                        }
                    ],
                    "label": "Crown Dependencies"
                }
            ],
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "County",
            "stateList": [
                {
                    "$elt": "Aberdeen City",
                    "$id": "ABERDEEN CITY"
                },
                {
                    "$elt": "Aberdeenshire",
                    "$id": "ABERDEENSHIRE"
                },
                {
                    "$elt": "Angus",
                    "$id": "ANGUS"
                },
                {
                    "$elt": "Argyll",
                    "$id": "ARGYLL"
                },
                {
                    "$elt": "Argyll and Bute",
                    "$id": "ARGYLL AND BUTE"
                },
                {
                    "$elt": "Avon",
                    "$id": "AVON"
                },
                {
                    "$elt": "Ayrshire",
                    "$id": "AYRSHIRE"
                },
                {
                    "$elt": "Banffshire",
                    "$id": "BANFFSHIRE"
                },
                {
                    "$elt": "Bedfordshire",
                    "$id": "BEDFORDSHIRE"
                },
                {
                    "$elt": "Belfast City",
                    "$id": "BELFAST CITY"
                },
                {
                    "$elt": "Berkshire",
                    "$id": "BERKSHIRE"
                },
                {
                    "$elt": "Berwickshire",
                    "$id": "BERWICKSHIRE"
                },
                {
                    "$elt": "Blaenau Gwent",
                    "$id": "BLAENAU GWENT"
                },
                {
                    "$elt": "Borders",
                    "$id": "BORDERS"
                },
                {
                    "$elt": "Bridgend",
                    "$id": "BRIDGEND"
                },
                {
                    "$elt": "Buckinghamshire",
                    "$id": "BUCKINGHAMSHIRE"
                },
                {
                    "$elt": "Bute",
                    "$id": "BUTE"
                },
                {
                    "$elt": "Caerphilly",
                    "$id": "CAERPHILLY"
                },
                {
                    "$elt": "Caithness",
                    "$id": "CAITHNESS"
                },
                {
                    "$elt": "Cambridgeshire",
                    "$id": "CAMBRIDGESHIRE"
                },
                {
                    "$elt": "Cardiff",
                    "$id": "CARDIFF"
                },
                {
                    "$elt": "Carmarthenshire",
                    "$id": "CARMARTHENSHIRE"
                },
                {
                    "$elt": "Central",
                    "$id": "CENTRAL"
                },
                {
                    "$elt": "Ceredigion",
                    "$id": "CEREDIGION"
                },
                {
                    "$elt": "Cheshire",
                    "$id": "CHESHIRE"
                },
                {
                    "$elt": "Clackmannanshire",
                    "$id": "CLACKMANNANSHIRE"
                },
                {
                    "$elt": "Cleveland",
                    "$id": "CLEVELAND"
                },
                {
                    "$elt": "Clwyd",
                    "$id": "CLWYD"
                },
                {
                    "$elt": "Conwy",
                    "$id": "CONWY"
                },
                {
                    "$elt": "Cornwall",
                    "$id": "CORNWALL"
                },
                {
                    "$elt": "County Antrim",
                    "$id": "COUNTY ANTRIM"
                },
                {
                    "$elt": "County Armagh",
                    "$id": "COUNTY ARMAGH"
                },
                {
                    "$elt": "County Down",
                    "$id": "COUNTY DOWN"
                },
                {
                    "$elt": "County Durham",
                    "$id": "COUNTY DURHAM"
                },
                {
                    "$elt": "County Fermanagh",
                    "$id": "COUNTY FERMANAGH"
                },
                {
                    "$elt": "County Londonderry",
                    "$id": "COUNTY LONDONDERRY"
                },
                {
                    "$elt": "County Tyrone",
                    "$id": "COUNTY TYRONE"
                },
                {
                    "$elt": "Cumbria",
                    "$id": "CUMBRIA"
                },
                {
                    "$elt": "Denbighshire",
                    "$id": "DENBIGHSHIRE"
                },
                {
                    "$elt": "Derbyshire",
                    "$id": "DERBYSHIRE"
                },
                {
                    "$elt": "Devon",
                    "$id": "DEVON"
                },
                {
                    "$elt": "Dorset",
                    "$id": "DORSET"
                },
                {
                    "$elt": "Dumfries and Galloway",
                    "$id": "DUMFRIES AND GALLOWAY"
                },
                {
                    "$elt": "Dumfriesshire",
                    "$id": "DUMFRIESSHIRE"
                },
                {
                    "$elt": "Dunbartonshire",
                    "$id": "DUNBARTONSHIRE"
                },
                {
                    "$elt": "Dundee City",
                    "$id": "DUNDEE CITY"
                },
                {
                    "$elt": "Dyfed",
                    "$id": "DYFED"
                },
                {
                    "$elt": "East Ayrshire",
                    "$id": "EAST AYRSHIRE"
                },
                {
                    "$elt": "East Dunbartonshire",
                    "$id": "EAST DUNBARTONSHIRE"
                },
                {
                    "$elt": "East Lothian",
                    "$id": "EAST LOTHIAN"
                },
                {
                    "$elt": "East Renfrewshire",
                    "$id": "EAST RENFREWSHIRE"
                },
                {
                    "$elt": "East Riding of Yorkshire",
                    "$id": "EAST RIDING OF YORKSHIRE"
                },
                {
                    "$elt": "East Sussex",
                    "$id": "EAST SUSSEX"
                },
                {
                    "$elt": "Edinburgh City",
                    "$id": "EDINBURGH CITY"
                },
                {
                    "$elt": "Essex",
                    "$id": "ESSEX"
                },
                {
                    "$elt": "Falkirk",
                    "$id": "FALKIRK"
                },
                {
                    "$elt": "Fife",
                    "$id": "FIFE"
                },
                {
                    "$elt": "Flintshire",
                    "$id": "FLINTSHIRE"
                },
                {
                    "$elt": "Glasgow City",
                    "$id": "GLASGOW CITY"
                },
                {
                    "$elt": "Gloucestershire",
                    "$id": "GLOUCESTERSHIRE"
                },
                {
                    "$elt": "Grampian",
                    "$id": "GRAMPIAN"
                },
                {
                    "$elt": "Greater Manchester",
                    "$id": "GREATER MANCHESTER"
                },
                {
                    "$elt": "Guernsey",
                    "$id": "GUERNSEY"
                },
                {
                    "$elt": "Gwent",
                    "$id": "GWENT"
                },
                {
                    "$elt": "Gwynedd",
                    "$id": "GWYNEDD"
                },
                {
                    "$elt": "Hampshire",
                    "$id": "HAMPSHIRE"
                },
                {
                    "$elt": "Herefordshire",
                    "$id": "HEREFORDSHIRE"
                },
                {
                    "$elt": "Hertfordshire",
                    "$id": "HERTFORDSHIRE"
                },
                {
                    "$elt": "Highland",
                    "$id": "HIGHLAND"
                },
                {
                    "$elt": "Inverclyde",
                    "$id": "INVERCLYDE"
                },
                {
                    "$elt": "Inverness-shire",
                    "$id": "INVERNESS-SHIRE"
                },
                {
                    "$elt": "Isle of Anglesey",
                    "$id": "ISLE OF ANGLESEY"
                },
                {
                    "$elt": "Isle of Lewis",
                    "$id": "ISLE OF LEWIS"
                },
                {
                    "$elt": "Isle of Man",
                    "$id": "ISLE OF MAN"
                },
                {
                    "$elt": "Isle of Skye",
                    "$id": "ISLE OF SKYE"
                },
                {
                    "$elt": "Isle of Wight",
                    "$id": "ISLE OF WIGHT"
                },
                {
                    "$elt": "Isles of Scilly",
                    "$id": "ISLES OF SCILLY"
                },
                {
                    "$elt": "Jersey",
                    "$id": "JERSEY"
                },
                {
                    "$elt": "Kent",
                    "$id": "KENT"
                },
                {
                    "$elt": "Kincardineshire",
                    "$id": "KINCARDINESHIRE"
                },
                {
                    "$elt": "Kinross-shire",
                    "$id": "KINROSS-SHIRE"
                },
                {
                    "$elt": "Kirkcudbrightshire",
                    "$id": "KIRKCUDBRIGHTSHIRE"
                },
                {
                    "$elt": "Lanarkshire",
                    "$id": "LANARKSHIRE"
                },
                {
                    "$elt": "Lancashire",
                    "$id": "LANCASHIRE"
                },
                {
                    "$elt": "Leicestershire",
                    "$id": "LEICESTERSHIRE"
                },
                {
                    "$elt": "Lincolnshire",
                    "$id": "LINCOLNSHIRE"
                },
                {
                    "$elt": "London",
                    "$id": "LONDON"
                },
                {
                    "$elt": "Lothian",
                    "$id": "LOTHIAN"
                },
                {
                    "$elt": "Merseyside",
                    "$id": "MERSEYSIDE"
                },
                {
                    "$elt": "Merthyr Tydfil",
                    "$id": "MERTHYR TYDFIL"
                },
                {
                    "$elt": "Mid Glamorgan",
                    "$id": "MID GLAMORGAN"
                },
                {
                    "$elt": "Middlesex",
                    "$id": "MIDDLESEX"
                },
                {
                    "$elt": "Midlothian",
                    "$id": "MIDLOTHIAN"
                },
                {
                    "$elt": "Monmouthshire",
                    "$id": "MONMOUTHSHIRE"
                },
                {
                    "$elt": "Moray",
                    "$id": "MORAY"
                },
                {
                    "$elt": "Nairn",
                    "$id": "NAIRN"
                },
                {
                    "$elt": "Neath Port Talbot",
                    "$id": "NEATH PORT TALBOT"
                },
                {
                    "$elt": "Newport",
                    "$id": "NEWPORT"
                },
                {
                    "$elt": "Norfolk",
                    "$id": "NORFOLK"
                },
                {
                    "$elt": "North Ayrshire",
                    "$id": "NORTH AYRSHIRE"
                },
                {
                    "$elt": "North East Lincolnshire",
                    "$id": "NORTH EAST LINCOLNSHIRE"
                },
                {
                    "$elt": "North Humberside",
                    "$id": "NORTH HUMBERSIDE"
                },
                {
                    "$elt": "North Lanarkshire",
                    "$id": "NORTH LANARKSHIRE"
                },
                {
                    "$elt": "North Yorkshire",
                    "$id": "NORTH YORKSHIRE"
                },
                {
                    "$elt": "Northamptonshire",
                    "$id": "NORTHAMPTONSHIRE"
                },
                {
                    "$elt": "Northumberland",
                    "$id": "NORTHUMBERLAND"
                },
                {
                    "$elt": "Nottinghamshire",
                    "$id": "NOTTINGHAMSHIRE"
                },
                {
                    "$elt": "Orkney Islands",
                    "$id": "ORKNEY ISLANDS"
                },
                {
                    "$elt": "Oxfordshire",
                    "$id": "OXFORDSHIRE"
                },
                {
                    "$elt": "Peeblesshire",
                    "$id": "PEEBLESSHIRE"
                },
                {
                    "$elt": "Pembrokeshire",
                    "$id": "PEMBROKESHIRE"
                },
                {
                    "$elt": "Perth and Kinross",
                    "$id": "PERTH AND KINROSS"
                },
                {
                    "$elt": "Perthshire",
                    "$id": "PERTHSHIRE"
                },
                {
                    "$elt": "Powys",
                    "$id": "POWYS"
                },
                {
                    "$elt": "Renfrewshire",
                    "$id": "RENFREWSHIRE"
                },
                {
                    "$elt": "Rhondda Cynon Taf",
                    "$id": "RHONDDA CYNON TAF"
                },
                {
                    "$elt": "Ross-shire",
                    "$id": "ROSS-SHIRE"
                },
                {
                    "$elt": "Roxburghshire",
                    "$id": "ROXBURGHSHIRE"
                },
                {
                    "$elt": "Rutland",
                    "$id": "RUTLAND"
                },
                {
                    "$elt": "Selkirkshire",
                    "$id": "SELKIRKSHIRE"
                },
                {
                    "$elt": "Shetland Islands",
                    "$id": "SHETLAND ISLANDS"
                },
                {
                    "$elt": "Shropshire",
                    "$id": "SHROPSHIRE"
                },
                {
                    "$elt": "Somerset",
                    "$id": "SOMERSET"
                },
                {
                    "$elt": "South Ayrshire",
                    "$id": "SOUTH AYRSHIRE"
                },
                {
                    "$elt": "South Glamorgan",
                    "$id": "SOUTH GLAMORGAN"
                },
                {
                    "$elt": "South Humberside",
                    "$id": "SOUTH HUMBERSIDE"
                },
                {
                    "$elt": "South Lanarkshire",
                    "$id": "SOUTH LANARKSHIRE"
                },
                {
                    "$elt": "South Yorkshire",
                    "$id": "SOUTH YORKSHIRE"
                },
                {
                    "$elt": "Staffordshire",
                    "$id": "STAFFORDSHIRE"
                },
                {
                    "$elt": "Stirling",
                    "$id": "STIRLING"
                },
                {
                    "$elt": "Strathclyde",
                    "$id": "STRATHCLYDE"
                },
                {
                    "$elt": "Suffolk",
                    "$id": "SUFFOLK"
                },
                {
                    "$elt": "Surrey",
                    "$id": "SURREY"
                },
                {
                    "$elt": "Sussex",
                    "$id": "SUSSEX"
                },
                {
                    "$elt": "Sutherland",
                    "$id": "SUTHERLAND"
                },
                {
                    "$elt": "Swansea",
                    "$id": "SWANSEA"
                },
                {
                    "$elt": "Tayside",
                    "$id": "TAYSIDE"
                },
                {
                    "$elt": "Torfaen",
                    "$id": "TORFAEN"
                },
                {
                    "$elt": "Tyne and Wear",
                    "$id": "TYNE AND WEAR"
                },
                {
                    "$elt": "Vale of Glamorgan",
                    "$id": "VALE OF GLAMORGAN"
                },
                {
                    "$elt": "Warwickshire",
                    "$id": "WARWICKSHIRE"
                },
                {
                    "$elt": "West Dunbartonshire",
                    "$id": "WEST DUNBARTONSHIRE"
                },
                {
                    "$elt": "West Glamorgan",
                    "$id": "WEST GLAMORGAN"
                },
                {
                    "$elt": "West Lothian",
                    "$id": "WEST LOTHIAN"
                },
                {
                    "$elt": "West Midlands",
                    "$id": "WEST MIDLANDS"
                },
                {
                    "$elt": "West Sussex",
                    "$id": "WEST SUSSEX"
                },
                {
                    "$elt": "West Yorkshire",
                    "$id": "WEST YORKSHIRE"
                },
                {
                    "$elt": "Western Isles",
                    "$id": "WESTERN ISLES"
                },
                {
                    "$elt": "Wigtownshire",
                    "$id": "WIGTOWNSHIRE"
                },
                {
                    "$elt": "Wiltshire",
                    "$id": "WILTSHIRE"
                },
                {
                    "$elt": "Worcestershire",
                    "$id": "WORCESTERSHIRE"
                },
                {
                    "$elt": "Wrexham",
                    "$id": "WREXHAM"
                }
            ],
            "validationError": "Please enter a valid UK postcode using uppercase letters – e.g. TW9 1EH or BFPO 772 (for British Armed Forces)."
        },
        "GD": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "GE": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "GF": {
            "cityLabel": "City",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 5 digits long and begin with 973 or 983."
        },
        "GI": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "GL": {
            "cityLabel": "City",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must begin with \"DK-39\" followed by 2 digits."
        },
        "GM": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "GN": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "GP": {
            "cityLabel": "City",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 5 digits long and begin with 970, 971, 980 or 981."
        },
        "GR": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 5 digits with an optional space after the first 3 digits."
        },
        "GT": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "GW": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "GY": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "HK": {
            "cityLabel": "District/Area",
            "groupCityList": [
                {
                    "$id": "HONG KONG ISLAND CENTRAL & WESTERN DISTRICT",
                    "group": [
                        {
                            "$elt": "Admiralty",
                            "$id": "ADMIRALTY"
                        },
                        {
                            "$elt": "Central",
                            "$id": "CENTRAL"
                        },
                        {
                            "$elt": "Central & Western District",
                            "$id": "CENTRAL & WESTERN DISTRICT"
                        },
                        {
                            "$elt": "Kennedy Town",
                            "$id": "KENNEDY TOWN"
                        },
                        {
                            "$elt": "Mid-Levels",
                            "$id": "MID-LEVELS"
                        },
                        {
                            "$elt": "Sai Ying Pun",
                            "$id": "SAI YING PUN"
                        },
                        {
                            "$elt": "Shek Tong Tsui",
                            "$id": "SHEK TONG TSUI"
                        },
                        {
                            "$elt": "Sheung Wan",
                            "$id": "SHEUNG WAN"
                        },
                        {
                            "$elt": "The Peak",
                            "$id": "THE PEAK"
                        }
                    ],
                    "label": "Hong Kong Island: Central & Western District"
                },
                {
                    "$id": "HONG KONG ISLAND EASTERN DISTRICT",
                    "group": [
                        {
                            "$elt": "Chai Wan",
                            "$id": "CHAI WAN"
                        },
                        {
                            "$elt": "Eastern District",
                            "$id": "EASTERN DISTRICT"
                        },
                        {
                            "$elt": "North Point",
                            "$id": "NORTH POINT"
                        },
                        {
                            "$elt": "Quarry Bay",
                            "$id": "QUARRY BAY"
                        },
                        {
                            "$elt": "Sai Wan Ho",
                            "$id": "SAI WAN HO"
                        },
                        {
                            "$elt": "Shau Kei Wan",
                            "$id": "SHAU KEI WAN"
                        },
                        {
                            "$elt": "Siu Sai Wan",
                            "$id": "SIU SAI WAN"
                        },
                        {
                            "$elt": "Taikoo Shing",
                            "$id": "TAIKOO SHING"
                        }
                    ],
                    "label": "Hong Kong Island: Eastern District"
                },
                {
                    "$id": "HONG KONG ISLAND SOUTHERN DISTRICT",
                    "group": [
                        {
                            "$elt": "Aberdeen",
                            "$id": "ABERDEEN"
                        },
                        {
                            "$elt": "Ap Lei Chau",
                            "$id": "AP LEI CHAU"
                        },
                        {
                            "$elt": "Chung Hom Kok",
                            "$id": "CHUNG HOM KOK"
                        },
                        {
                            "$elt": "Deep Water Bay",
                            "$id": "DEEP WATER BAY"
                        },
                        {
                            "$elt": "Jardines Lookout",
                            "$id": "JARDINES LOOKOUT"
                        },
                        {
                            "$elt": "Pok Fu Lam",
                            "$id": "POK FU LAM"
                        },
                        {
                            "$elt": "Repulse Bay",
                            "$id": "REPULSE BAY"
                        },
                        {
                            "$elt": "Shek O",
                            "$id": "SHEK O"
                        },
                        {
                            "$elt": "Southern District",
                            "$id": "SOUTHERN DISTRICT"
                        },
                        {
                            "$elt": "Stanley",
                            "$id": "STANLEY"
                        },
                        {
                            "$elt": "Tai Tam",
                            "$id": "TAI TAM"
                        },
                        {
                            "$elt": "Wah Fu",
                            "$id": "WAH FU"
                        },
                        {
                            "$elt": "Wong Chuk Hang",
                            "$id": "WONG CHUK HANG"
                        }
                    ],
                    "label": "Hong Kong Island: Southern District"
                },
                {
                    "$id": "HONG KONG ISLAND WAN CHAI DISTRICT",
                    "group": [
                        {
                            "$elt": "Causeway Bay",
                            "$id": "CAUSEWAY BAY"
                        },
                        {
                            "$elt": "Happy Valley",
                            "$id": "HAPPY VALLEY"
                        },
                        {
                            "$elt": "So Kon Po",
                            "$id": "SO KON PO"
                        },
                        {
                            "$elt": "Tai Hang",
                            "$id": "TAI HANG"
                        },
                        {
                            "$elt": "Wan Chai",
                            "$id": "WAN CHAI"
                        },
                        {
                            "$elt": "Wan Chai District",
                            "$id": "WAN CHAI DISTRICT"
                        }
                    ],
                    "label": "Hong Kong Island: Wan Chai District"
                },
                {
                    "$id": "KOWLOON KOWLOON CITY DISTRICT",
                    "group": [
                        {
                            "$elt": "Ho Man Tin",
                            "$id": "HO MAN TIN"
                        },
                        {
                            "$elt": "Hung Hom",
                            "$id": "HUNG HOM"
                        },
                        {
                            "$elt": "Kowloon City",
                            "$id": "KOWLOON CITY"
                        },
                        {
                            "$elt": "Kowloon City District",
                            "$id": "KOWLOON CITY DISTRICT"
                        },
                        {
                            "$elt": "Kowloon Tong",
                            "$id": "KOWLOON TONG"
                        },
                        {
                            "$elt": "Ma Tau Wai",
                            "$id": "MA TAU WAI"
                        },
                        {
                            "$elt": "To Kwa Wan",
                            "$id": "TO KWA WAN"
                        }
                    ],
                    "label": "Kowloon: Kowloon City District"
                },
                {
                    "$id": "KOWLOON KWUN TONG DISTRICT",
                    "group": [
                        {
                            "$elt": "Cha Kwo Ling",
                            "$id": "CHA KWO LING"
                        },
                        {
                            "$elt": "Kowloon Bay",
                            "$id": "KOWLOON BAY"
                        },
                        {
                            "$elt": "Kwun Tong",
                            "$id": "KWUN TONG"
                        },
                        {
                            "$elt": "Kwun Tong District",
                            "$id": "KWUN TONG DISTRICT"
                        },
                        {
                            "$elt": "Lam Tin",
                            "$id": "LAM TIN"
                        },
                        {
                            "$elt": "Lei Yue Mun",
                            "$id": "LEI YUE MUN"
                        },
                        {
                            "$elt": "Ngau Tau Kok",
                            "$id": "NGAU TAU KOK"
                        },
                        {
                            "$elt": "Sau Mau Ping",
                            "$id": "SAU MAU PING"
                        },
                        {
                            "$elt": "Yau Tong",
                            "$id": "YAU TONG"
                        }
                    ],
                    "label": "Kowloon: Kwun Tong District"
                },
                {
                    "$id": "KOWLOON SHAM SHUI PO DISTRICT",
                    "group": [
                        {
                            "$elt": "Cheung Sha Wan",
                            "$id": "CHEUNG SHA WAN"
                        },
                        {
                            "$elt": "Lai Chi Kok",
                            "$id": "LAI CHI KOK"
                        },
                        {
                            "$elt": "Mei Foo",
                            "$id": "MEI FOO"
                        },
                        {
                            "$elt": "Sham Shui Po",
                            "$id": "SHAM SHUI PO"
                        },
                        {
                            "$elt": "Sham Shui Po District",
                            "$id": "SHAM SHUI PO DISTRICT"
                        },
                        {
                            "$elt": "Shek Kip Mei",
                            "$id": "SHEK KIP MEI"
                        },
                        {
                            "$elt": "Tai Kok Tsui",
                            "$id": "TAI KOK TSUI"
                        },
                        {
                            "$elt": "Yau Yat Chuen",
                            "$id": "YAU YAT CHUEN"
                        }
                    ],
                    "label": "Kowloon: Sham Shui Po District"
                },
                {
                    "$id": "KOWLOON WONG TAI SIN DISTRICT",
                    "group": [
                        {
                            "$elt": "Choi Hung",
                            "$id": "CHOI HUNG"
                        },
                        {
                            "$elt": "Diamond Hill",
                            "$id": "DIAMOND HILL"
                        },
                        {
                            "$elt": "Lok Fu",
                            "$id": "LOK FU"
                        },
                        {
                            "$elt": "Ngau Chi Wan",
                            "$id": "NGAU CHI WAN"
                        },
                        {
                            "$elt": "San Po Kong",
                            "$id": "SAN PO KONG"
                        },
                        {
                            "$elt": "Tsz Wan Shan",
                            "$id": "TSZ WAN SHAN"
                        },
                        {
                            "$elt": "Wang Tau Hom",
                            "$id": "WANG TAU HOM"
                        },
                        {
                            "$elt": "Wong Tai Sin",
                            "$id": "WONG TAI SIN"
                        },
                        {
                            "$elt": "Wong Tai Sin District",
                            "$id": "WONG TAI SIN DISTRICT"
                        }
                    ],
                    "label": "Kowloon: Wong Tai Sin District"
                },
                {
                    "$id": "KOWLOON YAU TSIM MONG DISTRICT",
                    "group": [
                        {
                            "$elt": "Jordan",
                            "$id": "JORDAN"
                        },
                        {
                            "$elt": "Mong Kok",
                            "$id": "MONG KOK"
                        },
                        {
                            "$elt": "Prince Edward",
                            "$id": "PRINCE EDWARD"
                        },
                        {
                            "$elt": "Tsim Sha Tsui",
                            "$id": "TSIM SHA TSUI"
                        },
                        {
                            "$elt": "Tsim Sha Tsui East",
                            "$id": "TSIM SHA TSUI EAST"
                        },
                        {
                            "$elt": "West Kowloon",
                            "$id": "WEST KOWLOON"
                        },
                        {
                            "$elt": "Yau Ma Tei",
                            "$id": "YAU MA TEI"
                        },
                        {
                            "$elt": "Yau Tsim Mong District",
                            "$id": "YAU TSIM MONG DISTRICT"
                        }
                    ],
                    "label": "Kowloon: Yau Tsim Mong District"
                },
                {
                    "$id": "NEW TERRITORIES ISLANDS DISTRICT",
                    "group": [
                        {
                            "$elt": "Chek Lap Kok Lantau Island",
                            "$id": "CHEK LAP KOK LANTAU ISLAND"
                        },
                        {
                            "$elt": "Cheung Chau",
                            "$id": "CHEUNG CHAU"
                        },
                        {
                            "$elt": "Cheung Sha Lantau Island",
                            "$id": "CHEUNG SHA LANTAU ISLAND"
                        },
                        {
                            "$elt": "Chi Ma Wan Lantau Island",
                            "$id": "CHI MA WAN LANTAU ISLAND"
                        },
                        {
                            "$elt": "Discovery Bay Lantau Island",
                            "$id": "DISCOVERY BAY LANTAU ISLAND"
                        },
                        {
                            "$elt": "Hei Ling Chau",
                            "$id": "HEI LING CHAU"
                        },
                        {
                            "$elt": "Islands District",
                            "$id": "ISLANDS DISTRICT"
                        },
                        {
                            "$elt": "Lamma Island",
                            "$id": "LAMMA ISLAND"
                        },
                        {
                            "$elt": "Lantau Island",
                            "$id": "LANTAU ISLAND"
                        },
                        {
                            "$elt": "Mui Wo Lantau Island",
                            "$id": "MUI WO LANTAU ISLAND"
                        },
                        {
                            "$elt": "Ngong Ping Lantau Island",
                            "$id": "NGONG PING LANTAU ISLAND"
                        },
                        {
                            "$elt": "Peng Chau",
                            "$id": "PENG CHAU"
                        },
                        {
                            "$elt": "Po Toi Island",
                            "$id": "PO TOI ISLAND"
                        },
                        {
                            "$elt": "Shek Pik Lantau Island",
                            "$id": "SHEK PIK LANTAU ISLAND"
                        },
                        {
                            "$elt": "Soko Islands",
                            "$id": "SOKO ISLANDS"
                        },
                        {
                            "$elt": "Tai O Lantau Island",
                            "$id": "TAI O LANTAU ISLAND"
                        },
                        {
                            "$elt": "Tong Fuk Lantau Island",
                            "$id": "TONG FUK LANTAU ISLAND"
                        },
                        {
                            "$elt": "Tung Chung Lantau Island",
                            "$id": "TUNG CHUNG LANTAU ISLAND"
                        }
                    ],
                    "label": "New Territories: Islands District"
                },
                {
                    "$id": "NEW TERRITORIES KWAI TSING DISTRICT",
                    "group": [
                        {
                            "$elt": "Kwai Chung",
                            "$id": "KWAI CHUNG"
                        },
                        {
                            "$elt": "Kwai Fong",
                            "$id": "KWAI FONG"
                        },
                        {
                            "$elt": "Kwai Tsing District",
                            "$id": "KWAI TSING DISTRICT"
                        },
                        {
                            "$elt": "Tsing Yi",
                            "$id": "TSING YI"
                        }
                    ],
                    "label": "New Territories: Kwai Tsing District"
                },
                {
                    "$id": "NEW TERRITORIES NORTH DISTRICT",
                    "group": [
                        {
                            "$elt": "Fanling",
                            "$id": "FANLING"
                        },
                        {
                            "$elt": "Kat O Chau",
                            "$id": "KAT O CHAU"
                        },
                        {
                            "$elt": "Kwan Tei Fanling",
                            "$id": "KWAN TEI FANLING"
                        },
                        {
                            "$elt": "Kwu Tung",
                            "$id": "KWU TUNG"
                        },
                        {
                            "$elt": "Lo Wu",
                            "$id": "LO WU"
                        },
                        {
                            "$elt": "North District",
                            "$id": "NORTH DISTRICT"
                        },
                        {
                            "$elt": "Pat Sin Leng",
                            "$id": "PAT SIN LENG"
                        },
                        {
                            "$elt": "Ping Che",
                            "$id": "PING CHE"
                        },
                        {
                            "$elt": "Sha Tau Kok",
                            "$id": "SHA TAU KOK"
                        },
                        {
                            "$elt": "Sheung Shui",
                            "$id": "SHEUNG SHUI"
                        },
                        {
                            "$elt": "Ta Kwu Ling",
                            "$id": "TA KWU LING"
                        }
                    ],
                    "label": "New Territories: North District"
                },
                {
                    "$id": "NEW TERRITORIES SAI KUNG DISTRICT",
                    "group": [
                        {
                            "$elt": "Chek Keng",
                            "$id": "CHEK KENG"
                        },
                        {
                            "$elt": "Clear Water Bay",
                            "$id": "CLEAR WATER BAY"
                        },
                        {
                            "$elt": "Hang Hau Sai Kung",
                            "$id": "HANG HAU SAI KUNG"
                        },
                        {
                            "$elt": "Ninepin Group",
                            "$id": "NINEPIN GROUP"
                        },
                        {
                            "$elt": "Pak Sha Wan",
                            "$id": "PAK SHA WAN"
                        },
                        {
                            "$elt": "Sai Kung",
                            "$id": "SAI KUNG"
                        },
                        {
                            "$elt": "Sai Kung District",
                            "$id": "SAI KUNG DISTRICT"
                        },
                        {
                            "$elt": "Tap Mun Chau",
                            "$id": "TAP MUN CHAU"
                        },
                        {
                            "$elt": "Tseung Kwan O",
                            "$id": "TSEUNG KWAN O"
                        }
                    ],
                    "label": "New Territories: Sai Kung District"
                },
                {
                    "$id": "NEW TERRITORIES SHA TIN DISTRICT",
                    "group": [
                        {
                            "$elt": "Chinese University",
                            "$id": "CHINESE UNIVERSITY"
                        },
                        {
                            "$elt": "Fo Tan Sha Tin",
                            "$id": "FO TAN SHA TIN"
                        },
                        {
                            "$elt": "Kau To Shan",
                            "$id": "KAU TO SHAN"
                        },
                        {
                            "$elt": "Ma Liu Shui",
                            "$id": "MA LIU SHUI"
                        },
                        {
                            "$elt": "Ma On Shan",
                            "$id": "MA ON SHAN"
                        },
                        {
                            "$elt": "Nai Chung",
                            "$id": "NAI CHUNG"
                        },
                        {
                            "$elt": "Sha Tin",
                            "$id": "SHA TIN"
                        },
                        {
                            "$elt": "Sha Tin District",
                            "$id": "SHA TIN DISTRICT"
                        },
                        {
                            "$elt": "Siu Lek Yuen Sha Tin",
                            "$id": "SIU LEK YUEN SHA TIN"
                        },
                        {
                            "$elt": "Tai Wai Sha Tin",
                            "$id": "TAI WAI SHA TIN"
                        }
                    ],
                    "label": "New Territories: Sha Tin District"
                },
                {
                    "$id": "NEW TERRITORIES TUEN MUN DISTRICT",
                    "group": [
                        {
                            "$elt": "Castle Peak",
                            "$id": "CASTLE PEAK"
                        },
                        {
                            "$elt": "Castle Peak Bay",
                            "$id": "CASTLE PEAK BAY"
                        },
                        {
                            "$elt": "Fu Tei Tuen Mun",
                            "$id": "FU TEI TUEN MUN"
                        },
                        {
                            "$elt": "Lam Tei Tuen Mun",
                            "$id": "LAM TEI TUEN MUN"
                        },
                        {
                            "$elt": "Lung Kwu Chau",
                            "$id": "LUNG KWU CHAU"
                        },
                        {
                            "$elt": "San Hui Tuen Mun",
                            "$id": "SAN HUI TUEN MUN"
                        },
                        {
                            "$elt": "Siu Lam Tuen Mun",
                            "$id": "SIU LAM TUEN MUN"
                        },
                        {
                            "$elt": "So Kwun Wat",
                            "$id": "SO KWUN WAT"
                        },
                        {
                            "$elt": "Tai Lam",
                            "$id": "TAI LAM"
                        },
                        {
                            "$elt": "Tuen Mun",
                            "$id": "TUEN MUN"
                        },
                        {
                            "$elt": "Tuen Mun District",
                            "$id": "TUEN MUN DISTRICT"
                        }
                    ],
                    "label": "New Territories: Tuen Mun District"
                },
                {
                    "$id": "NEW TERRITORIES TAI PO DISTRICT",
                    "group": [
                        {
                            "$elt": "Lam Tsuen Tai Po",
                            "$id": "LAM TSUEN TAI PO"
                        },
                        {
                            "$elt": "Shuen Wan Tai Po",
                            "$id": "SHUEN WAN TAI PO"
                        },
                        {
                            "$elt": "Tai Po",
                            "$id": "TAI PO"
                        },
                        {
                            "$elt": "Tai Po District",
                            "$id": "TAI PO DISTRICT"
                        },
                        {
                            "$elt": "Tai Po Kau",
                            "$id": "TAI PO KAU"
                        },
                        {
                            "$elt": "Tai Wo",
                            "$id": "TAI WO"
                        },
                        {
                            "$elt": "Ting Kok Tai Po",
                            "$id": "TING KOK TAI PO"
                        }
                    ],
                    "label": "New Territories: Tai Po District"
                },
                {
                    "$id": "NEW TERRITORIES TSUEN WAN DISTRICT",
                    "group": [
                        {
                            "$elt": "Lo Wai Tsuen Wan",
                            "$id": "LO WAI TSUEN WAN"
                        },
                        {
                            "$elt": "Ma Wan",
                            "$id": "MA WAN"
                        },
                        {
                            "$elt": "Sham Tseng",
                            "$id": "SHAM TSENG"
                        },
                        {
                            "$elt": "Tai Mo Shan",
                            "$id": "TAI MO SHAN"
                        },
                        {
                            "$elt": "Ting Kau",
                            "$id": "TING KAU"
                        },
                        {
                            "$elt": "Tsuen Wan",
                            "$id": "TSUEN WAN"
                        },
                        {
                            "$elt": "Tsuen Wan District",
                            "$id": "TSUEN WAN DISTRICT"
                        }
                    ],
                    "label": "New Territories: Tsuen Wan District"
                },
                {
                    "$id": "NEW TERRITORIES YUEN LONG DISTRICT",
                    "group": [
                        {
                            "$elt": "Ha Tsuen Yuen Long",
                            "$id": "HA TSUEN YUEN LONG"
                        },
                        {
                            "$elt": "Hung Shui Kiu",
                            "$id": "HUNG SHUI KIU"
                        },
                        {
                            "$elt": "Kam Tin Yuen Long",
                            "$id": "KAM TIN YUEN LONG"
                        },
                        {
                            "$elt": "Lau Fau Shan",
                            "$id": "LAU FAU SHAN"
                        },
                        {
                            "$elt": "Lok Ma Chau",
                            "$id": "LOK MA CHAU"
                        },
                        {
                            "$elt": "Pat Heung Yuen Long",
                            "$id": "PAT HEUNG YUEN LONG"
                        },
                        {
                            "$elt": "Ping Shan Yuen Long",
                            "$id": "PING SHAN YUEN LONG"
                        },
                        {
                            "$elt": "San Tin Yuen Long",
                            "$id": "SAN TIN YUEN LONG"
                        },
                        {
                            "$elt": "Shek Kong Yuen Long",
                            "$id": "SHEK KONG YUEN LONG"
                        },
                        {
                            "$elt": "Tai Tong Yuen Long",
                            "$id": "TAI TONG YUEN LONG"
                        },
                        {
                            "$elt": "Tam Mei Yuen Long",
                            "$id": "TAM MEI YUEN LONG"
                        },
                        {
                            "$elt": "Tin Shui Wai",
                            "$id": "TIN SHUI WAI"
                        },
                        {
                            "$elt": "Yuen Long",
                            "$id": "YUEN LONG"
                        },
                        {
                            "$elt": "Yuen Long District",
                            "$id": "YUEN LONG DISTRICT"
                        }
                    ],
                    "label": "New Territories: Yuen Long District"
                }
            ],
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "stateLabel": "Region",
            "stateList": [
                {
                    "$elt": "Hong Kong Island",
                    "$id": "HONG KONG ISLAND"
                },
                {
                    "$elt": "Kowloon",
                    "$id": "KOWLOON"
                },
                {
                    "$elt": "New Territories/Outlying Islands",
                    "$id": "NEW TERRITORIES"
                }
            ]
        },
        "HN": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "HR": {
            "cityLabel": "City",
            "line1Label": "Street name and house number",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 5 digits."
        },
        "HU": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 4 digits."
        },
        "ID": {
            "cityLabel": "City",
            "groupStateList": [
                {
                    "$id": "ID-NU",
                    "group": [
                        {
                            "$elt": "Bali",
                            "$id": "BALI"
                        },
                        {
                            "$elt": "East Nusa Tenggara",
                            "$id": "EAST NUSA TENGGARA"
                        },
                        {
                            "$elt": "West Nusa Tenggara",
                            "$id": "WEST NUSA TENGGARA"
                        }
                    ],
                    "label": "Bali and Nusa Tenggara"
                },
                {
                    "$id": "ID-JW",
                    "group": [
                        {
                            "$elt": "Banten",
                            "$id": "BANTEN"
                        },
                        {
                            "$elt": "Central Java",
                            "$id": "CENTRAL JAVA"
                        },
                        {
                            "$elt": "East Java",
                            "$id": "EAST JAVA"
                        },
                        {
                            "$elt": "Jakarta",
                            "$id": "DKI JAKARTA"
                        },
                        {
                            "$elt": "West Java",
                            "$id": "WEST JAVA"
                        },
                        {
                            "$elt": "Yogyakarta",
                            "$id": "YOGYAKARTA"
                        }
                    ],
                    "label": "Java"
                },
                {
                    "$id": "ID-KA",
                    "group": [
                        {
                            "$elt": "Central Kalimantan",
                            "$id": "CENTRAL KALIMANTAN"
                        },
                        {
                            "$elt": "East Kalimatan",
                            "$id": "EAST KALIMANTAN"
                        },
                        {
                            "$elt": "North Kalimantan",
                            "$id": "NORTH KALIMANTAN"
                        },
                        {
                            "$elt": "South Kalimantan",
                            "$id": "SOUTH KALIMANTAN"
                        },
                        {
                            "$elt": "West Kalimantan",
                            "$id": "WEST KALIMANTAN"
                        }
                    ],
                    "label": "Kalimantan"
                },
                {
                    "$id": "ID-ML",
                    "group": [
                        {
                            "$elt": "Maluku",
                            "$id": "MALUKU"
                        },
                        {
                            "$elt": "North Maluku",
                            "$id": "NORTH MALUKU"
                        }
                    ],
                    "label": "Maluku"
                },
                {
                    "$id": "ID-IJ",
                    "group": [
                        {
                            "$elt": "Papua",
                            "$id": "PAPUA"
                        },
                        {
                            "$elt": "West Papua",
                            "$id": "WEST PAPUA"
                        }
                    ],
                    "label": "Papua"
                },
                {
                    "$id": "ID-SL",
                    "group": [
                        {
                            "$elt": "Central Sulawesi",
                            "$id": "CENTRAL SULAWESI"
                        },
                        {
                            "$elt": "Gorontalo",
                            "$id": "GORONTALO"
                        },
                        {
                            "$elt": "North Sulawesi",
                            "$id": "NORTH SULAWESI"
                        },
                        {
                            "$elt": "South Sulawesi",
                            "$id": "SOUTH SULAWESI"
                        },
                        {
                            "$elt": "Southeast Sulawesi",
                            "$id": "SOUTHEAST SULAWESI"
                        },
                        {
                            "$elt": "West Sulawesi",
                            "$id": "WEST SULAWESI"
                        }
                    ],
                    "label": "Sulawesi"
                },
                {
                    "$id": "ID-SM",
                    "group": [
                        {
                            "$elt": "Aceh",
                            "$id": "ACEH"
                        },
                        {
                            "$elt": "Bangka Belitung Islands",
                            "$id": "BANGKA BELITUNG ISLANDS"
                        },
                        {
                            "$elt": "Bengkulu",
                            "$id": "BENGKULU"
                        },
                        {
                            "$elt": "Jambi",
                            "$id": "JAMBI"
                        },
                        {
                            "$elt": "Lampung",
                            "$id": "LAMPUNG"
                        },
                        {
                            "$elt": "North Sumatra",
                            "$id": "NORTH SUMATRA"
                        },
                        {
                            "$elt": "Riau",
                            "$id": "RIAU"
                        },
                        {
                            "$elt": "Riau Islands",
                            "$id": "RIAU ISLANDS"
                        },
                        {
                            "$elt": "South Sumatra",
                            "$id": "SOUTH SUMATRA"
                        },
                        {
                            "$elt": "West Sumatra",
                            "$id": "WEST SUMATRA"
                        }
                    ],
                    "label": "Sumatra"
                }
            ],
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province",
            "stateList": [
                {
                    "$elt": "Aceh",
                    "$id": "ACEH"
                },
                {
                    "$elt": "Bali",
                    "$id": "BALI"
                },
                {
                    "$elt": "Bangka Belitung Islands",
                    "$id": "BANGKA BELITUNG ISLANDS"
                },
                {
                    "$elt": "Banten",
                    "$id": "BANTEN"
                },
                {
                    "$elt": "Bengkulu",
                    "$id": "BENGKULU"
                },
                {
                    "$elt": "Central Java",
                    "$id": "CENTRAL JAVA"
                },
                {
                    "$elt": "Central Kalimantan",
                    "$id": "CENTRAL KALIMANTAN"
                },
                {
                    "$elt": "Central Sulawesi",
                    "$id": "CENTRAL SULAWESI"
                },
                {
                    "$elt": "East Java",
                    "$id": "EAST JAVA"
                },
                {
                    "$elt": "East Kalimatan",
                    "$id": "EAST KALIMANTAN"
                },
                {
                    "$elt": "East Nusa Tenggara",
                    "$id": "EAST NUSA TENGGARA"
                },
                {
                    "$elt": "Gorontalo",
                    "$id": "GORONTALO"
                },
                {
                    "$elt": "Jakarta",
                    "$id": "DKI JAKARTA"
                },
                {
                    "$elt": "Jambi",
                    "$id": "JAMBI"
                },
                {
                    "$elt": "Lampung",
                    "$id": "LAMPUNG"
                },
                {
                    "$elt": "Maluku",
                    "$id": "MALUKU"
                },
                {
                    "$elt": "North Kalimantan",
                    "$id": "NORTH KALIMANTAN"
                },
                {
                    "$elt": "North Maluku",
                    "$id": "NORTH MALUKU"
                },
                {
                    "$elt": "North Sulawesi",
                    "$id": "NORTH SULAWESI"
                },
                {
                    "$elt": "North Sumatra",
                    "$id": "NORTH SUMATRA"
                },
                {
                    "$elt": "Papua",
                    "$id": "PAPUA"
                },
                {
                    "$elt": "Riau",
                    "$id": "RIAU"
                },
                {
                    "$elt": "Riau Islands",
                    "$id": "RIAU ISLANDS"
                },
                {
                    "$elt": "South Kalimantan",
                    "$id": "SOUTH KALIMANTAN"
                },
                {
                    "$elt": "South Sulawesi",
                    "$id": "SOUTH SULAWESI"
                },
                {
                    "$elt": "South Sumatra",
                    "$id": "SOUTH SUMATRA"
                },
                {
                    "$elt": "Southeast Sulawesi",
                    "$id": "SOUTHEAST SULAWESI"
                },
                {
                    "$elt": "West Java",
                    "$id": "WEST JAVA"
                },
                {
                    "$elt": "West Kalimantan",
                    "$id": "WEST KALIMANTAN"
                },
                {
                    "$elt": "West Nusa Tenggara",
                    "$id": "WEST NUSA TENGGARA"
                },
                {
                    "$elt": "West Papua",
                    "$id": "WEST PAPUA"
                },
                {
                    "$elt": "West Sulawesi",
                    "$id": "WEST SULAWESI"
                },
                {
                    "$elt": "West Sumatra",
                    "$id": "WEST SUMATRA"
                },
                {
                    "$elt": "Yogyakarta",
                    "$id": "YOGYAKARTA"
                }
            ],
            "validationError": "Your postcode must contain 5 digits."
        },
        "IE": {
            "cityLabel": "Town/City",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "County/Dublin postal district",
            "stateList": [
                {
                    "$elt": "Co. Carlow",
                    "$id": "CO CARLOW"
                },
                {
                    "$elt": "Co. Cavan",
                    "$id": "CO CAVAN"
                },
                {
                    "$elt": "Co. Clare",
                    "$id": "CO CLARE"
                },
                {
                    "$elt": "Co. Cork",
                    "$id": "CO CORK"
                },
                {
                    "$elt": "Co. Donegal",
                    "$id": "CO DONEGAL"
                },
                {
                    "$elt": "Co. Dublin",
                    "$id": "CO DUBLIN"
                },
                {
                    "$elt": "Co. Galway",
                    "$id": "CO GALWAY"
                },
                {
                    "$elt": "Co. Kerry",
                    "$id": "CO KERRY"
                },
                {
                    "$elt": "Co. Kildare",
                    "$id": "CO KILDARE"
                },
                {
                    "$elt": "Co. Kilkenny",
                    "$id": "CO KILKENNY"
                },
                {
                    "$elt": "Co. Laois",
                    "$id": "CO LAOIS"
                },
                {
                    "$elt": "Co. Leitrim",
                    "$id": "CO LEITRIM"
                },
                {
                    "$elt": "Co. Limerick",
                    "$id": "CO LIMERICK"
                },
                {
                    "$elt": "Co. Longford",
                    "$id": "CO LONGFORD"
                },
                {
                    "$elt": "Co. Louth",
                    "$id": "CO LOUTH"
                },
                {
                    "$elt": "Co. Mayo",
                    "$id": "CO MAYO"
                },
                {
                    "$elt": "Co. Meath",
                    "$id": "CO MEATH"
                },
                {
                    "$elt": "Co. Monaghan",
                    "$id": "CO MONAGHAN"
                },
                {
                    "$elt": "Co. Offaly",
                    "$id": "CO OFFALY"
                },
                {
                    "$elt": "Co. Roscommon",
                    "$id": "CO ROSCOMMON"
                },
                {
                    "$elt": "Co. Sligo",
                    "$id": "CO SLIGO"
                },
                {
                    "$elt": "Co. Tipperary",
                    "$id": "CO TIPPERARY"
                },
                {
                    "$elt": "Co. Waterford",
                    "$id": "CO WATERFORD"
                },
                {
                    "$elt": "Co. Westmeath",
                    "$id": "CO WESTMEATH"
                },
                {
                    "$elt": "Co. Wexford",
                    "$id": "CO WEXFORD"
                },
                {
                    "$elt": "Co. Wicklow",
                    "$id": "CO WICKLOW"
                },
                {
                    "$elt": "Dublin 01",
                    "$id": "DUBLIN 1"
                },
                {
                    "$elt": "Dublin 02",
                    "$id": "DUBLIN 2"
                },
                {
                    "$elt": "Dublin 03",
                    "$id": "DUBLIN 3"
                },
                {
                    "$elt": "Dublin 04",
                    "$id": "DUBLIN 4"
                },
                {
                    "$elt": "Dublin 05",
                    "$id": "DUBLIN 5"
                },
                {
                    "$elt": "Dublin 06",
                    "$id": "DUBLIN 6"
                },
                {
                    "$elt": "Dublin 06W",
                    "$id": "DUBLIN 6W"
                },
                {
                    "$elt": "Dublin 07",
                    "$id": "DUBLIN 7"
                },
                {
                    "$elt": "Dublin 08",
                    "$id": "DUBLIN 8"
                },
                {
                    "$elt": "Dublin 09",
                    "$id": "DUBLIN 9"
                },
                {
                    "$elt": "Dublin 10",
                    "$id": "DUBLIN 10"
                },
                {
                    "$elt": "Dublin 11",
                    "$id": "DUBLIN 11"
                },
                {
                    "$elt": "Dublin 12",
                    "$id": "DUBLIN 12"
                },
                {
                    "$elt": "Dublin 13",
                    "$id": "DUBLIN 13"
                },
                {
                    "$elt": "Dublin 14",
                    "$id": "DUBLIN 14"
                },
                {
                    "$elt": "Dublin 15",
                    "$id": "DUBLIN 15"
                },
                {
                    "$elt": "Dublin 16",
                    "$id": "DUBLIN 16"
                },
                {
                    "$elt": "Dublin 17",
                    "$id": "DUBLIN 17"
                },
                {
                    "$elt": "Dublin 18",
                    "$id": "DUBLIN 18"
                },
                {
                    "$elt": "Dublin 20",
                    "$id": "DUBLIN 20"
                },
                {
                    "$elt": "Dublin 22",
                    "$id": "DUBLIN 22"
                },
                {
                    "$elt": "Dublin 24",
                    "$id": "DUBLIN 24"
                }
            ],
            "validationError": "Please enter a valid local postcode or EIRCODE."
        },
        "IL": {
            "cityLabel": "City",
            "line1Label": "Street and house number",
            "line2Label": "More address details",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 7 digits long."
        },
        "IN": {
            "cityLabel": "Town/City",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "PIN code",
            "selectLabel": "Select",
            "stateLabel": "State",
            "stateList": [
                {
                    "$elt": "Andaman and Nicobar Islands",
                    "$id": "Andaman and Nicobar Islands"
                },
                {
                    "$elt": "Andhra Pradesh",
                    "$id": "Andhra Pradesh"
                },
                {
                    "$elt": "Arunachal Pradesh",
                    "$id": "Arunachal Pradesh"
                },
                {
                    "$elt": "Assam",
                    "$id": "Assam"
                },
                {
                    "$elt": "Bihar",
                    "$id": "Bihar"
                },
                {
                    "$elt": "Chandigarh",
                    "$id": "Chandigarh"
                },
                {
                    "$elt": "Chhattisgarh",
                    "$id": "Chhattisgarh"
                },
                {
                    "$elt": "Dadra and Nagar Haveli",
                    "$id": "Dadra and Nagar Haveli"
                },
                {
                    "$elt": "Daman and Diu",
                    "$id": "Daman and Diu"
                },
                {
                    "$elt": "Delhi",
                    "$id": "Delhi (NCT)"
                },
                {
                    "$elt": "Goa",
                    "$id": "Goa"
                },
                {
                    "$elt": "Gujarat",
                    "$id": "Gujarat"
                },
                {
                    "$elt": "Haryana",
                    "$id": "Haryana"
                },
                {
                    "$elt": "Himachal Pradesh",
                    "$id": "Himachal Pradesh"
                },
                {
                    "$elt": "Jammu and Kashmir",
                    "$id": "Jammu and Kashmir"
                },
                {
                    "$elt": "Jharkhand",
                    "$id": "Jharkhand"
                },
                {
                    "$elt": "Karnataka",
                    "$id": "Karnataka"
                },
                {
                    "$elt": "Kerala",
                    "$id": "Kerala"
                },
                {
                    "$elt": "Lakshadweep",
                    "$id": "Lakshadweep"
                },
                {
                    "$elt": "Madhya Pradesh",
                    "$id": "Madhya Pradesh"
                },
                {
                    "$elt": "Maharashtra",
                    "$id": "Maharashtra"
                },
                {
                    "$elt": "Manipur",
                    "$id": "Manipur"
                },
                {
                    "$elt": "Meghalaya",
                    "$id": "Meghalaya"
                },
                {
                    "$elt": "Mizoram",
                    "$id": "Mizoram"
                },
                {
                    "$elt": "Nagaland",
                    "$id": "Nagaland"
                },
                {
                    "$elt": "Odisha",
                    "$id": "Odisha"
                },
                {
                    "$elt": "Puducherry",
                    "$id": "Puducherry"
                },
                {
                    "$elt": "Punjab",
                    "$id": "Punjab"
                },
                {
                    "$elt": "Rajasthan",
                    "$id": "Rajasthan"
                },
                {
                    "$elt": "Sikkim",
                    "$id": "Sikkim"
                },
                {
                    "$elt": "Tamil Nadu",
                    "$id": "Tamil Nadu"
                },
                {
                    "$elt": "Telangana",
                    "$id": "Telangana"
                },
                {
                    "$elt": "Tripura",
                    "$id": "Tripura"
                },
                {
                    "$elt": "Uttar Pradesh",
                    "$id": "Uttar Pradesh"
                },
                {
                    "$elt": "Uttarakhand",
                    "$id": "Uttarakhand"
                },
                {
                    "$elt": "West Bengal",
                    "$id": "West Bengal"
                },
                {
                    "$elt": "Army Post Office",
                    "$id": "APO"
                }
            ],
            "validationError": "Your PIN code must contain 6 digits."
        },
        "IS": {
            "cityLabel": "Town/City",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 3 digits long."
        },
        "IT": {
            "cityLabel": "City",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "selectLabel": "Select",
            "stateLabel": "Province",
            "stateList": [
                {
                    "$elt": "Agrigento",
                    "$id": "AG"
                },
                {
                    "$elt": "Alessandria",
                    "$id": "AL"
                },
                {
                    "$elt": "Ancona",
                    "$id": "AN"
                },
                {
                    "$elt": "Aosta",
                    "$id": "AO"
                },
                {
                    "$elt": "Arezzo",
                    "$id": "AR"
                },
                {
                    "$elt": "Ascoli Piceno",
                    "$id": "AP"
                },
                {
                    "$elt": "Asti",
                    "$id": "AT"
                },
                {
                    "$elt": "Avellino",
                    "$id": "AV"
                },
                {
                    "$elt": "Bari",
                    "$id": "BA"
                },
                {
                    "$elt": "Barletta-Andria-Trani",
                    "$id": "BT"
                },
                {
                    "$elt": "Belluno",
                    "$id": "BL"
                },
                {
                    "$elt": "Benevento",
                    "$id": "BN"
                },
                {
                    "$elt": "Bergamo",
                    "$id": "BG"
                },
                {
                    "$elt": "Biella",
                    "$id": "BI"
                },
                {
                    "$elt": "Bologna",
                    "$id": "BO"
                },
                {
                    "$elt": "Bolzano",
                    "$id": "BZ"
                },
                {
                    "$elt": "Brescia",
                    "$id": "BS"
                },
                {
                    "$elt": "Brindisi",
                    "$id": "BR"
                },
                {
                    "$elt": "Cagliari",
                    "$id": "CA"
                },
                {
                    "$elt": "Caltanissetta",
                    "$id": "CL"
                },
                {
                    "$elt": "Campobasso",
                    "$id": "CB"
                },
                {
                    "$elt": "Carbonia-Iglesias",
                    "$id": "CI"
                },
                {
                    "$elt": "Caserta",
                    "$id": "CE"
                },
                {
                    "$elt": "Catania",
                    "$id": "CT"
                },
                {
                    "$elt": "Catanzaro",
                    "$id": "CZ"
                },
                {
                    "$elt": "Chieti",
                    "$id": "CH"
                },
                {
                    "$elt": "Como",
                    "$id": "CO"
                },
                {
                    "$elt": "Cosenza",
                    "$id": "CS"
                },
                {
                    "$elt": "Cremona",
                    "$id": "CR"
                },
                {
                    "$elt": "Crotone",
                    "$id": "KR"
                },
                {
                    "$elt": "Cuneo",
                    "$id": "CN"
                },
                {
                    "$elt": "Enna",
                    "$id": "EN"
                },
                {
                    "$elt": "Fermo",
                    "$id": "FM"
                },
                {
                    "$elt": "Ferrara",
                    "$id": "FE"
                },
                {
                    "$elt": "Firenze",
                    "$id": "FI"
                },
                {
                    "$elt": "Foggia",
                    "$id": "FG"
                },
                {
                    "$elt": "Forlì-Cesena",
                    "$id": "FC"
                },
                {
                    "$elt": "Frosinone",
                    "$id": "FR"
                },
                {
                    "$elt": "Genova",
                    "$id": "GE"
                },
                {
                    "$elt": "Gorizia",
                    "$id": "GO"
                },
                {
                    "$elt": "Grosseto",
                    "$id": "GR"
                },
                {
                    "$elt": "Imperia",
                    "$id": "IM"
                },
                {
                    "$elt": "Isernia",
                    "$id": "IS"
                },
                {
                    "$elt": "La Spezia",
                    "$id": "SP"
                },
                {
                    "$elt": "L'Aquila",
                    "$id": "AQ"
                },
                {
                    "$elt": "Latina",
                    "$id": "LT"
                },
                {
                    "$elt": "Lecce",
                    "$id": "LE"
                },
                {
                    "$elt": "Lecco",
                    "$id": "LC"
                },
                {
                    "$elt": "Livorno",
                    "$id": "LI"
                },
                {
                    "$elt": "Lodi",
                    "$id": "LO"
                },
                {
                    "$elt": "Lucca",
                    "$id": "LU"
                },
                {
                    "$elt": "Macerata",
                    "$id": "MC"
                },
                {
                    "$elt": "Mantova",
                    "$id": "MN"
                },
                {
                    "$elt": "Massa-Carrara",
                    "$id": "MS"
                },
                {
                    "$elt": "Matera",
                    "$id": "MT"
                },
                {
                    "$elt": "Medio Campidano",
                    "$id": "VS"
                },
                {
                    "$elt": "Messina",
                    "$id": "ME"
                },
                {
                    "$elt": "Milano",
                    "$id": "MI"
                },
                {
                    "$elt": "Modena",
                    "$id": "MO"
                },
                {
                    "$elt": "Monza e della Brianza",
                    "$id": "MB"
                },
                {
                    "$elt": "Napoli",
                    "$id": "NA"
                },
                {
                    "$elt": "Novara",
                    "$id": "NO"
                },
                {
                    "$elt": "Nuoro",
                    "$id": "NU"
                },
                {
                    "$elt": "Ogliastra",
                    "$id": "OG"
                },
                {
                    "$elt": "Olbia-Tempio",
                    "$id": "OT"
                },
                {
                    "$elt": "Oristano",
                    "$id": "OR"
                },
                {
                    "$elt": "Padova",
                    "$id": "PD"
                },
                {
                    "$elt": "Palermo",
                    "$id": "PA"
                },
                {
                    "$elt": "Parma",
                    "$id": "PR"
                },
                {
                    "$elt": "Pavia",
                    "$id": "PV"
                },
                {
                    "$elt": "Perugia",
                    "$id": "PG"
                },
                {
                    "$elt": "Pesaro e Urbino",
                    "$id": "PU"
                },
                {
                    "$elt": "Pescara",
                    "$id": "PE"
                },
                {
                    "$elt": "Piacenza",
                    "$id": "PC"
                },
                {
                    "$elt": "Pisa",
                    "$id": "PI"
                },
                {
                    "$elt": "Pistoia",
                    "$id": "PT"
                },
                {
                    "$elt": "Pordenone",
                    "$id": "PN"
                },
                {
                    "$elt": "Potenza",
                    "$id": "PZ"
                },
                {
                    "$elt": "Prato",
                    "$id": "PO"
                },
                {
                    "$elt": "Ragusa",
                    "$id": "RG"
                },
                {
                    "$elt": "Ravenna",
                    "$id": "RA"
                },
                {
                    "$elt": "Reggio Calabria",
                    "$id": "RC"
                },
                {
                    "$elt": "Reggio Emilia",
                    "$id": "RE"
                },
                {
                    "$elt": "Rieti",
                    "$id": "RI"
                },
                {
                    "$elt": "Rimini",
                    "$id": "RN"
                },
                {
                    "$elt": "Roma",
                    "$id": "RM"
                },
                {
                    "$elt": "Rovigo",
                    "$id": "RO"
                },
                {
                    "$elt": "Salerno",
                    "$id": "SA"
                },
                {
                    "$elt": "Sassari",
                    "$id": "SS"
                },
                {
                    "$elt": "Savona",
                    "$id": "SV"
                },
                {
                    "$elt": "Siena",
                    "$id": "SI"
                },
                {
                    "$elt": "Siracusa",
                    "$id": "SR"
                },
                {
                    "$elt": "Sondrio",
                    "$id": "SO"
                },
                {
                    "$elt": "Taranto",
                    "$id": "TA"
                },
                {
                    "$elt": "Teramo",
                    "$id": "TE"
                },
                {
                    "$elt": "Terni",
                    "$id": "TR"
                },
                {
                    "$elt": "Torino",
                    "$id": "TO"
                },
                {
                    "$elt": "Trapani",
                    "$id": "TP"
                },
                {
                    "$elt": "Trento",
                    "$id": "TN"
                },
                {
                    "$elt": "Treviso",
                    "$id": "TV"
                },
                {
                    "$elt": "Trieste",
                    "$id": "TS"
                },
                {
                    "$elt": "Udine",
                    "$id": "UD"
                },
                {
                    "$elt": "Varese",
                    "$id": "VA"
                },
                {
                    "$elt": "Venezia",
                    "$id": "VE"
                },
                {
                    "$elt": "Verbano-Cusio-Ossola",
                    "$id": "VB"
                },
                {
                    "$elt": "Vercelli",
                    "$id": "VC"
                },
                {
                    "$elt": "Verona",
                    "$id": "VR"
                },
                {
                    "$elt": "Vibo Valentia",
                    "$id": "VV"
                },
                {
                    "$elt": "Vicenza",
                    "$id": "VI"
                },
                {
                    "$elt": "Viterbo",
                    "$id": "VT"
                }
            ],
            "validationError": "Your postcode must contain 5 digits."
        },
        "JM": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "JO": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "JP": {
            "addressLine1Help": "Example: Aoyama 1-1-1",
            "addressLine2Help": "Example: PayPal Building 1037",
            "cityHelp": "Example: Yokohama-shi, Midori-ku",
            "cityLabel": "City/Ward/Town/Village",
            "line1Label": "Street name, street number",
            "line2Label": "Building name, floor, room number",
            "optionalLabel": "(optional)",
            "postalCodePrefix": "〒",
            "postal_code_help": "Example: 123-4567",
            "postcodeLabel": "Postcode",
            "selectLabel": "Select",
            "stateLabel": "Prefecture",
            "stateList": [
                {
                    "$elt": "Hokkaido",
                    "$id": "HOKKAIDO"
                },
                {
                    "$elt": "Aomori",
                    "$id": "AOMORI-KEN"
                },
                {
                    "$elt": "Iwate",
                    "$id": "IWATE-KEN"
                },
                {
                    "$elt": "Miyagi",
                    "$id": "MIYAGI-KEN"
                },
                {
                    "$elt": "Akita",
                    "$id": "AKITA-KEN"
                },
                {
                    "$elt": "Yamagata",
                    "$id": "YAMAGATA-KEN"
                },
                {
                    "$elt": "Fukushima",
                    "$id": "FUKUSHIMA-KEN"
                },
                {
                    "$elt": "Ibaraki",
                    "$id": "IBARAKI-KEN"
                },
                {
                    "$elt": "Tochigi",
                    "$id": "TOCHIGI-KEN"
                },
                {
                    "$elt": "Gunma",
                    "$id": "GUNMA-KEN"
                },
                {
                    "$elt": "Saitama",
                    "$id": "SAITAMA-KEN"
                },
                {
                    "$elt": "Chiba",
                    "$id": "CHIBA-KEN"
                },
                {
                    "$elt": "Tokyo",
                    "$id": "TOKYO-TO"
                },
                {
                    "$elt": "Kanagawa",
                    "$id": "KANAGAWA-KEN"
                },
                {
                    "$elt": "Niigata",
                    "$id": "NIIGATA-KEN"
                },
                {
                    "$elt": "Toyama",
                    "$id": "TOYAMA-KEN"
                },
                {
                    "$elt": "Ishikawa",
                    "$id": "ISHIKAWA-KEN"
                },
                {
                    "$elt": "Fukui",
                    "$id": "FUKUI-KEN"
                },
                {
                    "$elt": "Yamanashi",
                    "$id": "YAMANASHI-KEN"
                },
                {
                    "$elt": "Nagano",
                    "$id": "NAGANO-KEN"
                },
                {
                    "$elt": "Gifu",
                    "$id": "GIFU-KEN"
                },
                {
                    "$elt": "Shizuoka",
                    "$id": "SHIZUOKA-KEN"
                },
                {
                    "$elt": "Aichi",
                    "$id": "AICHI-KEN"
                },
                {
                    "$elt": "Mie",
                    "$id": "MIE-KEN"
                },
                {
                    "$elt": "Shiga",
                    "$id": "SHIGA-KEN"
                },
                {
                    "$elt": "Kyoto",
                    "$id": "KYOTO-FU"
                },
                {
                    "$elt": "Osaka",
                    "$id": "OSAKA-FU"
                },
                {
                    "$elt": "Hyogo",
                    "$id": "HYOGO-KEN"
                },
                {
                    "$elt": "Nara",
                    "$id": "NARA-KEN"
                },
                {
                    "$elt": "Wakayama",
                    "$id": "WAKAYAMA-KEN"
                },
                {
                    "$elt": "Tottori",
                    "$id": "TOTTORI-KEN"
                },
                {
                    "$elt": "Shimane",
                    "$id": "SHIMANE-KEN"
                },
                {
                    "$elt": "Okayama",
                    "$id": "OKAYAMA-KEN"
                },
                {
                    "$elt": "Hiroshima",
                    "$id": "HIROSHIMA-KEN"
                },
                {
                    "$elt": "Yamaguchi",
                    "$id": "YAMAGUCHI-KEN"
                },
                {
                    "$elt": "Tokushima",
                    "$id": "TOKUSHIMA-KEN"
                },
                {
                    "$elt": "Kagawa",
                    "$id": "KAGAWA-KEN"
                },
                {
                    "$elt": "Ehime",
                    "$id": "EHIME-KEN"
                },
                {
                    "$elt": "Kochi",
                    "$id": "KOCHI-KEN"
                },
                {
                    "$elt": "Fukuoka",
                    "$id": "FUKUOKA-KEN"
                },
                {
                    "$elt": "Saga",
                    "$id": "SAGA-KEN"
                },
                {
                    "$elt": "Nagasaki",
                    "$id": "NAGASAKI-KEN"
                },
                {
                    "$elt": "Kumamoto",
                    "$id": "KUMAMOTO-KEN"
                },
                {
                    "$elt": "Oita",
                    "$id": "OITA-KEN"
                },
                {
                    "$elt": "Miyazaki",
                    "$id": "MIYAZAKI-KEN"
                },
                {
                    "$elt": "Kagoshima",
                    "$id": "KAGOSHIMA-KEN"
                },
                {
                    "$elt": "Okinawa",
                    "$id": "OKINAWA-KEN"
                }
            ],
            "validationError": "Your postcode must contain 7 digits and be in the following format: 112-0001"
        },
        "KE": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "KG": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province",
            "validationError": "Your postcode must be 6 digits."
        },
        "KH": {
            "cityLabel": "City/Province",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 5 digits."
        },
        "KI": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "KM": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "KN": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "KR": {
            "cityLabel": "City/District",
            "line1Label": "Street, Building number",
            "line2Label": "More address information",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province",
            "stateList": [
                {
                    "$elt": "Busan",
                    "$id": "Busan"
                },
                {
                    "$elt": "Chungcheongbuk-do",
                    "$id": "Chungcheongbuk-do"
                },
                {
                    "$elt": "Chungcheongnam-do",
                    "$id": "Chungcheongnam-do"
                },
                {
                    "$elt": "Daegu",
                    "$id": "Daegu"
                },
                {
                    "$elt": "Daejeon",
                    "$id": "Daejeon"
                },
                {
                    "$elt": "Gangwon-do",
                    "$id": "Gangwon-do"
                },
                {
                    "$elt": "Gwangju",
                    "$id": "Gwangju"
                },
                {
                    "$elt": "Gyeonggi-do",
                    "$id": "Gyeonggi-do"
                },
                {
                    "$elt": "Gyeongsangbuk-do",
                    "$id": "Gyeongsangbuk-do"
                },
                {
                    "$elt": "Gyeongsangnam-do",
                    "$id": "Gyeongsangnam-do"
                },
                {
                    "$elt": "Incheon",
                    "$id": "Incheon"
                },
                {
                    "$elt": "Jeju-do",
                    "$id": "Jeju-do"
                },
                {
                    "$elt": "Jeollabuk-do",
                    "$id": "Jeollabuk-do"
                },
                {
                    "$elt": "Jeollanam-do",
                    "$id": "Jeollanam-do"
                },
                {
                    "$elt": "Sejong",
                    "$id": "Sejong"
                },
                {
                    "$elt": "Seoul",
                    "$id": "Seoul"
                },
                {
                    "$elt": "Ulsan",
                    "$id": "Ulsan"
                }
            ],
            "validationError": "Your postcode must be 5 digits."
        },
        "KW": {
            "cityLabel": "City",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 5 digits."
        },
        "KY": {
            "cityLabel": "Island name",
            "line1Label": "Street name and house number",
            "line2Label": "More address details",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "State"
        },
        "KZ": {
            "cityLabel": "City",
            "line1Label": "Street name and house number",
            "line2Label": "District",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Region/Independent town/city",
            "stateList": [
                {
                    "$elt": "Almaty",
                    "$id": "Almaty"
                },
                {
                    "$elt": "Almaty Region",
                    "$id": "Almaty Region"
                },
                {
                    "$elt": "Aqmola Region",
                    "$id": "Aqmola Region"
                },
                {
                    "$elt": "Aqtöbe Region",
                    "$id": "Aqtöbe Region"
                },
                {
                    "$elt": "Astana",
                    "$id": "Astana"
                },
                {
                    "$elt": "Atyraū Region",
                    "$id": "Atyraū Region"
                },
                {
                    "$elt": "Batys Qazaqstan Region",
                    "$id": "Batys Qazaqstan Region"
                },
                {
                    "$elt": "Mangghystaū Region",
                    "$id": "Mangghystaū Region"
                },
                {
                    "$elt": "Ongtüstik Qazaqstan Region",
                    "$id": "Ongtüstik Qazaqstan Region"
                },
                {
                    "$elt": "Pavlodar Region",
                    "$id": "Pavlodar Region"
                },
                {
                    "$elt": "Qaraghandy Region",
                    "$id": "Qaraghandy Region"
                },
                {
                    "$elt": "Qostanay Region",
                    "$id": "Qostanay Region"
                },
                {
                    "$elt": "Qyzylorda Region",
                    "$id": "Qyzylorda Region"
                },
                {
                    "$elt": "Shyghys Qazaqstan Region",
                    "$id": "Shyghys Qazaqstan Region"
                },
                {
                    "$elt": "Soltüstik Qazaqstan Region",
                    "$id": "Soltüstik Qazaqstan Region"
                },
                {
                    "$elt": "Zhambyl Region",
                    "$id": "Zhambyl Region"
                }
            ],
            "validationError": "Your postcode must consist of 7 alphanumeric characters."
        },
        "LA": {
            "cityLabel": "City",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 5 digits."
        },
        "LC": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "LI": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "LK": {
            "cityLabel": "City",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 5 digits."
        },
        "LS": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "LT": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 5 digits."
        },
        "LU": {
            "cityLabel": "City",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 4 digits."
        },
        "LV": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must start with the letters \"LV-\" and be followed by 4 digits."
        },
        "MA": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 5 digits."
        },
        "MC": {
            "cityLabel": "City",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "State",
            "validationError": "Your postcode must be 980 followed by 2 digits."
        },
        "MD": {
            "cityLabel": "City",
            "line1Label": "Street name and house number",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 4 digits."
        },
        "ME": {
            "cityLabel": "Locality",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 8 followed by 4 digits."
        },
        "MG": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "MH": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "MK": {
            "cityLabel": "Municipality",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postal number",
            "validationError": "Your postal number must be 4 digits."
        },
        "ML": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "MN": {
            "cityLabel": "District/Sub-province",
            "line1Label": "Address",
            "line2Label": "Sub-district/Sub-division",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Capital/Province",
            "stateList": [
                {
                    "$elt": "Arkhangai",
                    "$id": "ARKHANGAI"
                },
                {
                    "$elt": "Bayan-Ölgii",
                    "$id": "BAYAN-ÖLGII"
                },
                {
                    "$elt": "Bayankhongor",
                    "$id": "BAYANKHONGOR"
                },
                {
                    "$elt": "Bulgan",
                    "$id": "BULGAN"
                },
                {
                    "$elt": "Darkhan-Uul",
                    "$id": "DARKHAN-UUL"
                },
                {
                    "$elt": "Dornod",
                    "$id": "DORNOD"
                },
                {
                    "$elt": "Dornogovi",
                    "$id": "DORNOGOVI"
                },
                {
                    "$elt": "Dundgovi",
                    "$id": "DUNDGOVI"
                },
                {
                    "$elt": "Govi-Altai",
                    "$id": "GOVI-ALTAI"
                },
                {
                    "$elt": "Govisümber",
                    "$id": "GOVISÜMBER"
                },
                {
                    "$elt": "Khentii",
                    "$id": "KHENTII"
                },
                {
                    "$elt": "Khovd",
                    "$id": "KHOVD"
                },
                {
                    "$elt": "Khövsgöl",
                    "$id": "KHÖVSGÖL"
                },
                {
                    "$elt": "Ömnögovi",
                    "$id": "ÖMNÖGOVI"
                },
                {
                    "$elt": "Orkhon",
                    "$id": "ORKHON"
                },
                {
                    "$elt": "Övörkhangai",
                    "$id": "ÖVÖRKHANGAI"
                },
                {
                    "$elt": "Selenge",
                    "$id": "SELENGE"
                },
                {
                    "$elt": "Sükhbaatar",
                    "$id": "SÜKHBAATAR"
                },
                {
                    "$elt": "Töv",
                    "$id": "TÖV"
                },
                {
                    "$elt": "Ulaanbaatar",
                    "$id": "ULAANBAATAR"
                },
                {
                    "$elt": "Uvs",
                    "$id": "UVS"
                },
                {
                    "$elt": "Zavkhan",
                    "$id": "ZAVKHAN"
                }
            ],
            "validationError": "Your postcode must be 5 digits."
        },
        "MQ": {
            "cityLabel": "City",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 5 digits long and begin with 972 or 982."
        },
        "MR": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "MS": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "MT": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 3 letters followed by a space (optional) and then 4 digits."
        },
        "MU": {
            "cityLabel": "Main village/Town",
            "line1Label": "Street name and house number",
            "line2Label": "Sub-locality",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "District/Dependency",
            "stateList": [
                {
                    "$elt": "Agalega Islands",
                    "$id": "Agalega Islands"
                },
                {
                    "$elt": "Black River",
                    "$id": "Black River"
                },
                {
                    "$elt": "Cargados Carajos Shoals",
                    "$id": "Cargados Carajos Shoals"
                },
                {
                    "$elt": "Flacq",
                    "$id": "Flacq"
                },
                {
                    "$elt": "Grand Port",
                    "$id": "Grand Port"
                },
                {
                    "$elt": "Moka",
                    "$id": "Moka"
                },
                {
                    "$elt": "Pamplemousses",
                    "$id": "Pamplemousses"
                },
                {
                    "$elt": "Plaines Wilhems",
                    "$id": "Plaines Wilhems"
                },
                {
                    "$elt": "Port Louis",
                    "$id": "Port Louis"
                },
                {
                    "$elt": "Rivière du Rempart",
                    "$id": "Rivière du Rempart"
                },
                {
                    "$elt": "Rodrigues Island",
                    "$id": "Rodrigues Island"
                },
                {
                    "$elt": "Savanne",
                    "$id": "Savanne"
                }
            ],
            "validationError": "Your postcode must be 5 digits."
        },
        "MV": {
            "cityLabel": "Town/City/Region",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 5 digits."
        },
        "MW": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "MX": {
            "cityLabel": "City",
            "line1Label": "Address line 1",
            "line2Label": "Neighbourhood/Quarter",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "selectLabel": "Select",
            "stateLabel": "State",
            "stateList": [
                {
                    "$elt": "Aguascalientes",
                    "$id": "AGS"
                },
                {
                    "$elt": "Baja California",
                    "$id": "BC"
                },
                {
                    "$elt": "Baja California Sur",
                    "$id": "BCS"
                },
                {
                    "$elt": "Campeche",
                    "$id": "CAMP"
                },
                {
                    "$elt": "Chiapas",
                    "$id": "CHIS"
                },
                {
                    "$elt": "Chihuahua",
                    "$id": "CHIH"
                },
                {
                    "$elt": "Coahuila",
                    "$id": "COAH"
                },
                {
                    "$elt": "Colima",
                    "$id": "COL"
                },
                {
                    "$elt": "Distrito Federal",
                    "$id": "DF"
                },
                {
                    "$elt": "Durango",
                    "$id": "DGO"
                },
                {
                    "$elt": "Estado de México",
                    "$id": "MEX"
                },
                {
                    "$elt": "Guanajuato",
                    "$id": "GTO"
                },
                {
                    "$elt": "Guerrero",
                    "$id": "GRO"
                },
                {
                    "$elt": "Hidalgo",
                    "$id": "HGO"
                },
                {
                    "$elt": "Jalisco",
                    "$id": "JAL"
                },
                {
                    "$elt": "Michoacán",
                    "$id": "MICH"
                },
                {
                    "$elt": "Morelos",
                    "$id": "MOR"
                },
                {
                    "$elt": "Nayarit",
                    "$id": "NAY"
                },
                {
                    "$elt": "Nuevo León",
                    "$id": "NL"
                },
                {
                    "$elt": "Oaxaca",
                    "$id": "OAX"
                },
                {
                    "$elt": "Puebla",
                    "$id": "PUE"
                },
                {
                    "$elt": "Querétaro",
                    "$id": "QRO"
                },
                {
                    "$elt": "Quintana Roo",
                    "$id": "Q ROO"
                },
                {
                    "$elt": "San Luis Potosí",
                    "$id": "SLP"
                },
                {
                    "$elt": "Sinaloa",
                    "$id": "SIN"
                },
                {
                    "$elt": "Sonora",
                    "$id": "SON"
                },
                {
                    "$elt": "Tabasco",
                    "$id": "TAB"
                },
                {
                    "$elt": "Tamaulipas",
                    "$id": "TAMPS"
                },
                {
                    "$elt": "Tlaxcala",
                    "$id": "TLAX"
                },
                {
                    "$elt": "Veracruz",
                    "$id": "VER"
                },
                {
                    "$elt": "Yucatán",
                    "$id": "YUC"
                },
                {
                    "$elt": "Zacatecas",
                    "$id": "ZAC"
                }
            ],
            "validationError": "Your postcode must contain 5 digits."
        },
        "MY": {
            "cityLabel": "Town/City/Locality",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "County/Federal territory",
            "stateList": [
                {
                    "$elt": "Johor",
                    "$id": "Johor"
                },
                {
                    "$elt": "Kedah",
                    "$id": "Kedah"
                },
                {
                    "$elt": "Kelantan",
                    "$id": "Kelantan"
                },
                {
                    "$elt": "Kuala Lumpur",
                    "$id": "Kuala Lumpur"
                },
                {
                    "$elt": "Labuan",
                    "$id": "Labuan"
                },
                {
                    "$elt": "Melaka",
                    "$id": "Melaka"
                },
                {
                    "$elt": "Negeri Sembilan",
                    "$id": "Negeri Sembilan"
                },
                {
                    "$elt": "Pahang",
                    "$id": "Pahang"
                },
                {
                    "$elt": "Perak",
                    "$id": "Perak"
                },
                {
                    "$elt": "Perlis",
                    "$id": "Perlis"
                },
                {
                    "$elt": "Pulau Pinang",
                    "$id": "Pulau Pinang"
                },
                {
                    "$elt": "Putrajaya",
                    "$id": "Putrajaya"
                },
                {
                    "$elt": "Sabah",
                    "$id": "Sabah"
                },
                {
                    "$elt": "Sarawak",
                    "$id": "Sarawak"
                },
                {
                    "$elt": "Selangor",
                    "$id": "Selangor"
                },
                {
                    "$elt": "Terengganu",
                    "$id": "Terengganu"
                }
            ],
            "validationError": "Your postcode must be 5 digits."
        },
        "MZ": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "NA": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "NC": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "NE": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "NF": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "NG": {
            "cityLabel": "City",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "County/Capital",
            "stateList": [
                {
                    "$elt": "Abia",
                    "$id": "ABIA STATE"
                },
                {
                    "$elt": "Abuja Federal Capital Territory",
                    "$id": "FCT"
                },
                {
                    "$elt": "Adamawa",
                    "$id": "ADAMAWA STATE"
                },
                {
                    "$elt": "Akwa Ibom",
                    "$id": "AKWA IBOM STATE"
                },
                {
                    "$elt": "Anambra",
                    "$id": "ANAMBRA STATE"
                },
                {
                    "$elt": "Bauchi",
                    "$id": "BAUCHI STATE"
                },
                {
                    "$elt": "Bayelsa",
                    "$id": "BAYELSA STATE"
                },
                {
                    "$elt": "Benue",
                    "$id": "BENUE STATE"
                },
                {
                    "$elt": "Borno",
                    "$id": "BORNO STATE"
                },
                {
                    "$elt": "Cross River",
                    "$id": "CROSS RIVER STATE"
                },
                {
                    "$elt": "Delta",
                    "$id": "DELTA STATE"
                },
                {
                    "$elt": "Ebonyi",
                    "$id": "EBONYI STATE"
                },
                {
                    "$elt": "Edo",
                    "$id": "EDO STATE"
                },
                {
                    "$elt": "Ekiti",
                    "$id": "EKITI STATE"
                },
                {
                    "$elt": "Enugu",
                    "$id": "ENUGU STATE"
                },
                {
                    "$elt": "Gombe",
                    "$id": "GOMBE STATE"
                },
                {
                    "$elt": "Imo",
                    "$id": "IMO STATE"
                },
                {
                    "$elt": "Jigawa",
                    "$id": "JIGAWA STATE"
                },
                {
                    "$elt": "Kaduna",
                    "$id": "KADUNA STATE"
                },
                {
                    "$elt": "Kano",
                    "$id": "KANO STATE"
                },
                {
                    "$elt": "Katsina",
                    "$id": "KATSINA STATE"
                },
                {
                    "$elt": "Kebbi",
                    "$id": "KEBBI STATE"
                },
                {
                    "$elt": "Kogi",
                    "$id": "KOGI STATE"
                },
                {
                    "$elt": "Kwara",
                    "$id": "KWARA STATE"
                },
                {
                    "$elt": "Lagos",
                    "$id": "LAGOS STATE"
                },
                {
                    "$elt": "Nassarawa",
                    "$id": "NASARAWA STATE"
                },
                {
                    "$elt": "Niger",
                    "$id": "NIGER STATE"
                },
                {
                    "$elt": "Ogun",
                    "$id": "OGUN STATE"
                },
                {
                    "$elt": "Ondo",
                    "$id": "ONDO STATE"
                },
                {
                    "$elt": "Osun",
                    "$id": "OSUN STATE"
                },
                {
                    "$elt": "Oyo",
                    "$id": "OYO STATE"
                },
                {
                    "$elt": "Plateau",
                    "$id": "PLATEAU STATE"
                },
                {
                    "$elt": "Rivers",
                    "$id": "RIVERS STATE"
                },
                {
                    "$elt": "Sokoto",
                    "$id": "SOKOTO STATE"
                },
                {
                    "$elt": "Taraba",
                    "$id": "TARABA STATE"
                },
                {
                    "$elt": "Yobe",
                    "$id": "YOBE STATE"
                },
                {
                    "$elt": "Zamfara",
                    "$id": "ZAMFARA STATE"
                }
            ],
            "validationError": "Your postcode must be 6 digits."
        },
        "NI": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "NL": {
            "cityLabel": "City",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 4 digits long followed by 2 letters."
        },
        "NO": {
            "cityLabel": "Town/City",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 4 digits."
        },
        "NP": {
            "cityLabel": "City/District",
            "groupStateList": [
                {
                    "$id": "Capital",
                    "group": [
                        {
                            "$elt": "Kathmandu",
                            "$id": "KATHMANDU"
                        }
                    ],
                    "label": "Capital"
                },
                {
                    "$id": "NP-1",
                    "group": [
                        {
                            "$elt": "Bagmati",
                            "$id": "BAGMATI"
                        },
                        {
                            "$elt": "Janakpur",
                            "$id": "JANAKPUR"
                        },
                        {
                            "$elt": "Narayani",
                            "$id": "NARAYANI"
                        }
                    ],
                    "label": "Central Region (Madhyamanchal)"
                },
                {
                    "$id": "NP-4",
                    "group": [
                        {
                            "$elt": "Kosi",
                            "$id": "KOSI"
                        },
                        {
                            "$elt": "Mechi",
                            "$id": "MECHI"
                        },
                        {
                            "$elt": "Sagarmatha",
                            "$id": "SAGARMATHA"
                        }
                    ],
                    "label": "Eastern Region (Purwanchal)"
                },
                {
                    "$id": "NP-5",
                    "group": [
                        {
                            "$elt": "Mahakali",
                            "$id": "MAHAKALI"
                        },
                        {
                            "$elt": "Seti",
                            "$id": "SETI"
                        }
                    ],
                    "label": "Far-Western Region (Sudur Pashchimanchal)"
                },
                {
                    "$id": "NP-2",
                    "group": [
                        {
                            "$elt": "Bheri",
                            "$id": "BHERI"
                        },
                        {
                            "$elt": "Karnali",
                            "$id": "KARNALI"
                        },
                        {
                            "$elt": "Rapti",
                            "$id": "RAPTI"
                        }
                    ],
                    "label": "Mid-Western Region (Madhya Pashchimanchal)"
                },
                {
                    "$id": "NP-3",
                    "group": [
                        {
                            "$elt": "Dhawalagiri",
                            "$id": "DHAWALAGIRI"
                        },
                        {
                            "$elt": "Gandaki",
                            "$id": "GANDAKI"
                        },
                        {
                            "$elt": "Lumbini",
                            "$id": "LUMBINI"
                        }
                    ],
                    "label": "Western Region (Pashchimanchal)"
                }
            ],
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Zone/Capital",
            "stateList": [
                {
                    "$elt": "Bagmati",
                    "$id": "BAGMATI"
                },
                {
                    "$elt": "Bheri",
                    "$id": "BHERI"
                },
                {
                    "$elt": "Dhawalagiri",
                    "$id": "DHAWALAGIRI"
                },
                {
                    "$elt": "Gandaki",
                    "$id": "GANDAKI"
                },
                {
                    "$elt": "Janakpur",
                    "$id": "JANAKPUR"
                },
                {
                    "$elt": "Karnali",
                    "$id": "KARNALI"
                },
                {
                    "$elt": "Kathmandu",
                    "$id": "KATHMANDU"
                },
                {
                    "$elt": "Kosi",
                    "$id": "KOSI"
                },
                {
                    "$elt": "Lumbini",
                    "$id": "LUMBINI"
                },
                {
                    "$elt": "Mahakali",
                    "$id": "MAHAKALI"
                },
                {
                    "$elt": "Mechi",
                    "$id": "MECHI"
                },
                {
                    "$elt": "Narayani",
                    "$id": "NARAYANI"
                },
                {
                    "$elt": "Rapti",
                    "$id": "RAPTI"
                },
                {
                    "$elt": "Sagarmatha",
                    "$id": "SAGARMATHA"
                },
                {
                    "$elt": "Seti",
                    "$id": "SETI"
                }
            ],
            "validationError": "Your postcode must be 5 digits."
        },
        "NR": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "NU": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "NZ": {
            "cityLabel": "City/Suburb",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 4 digits."
        },
        "OM": {
            "cityLabel": "Town/City",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province",
            "validationError": "Your postcode must be 3 digits long."
        },
        "PA": {
            "cityLabel": "City/District",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Region",
            "stateList": [
                {
                    "$elt": "Bocas del Toro",
                    "$id": "Provincia de Bocas del Toro"
                },
                {
                    "$elt": "Chiriquí",
                    "$id": "Provincia de Chiriquí"
                },
                {
                    "$elt": "Coclé",
                    "$id": "Provincia de Coclé"
                },
                {
                    "$elt": "Colón",
                    "$id": "Provincia de Colón"
                },
                {
                    "$elt": "Darién",
                    "$id": "Provincia del Darién"
                },
                {
                    "$elt": "Emberá-Wounaan",
                    "$id": "Emberá-Wounaan"
                },
                {
                    "$elt": "Guna de Madugandí",
                    "$id": "Guna de Madugandí"
                },
                {
                    "$elt": "Guna de Wargandí",
                    "$id": "Guna de Wargandí"
                },
                {
                    "$elt": "Guna Yala",
                    "$id": "Guna Yala"
                },
                {
                    "$elt": "Herrera",
                    "$id": "Provincia de Herrera"
                },
                {
                    "$elt": "Los Santos",
                    "$id": "Provincia de Los Santos"
                },
                {
                    "$elt": "Ngöbe-Buglé",
                    "$id": "Ngöbe-Buglé"
                },
                {
                    "$elt": "Panamá",
                    "$id": "Provincia de Panamá"
                },
                {
                    "$elt": "Panamá Oeste",
                    "$id": "Provincia de Panamá Oeste"
                },
                {
                    "$elt": "Veraguas",
                    "$id": "Provincia de Veraguas"
                }
            ],
            "validationError": "Your postcode must be 4 digits."
        },
        "PE": {
            "cityLabel": "City",
            "line1Label": "Street name and house number",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province",
            "validationError": "Your postcode must be 5 digits."
        },
        "PF": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "PG": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "PH": {
            "cityLabel": "Town/City/Municipality",
            "groupStateList": [
                {
                    "$id": "PH-14",
                    "group": [
                        {
                            "$elt": "Basilan",
                            "$id": "BASILAN"
                        },
                        {
                            "$elt": "Lanao del Sur",
                            "$id": "LANAO DEL SUR"
                        },
                        {
                            "$elt": "Maguindanao",
                            "$id": "MAGUINDANAO"
                        },
                        {
                            "$elt": "Sulu",
                            "$id": "SULU"
                        },
                        {
                            "$elt": "Tawi-Tawi",
                            "$id": "TAWI-TAWI"
                        }
                    ],
                    "label": "Autonomous Region in Muslim Mindanao"
                },
                {
                    "$id": "PH-05",
                    "group": [
                        {
                            "$elt": "Albay",
                            "$id": "ALBAY"
                        },
                        {
                            "$elt": "Camarines Norte",
                            "$id": "CAMARINES NORTE"
                        },
                        {
                            "$elt": "Camarines Sur",
                            "$id": "CAMARINES SUR"
                        },
                        {
                            "$elt": "Catanduanes",
                            "$id": "CATANDUANES"
                        },
                        {
                            "$elt": "Masbate",
                            "$id": "MASBATE"
                        },
                        {
                            "$elt": "Sorsogon",
                            "$id": "SORSOGON"
                        }
                    ],
                    "label": "Bicol"
                },
                {
                    "$id": "PH-02",
                    "group": [
                        {
                            "$elt": "Batanes",
                            "$id": "BATANES"
                        },
                        {
                            "$elt": "Cagayan",
                            "$id": "CAGAYAN"
                        },
                        {
                            "$elt": "Isabela",
                            "$id": "ISABELA"
                        },
                        {
                            "$elt": "Nueva Vizcaya",
                            "$id": "NUEVA VIZCAYA"
                        },
                        {
                            "$elt": "Quirino",
                            "$id": "QUIRINO"
                        }
                    ],
                    "label": "Cagayan Valley"
                },
                {
                    "$id": "PH-40",
                    "group": [
                        {
                            "$elt": "Batangas",
                            "$id": "BATANGAS"
                        },
                        {
                            "$elt": "Cavite",
                            "$id": "CAVITE"
                        },
                        {
                            "$elt": "Laguna",
                            "$id": "LAGUNA"
                        },
                        {
                            "$elt": "Quezon",
                            "$id": "QUEZON"
                        },
                        {
                            "$elt": "Rizal",
                            "$id": "RIZAL"
                        }
                    ],
                    "label": "Calabarzon"
                },
                {
                    "$id": "PH-13",
                    "group": [
                        {
                            "$elt": "Agusan del Norte",
                            "$id": "AGUSAN DEL NORTE"
                        },
                        {
                            "$elt": "Agusan del Sur",
                            "$id": "AGUSAN DEL SUR"
                        },
                        {
                            "$elt": "Dinagat Islands",
                            "$id": "DINAGAT ISLANDS"
                        },
                        {
                            "$elt": "Surigao del Norte",
                            "$id": "SURIGAO DEL NORTE"
                        },
                        {
                            "$elt": "Surigao del Sur",
                            "$id": "SURIGAO DEL SUR"
                        }
                    ],
                    "label": "Caraga"
                },
                {
                    "$id": "PH-03",
                    "group": [
                        {
                            "$elt": "Aurora",
                            "$id": "AURORA"
                        },
                        {
                            "$elt": "Bataan",
                            "$id": "BATAAN"
                        },
                        {
                            "$elt": "Bulacan",
                            "$id": "BULACAN"
                        },
                        {
                            "$elt": "Nueva Ecija",
                            "$id": "NUEVA ECIJA"
                        },
                        {
                            "$elt": "Pampanga",
                            "$id": "PAMPANGA"
                        },
                        {
                            "$elt": "Tarlac",
                            "$id": "TARLAC"
                        },
                        {
                            "$elt": "Zambales",
                            "$id": "ZAMBALES"
                        }
                    ],
                    "label": "Central Luzon"
                },
                {
                    "$id": "PH-07",
                    "group": [
                        {
                            "$elt": "Bohol",
                            "$id": "BOHOL"
                        },
                        {
                            "$elt": "Cebu",
                            "$id": "CEBU"
                        },
                        {
                            "$elt": "Negros Oriental",
                            "$id": "NEGROS ORIENTAL"
                        },
                        {
                            "$elt": "Siquijor",
                            "$id": "SIQUIJOR"
                        }
                    ],
                    "label": "Central Visayas"
                },
                {
                    "$id": "PH-15",
                    "group": [
                        {
                            "$elt": "Abra",
                            "$id": "ABRA"
                        },
                        {
                            "$elt": "Apayao",
                            "$id": "APAYAO"
                        },
                        {
                            "$elt": "Benguet",
                            "$id": "BENGUET"
                        },
                        {
                            "$elt": "Ifugao",
                            "$id": "IFUGAO"
                        },
                        {
                            "$elt": "Kalinga",
                            "$id": "KALINGA"
                        },
                        {
                            "$elt": "Mountain Province",
                            "$id": "MOUNTAIN PROVINCE"
                        }
                    ],
                    "label": "Cordillera Administrative Region"
                },
                {
                    "$id": "PH-11",
                    "group": [
                        {
                            "$elt": "Compostela Valley",
                            "$id": "COMPOSTELA VALLEY"
                        },
                        {
                            "$elt": "Davao del Norte",
                            "$id": "DAVAO DEL NORTE"
                        },
                        {
                            "$elt": "Davao del Sur",
                            "$id": "DAVAO DEL SUR"
                        },
                        {
                            "$elt": "Davao Oriental",
                            "$id": "DAVAO ORIENTAL"
                        }
                    ],
                    "label": "Davao"
                },
                {
                    "$id": "PH-08",
                    "group": [
                        {
                            "$elt": "Biliran",
                            "$id": "BILIRAN"
                        },
                        {
                            "$elt": "Eastern Samar",
                            "$id": "EASTERN SAMAR"
                        },
                        {
                            "$elt": "Leyte",
                            "$id": "LEYTE"
                        },
                        {
                            "$elt": "Northern Samar",
                            "$id": "NORTHERN SAMAR"
                        },
                        {
                            "$elt": "Samar (Western Samar)",
                            "$id": "SAMAR"
                        },
                        {
                            "$elt": "Southern Leyte",
                            "$id": "SOUTHERN LEYTE"
                        }
                    ],
                    "label": "Eastern Visayas"
                },
                {
                    "$id": "PH-01",
                    "group": [
                        {
                            "$elt": "Ilocos Norte",
                            "$id": "ILOCOS NORTE"
                        },
                        {
                            "$elt": "Ilocos Sur",
                            "$id": "ILOCOS SUR"
                        },
                        {
                            "$elt": "La Union",
                            "$id": "LA UNION"
                        },
                        {
                            "$elt": "Pangasinan",
                            "$id": "PANGASINAN"
                        }
                    ],
                    "label": "Ilocos"
                },
                {
                    "$id": "PH-41",
                    "group": [
                        {
                            "$elt": "Marinduque",
                            "$id": "MARINDUQUE"
                        },
                        {
                            "$elt": "Mindoro Occidental",
                            "$id": "MINDORO OCCIDENTAL"
                        },
                        {
                            "$elt": "Mindoro Oriental",
                            "$id": "MINDORO ORIENTAL"
                        },
                        {
                            "$elt": "Palawan",
                            "$id": "PALAWAN"
                        },
                        {
                            "$elt": "Romblon",
                            "$id": "ROMBLON"
                        }
                    ],
                    "label": "Mimaropa"
                },
                {
                    "$id": "PH-00",
                    "group": [
                        {
                            "$elt": "Manila",
                            "$id": "MANILA"
                        },
                        {
                            "$elt": "Metro Manila",
                            "$id": "METRO MANILA"
                        }
                    ],
                    "label": "National Capital Region"
                },
                {
                    "$id": "PH-10",
                    "group": [
                        {
                            "$elt": "Bukidnon",
                            "$id": "BUKIDNON"
                        },
                        {
                            "$elt": "Camiguin",
                            "$id": "CAMIGUIN"
                        },
                        {
                            "$elt": "Lanao del Norte",
                            "$id": "LANAO DEL NORTE"
                        },
                        {
                            "$elt": "Misamis Occidental",
                            "$id": "MISAMIS OCCIDENTAL"
                        },
                        {
                            "$elt": "Misamis Oriental",
                            "$id": "MISAMIS ORIENTAL"
                        }
                    ],
                    "label": "Northern Mindanao"
                },
                {
                    "$id": "PH-12",
                    "group": [
                        {
                            "$elt": "Cotabato",
                            "$id": "COTABATO"
                        },
                        {
                            "$elt": "Sarangani",
                            "$id": "SARANGANI"
                        },
                        {
                            "$elt": "South Cotabato",
                            "$id": "SOUTH COTABATO"
                        },
                        {
                            "$elt": "Sultan Kudarat",
                            "$id": "SULTAN KUDARAT"
                        }
                    ],
                    "label": "Soccsksargen"
                },
                {
                    "$id": "PH-06",
                    "group": [
                        {
                            "$elt": "Aklan",
                            "$id": "AKLAN"
                        },
                        {
                            "$elt": "Antique",
                            "$id": "ANTIQUE"
                        },
                        {
                            "$elt": "Capiz",
                            "$id": "CAPIZ"
                        },
                        {
                            "$elt": "Guimaras",
                            "$id": "GUIMARAS"
                        },
                        {
                            "$elt": "Iloilo",
                            "$id": "ILOILO"
                        },
                        {
                            "$elt": "Negros Occidental",
                            "$id": "NEGROS OCCIDENTAL"
                        }
                    ],
                    "label": "Western Visayas"
                },
                {
                    "$id": "PH-09",
                    "group": [
                        {
                            "$elt": "Zamboanga del Norte",
                            "$id": "ZAMBOANGA DEL NORTE"
                        },
                        {
                            "$elt": "Zamboanga del Sur",
                            "$id": "ZAMBOANGA DEL SUR"
                        },
                        {
                            "$elt": "Zamboanga Sibugay",
                            "$id": "ZAMBOANGA SIBUGAY"
                        }
                    ],
                    "label": "Zamboanga Peninsula"
                }
            ],
            "line1Label": "Street name, house number, subdivision",
            "line2Label": "Barangay/District",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Region",
            "stateList": [
                {
                    "$elt": "Abra",
                    "$id": "ABRA"
                },
                {
                    "$elt": "Agusan del Norte",
                    "$id": "AGUSAN DEL NORTE"
                },
                {
                    "$elt": "Agusan del Sur",
                    "$id": "AGUSAN DEL SUR"
                },
                {
                    "$elt": "Aklan",
                    "$id": "AKLAN"
                },
                {
                    "$elt": "Albay",
                    "$id": "ALBAY"
                },
                {
                    "$elt": "Antique",
                    "$id": "ANTIQUE"
                },
                {
                    "$elt": "Apayao",
                    "$id": "APAYAO"
                },
                {
                    "$elt": "Aurora",
                    "$id": "AURORA"
                },
                {
                    "$elt": "Basilan",
                    "$id": "BASILAN"
                },
                {
                    "$elt": "Bataan",
                    "$id": "BATAAN"
                },
                {
                    "$elt": "Batanes",
                    "$id": "BATANES"
                },
                {
                    "$elt": "Batangas",
                    "$id": "BATANGAS"
                },
                {
                    "$elt": "Benguet",
                    "$id": "BENGUET"
                },
                {
                    "$elt": "Biliran",
                    "$id": "BILIRAN"
                },
                {
                    "$elt": "Bohol",
                    "$id": "BOHOL"
                },
                {
                    "$elt": "Bukidnon",
                    "$id": "BUKIDNON"
                },
                {
                    "$elt": "Bulacan",
                    "$id": "BULACAN"
                },
                {
                    "$elt": "Cagayan",
                    "$id": "CAGAYAN"
                },
                {
                    "$elt": "Camarines Norte",
                    "$id": "CAMARINES NORTE"
                },
                {
                    "$elt": "Camarines Sur",
                    "$id": "CAMARINES SUR"
                },
                {
                    "$elt": "Camiguin",
                    "$id": "CAMIGUIN"
                },
                {
                    "$elt": "Capiz",
                    "$id": "CAPIZ"
                },
                {
                    "$elt": "Catanduanes",
                    "$id": "CATANDUANES"
                },
                {
                    "$elt": "Cavite",
                    "$id": "CAVITE"
                },
                {
                    "$elt": "Cebu",
                    "$id": "CEBU"
                },
                {
                    "$elt": "Compostela Valley",
                    "$id": "COMPOSTELA VALLEY"
                },
                {
                    "$elt": "Cotabato",
                    "$id": "COTABATO"
                },
                {
                    "$elt": "Davao del Norte",
                    "$id": "DAVAO DEL NORTE"
                },
                {
                    "$elt": "Davao del Sur",
                    "$id": "DAVAO DEL SUR"
                },
                {
                    "$elt": "Davao Oriental",
                    "$id": "DAVAO ORIENTAL"
                },
                {
                    "$elt": "Dinagat Islands",
                    "$id": "DINAGAT ISLANDS"
                },
                {
                    "$elt": "Eastern Samar",
                    "$id": "EASTERN SAMAR"
                },
                {
                    "$elt": "Guimaras",
                    "$id": "GUIMARAS"
                },
                {
                    "$elt": "Ifugao",
                    "$id": "IFUGAO"
                },
                {
                    "$elt": "Ilocos Norte",
                    "$id": "ILOCOS NORTE"
                },
                {
                    "$elt": "Ilocos Sur",
                    "$id": "ILOCOS SUR"
                },
                {
                    "$elt": "Iloilo",
                    "$id": "ILOILO"
                },
                {
                    "$elt": "Isabela",
                    "$id": "ISABELA"
                },
                {
                    "$elt": "Kalinga",
                    "$id": "KALINGA"
                },
                {
                    "$elt": "La Union",
                    "$id": "LA UNION"
                },
                {
                    "$elt": "Laguna",
                    "$id": "LAGUNA"
                },
                {
                    "$elt": "Lanao del Norte",
                    "$id": "LANAO DEL NORTE"
                },
                {
                    "$elt": "Lanao del Sur",
                    "$id": "LANAO DEL SUR"
                },
                {
                    "$elt": "Leyte",
                    "$id": "LEYTE"
                },
                {
                    "$elt": "Maguindanao",
                    "$id": "MAGUINDANAO"
                },
                {
                    "$elt": "Manila",
                    "$id": "MANILA"
                },
                {
                    "$elt": "Marinduque",
                    "$id": "MARINDUQUE"
                },
                {
                    "$elt": "Masbate",
                    "$id": "MASBATE"
                },
                {
                    "$elt": "Metro Manila",
                    "$id": "METRO MANILA"
                },
                {
                    "$elt": "Mindoro Occidental",
                    "$id": "MINDORO OCCIDENTAL"
                },
                {
                    "$elt": "Mindoro Oriental",
                    "$id": "MINDORO ORIENTAL"
                },
                {
                    "$elt": "Misamis Occidental",
                    "$id": "MISAMIS OCCIDENTAL"
                },
                {
                    "$elt": "Misamis Oriental",
                    "$id": "MISAMIS ORIENTAL"
                },
                {
                    "$elt": "Mountain Province",
                    "$id": "MOUNTAIN PROVINCE"
                },
                {
                    "$elt": "Negros Occidental",
                    "$id": "NEGROS OCCIDENTAL"
                },
                {
                    "$elt": "Negros Oriental",
                    "$id": "NEGROS ORIENTAL"
                },
                {
                    "$elt": "Northern Samar",
                    "$id": "NORTHERN SAMAR"
                },
                {
                    "$elt": "Nueva Ecija",
                    "$id": "NUEVA ECIJA"
                },
                {
                    "$elt": "Nueva Vizcaya",
                    "$id": "NUEVA VIZCAYA"
                },
                {
                    "$elt": "Palawan",
                    "$id": "PALAWAN"
                },
                {
                    "$elt": "Pampanga",
                    "$id": "PAMPANGA"
                },
                {
                    "$elt": "Pangasinan",
                    "$id": "PANGASINAN"
                },
                {
                    "$elt": "Quezon",
                    "$id": "QUEZON"
                },
                {
                    "$elt": "Quirino",
                    "$id": "QUIRINO"
                },
                {
                    "$elt": "Rizal",
                    "$id": "RIZAL"
                },
                {
                    "$elt": "Romblon",
                    "$id": "ROMBLON"
                },
                {
                    "$elt": "Samar (Western Samar)",
                    "$id": "SAMAR"
                },
                {
                    "$elt": "Sarangani",
                    "$id": "SARANGANI"
                },
                {
                    "$elt": "Siquijor",
                    "$id": "SIQUIJOR"
                },
                {
                    "$elt": "Sorsogon",
                    "$id": "SORSOGON"
                },
                {
                    "$elt": "South Cotabato",
                    "$id": "SOUTH COTABATO"
                },
                {
                    "$elt": "Southern Leyte",
                    "$id": "SOUTHERN LEYTE"
                },
                {
                    "$elt": "Sultan Kudarat",
                    "$id": "SULTAN KUDARAT"
                },
                {
                    "$elt": "Sulu",
                    "$id": "SULU"
                },
                {
                    "$elt": "Surigao del Norte",
                    "$id": "SURIGAO DEL NORTE"
                },
                {
                    "$elt": "Surigao del Sur",
                    "$id": "SURIGAO DEL SUR"
                },
                {
                    "$elt": "Tarlac",
                    "$id": "TARLAC"
                },
                {
                    "$elt": "Tawi-Tawi",
                    "$id": "TAWI-TAWI"
                },
                {
                    "$elt": "Zambales",
                    "$id": "ZAMBALES"
                },
                {
                    "$elt": "Zamboanga del Norte",
                    "$id": "ZAMBOANGA DEL NORTE"
                },
                {
                    "$elt": "Zamboanga del Sur",
                    "$id": "ZAMBOANGA DEL SUR"
                },
                {
                    "$elt": "Zamboanga Sibugay",
                    "$id": "ZAMBOANGA SIBUGAY"
                }
            ],
            "validationError": "Your postcode must be 4 digits."
        },
        "PL": {
            "cityLabel": "Town/City",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province",
            "stateList": [
                {
                    "$elt": "Dolnośląskie",
                    "$id": "DOLNOŚLĄSKIE"
                },
                {
                    "$elt": "Kujawsko-pomorskie",
                    "$id": "KUJAWSKO-POMORSKIE"
                },
                {
                    "$elt": "Lubelskie",
                    "$id": "LUBELSKIE"
                },
                {
                    "$elt": "Lubuskie",
                    "$id": "LUBUSKIE"
                },
                {
                    "$elt": "Łódzkie",
                    "$id": "ŁÓDZKIE"
                },
                {
                    "$elt": "Małopolskie",
                    "$id": "MAŁOPOLSKIE"
                },
                {
                    "$elt": "Mazowieckie",
                    "$id": "MAZOWIECKIE"
                },
                {
                    "$elt": "Opolskie",
                    "$id": "OPOLSKIE"
                },
                {
                    "$elt": "Podkarpackie",
                    "$id": "PODKARPACKIE"
                },
                {
                    "$elt": "Podlaskie",
                    "$id": "PODLASKIE"
                },
                {
                    "$elt": "Pomorskie",
                    "$id": "POMORSKIE"
                },
                {
                    "$elt": "Śląskie",
                    "$id": "ŚLĄSKIE"
                },
                {
                    "$elt": "Świętokrzyskie",
                    "$id": "ŚWIĘTOKRZYSKIE"
                },
                {
                    "$elt": "Warmińsko-mazurskie",
                    "$id": "WARMIŃSKO-MAZURSKIE"
                },
                {
                    "$elt": "Wielkopolskie",
                    "$id": "WIELKOPOLSKIE"
                },
                {
                    "$elt": "Zachodnio-pomorskie",
                    "$id": "ZACHODNIOPOMORSKIE"
                }
            ],
            "validationError": "Your postcode must be 5 digits long in the following format: 05-470"
        },
        "PM": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "PN": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "PT": {
            "cityLabel": "City",
            "line1Label": "Address line 1",
            "line2Label": "Town/Village/Post office",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "District",
            "validationError": "Your postcode must be 4 or 7 digits long."
        },
        "PW": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "PY": {
            "cityLabel": "Post town",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 4 digits."
        },
        "QA": {
            "cityLabel": "City",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)"
        },
        "RE": {
            "cityLabel": "City",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 5 digits long and begin with 974 or 984."
        },
        "RO": {
            "cityLabel": "City",
            "line1Label": "Address line 1",
            "line2Label": "Judet/Sector",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 6 digits."
        },
        "RS": {
            "cityLabel": "City",
            "line1Label": "Street name and house number",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 5 or 6 digits long."
        },
        "RU": {
            "cityLabel": "Centre of population (town/city, village)",
            "line1Label": "Street, building, apartment",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "District, Region",
            "validationError": "Your postcode must be 6 digits long and cannot begin with 0."
        },
        "RW": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "SA": {
            "cityLabel": "City",
            "line1Label": "Street name and house number",
            "line2Label": "Address line 3",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 5 digits long and can be followed by a dash and 4 digits."
        },
        "SB": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "SC": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "SE": {
            "cityLabel": "Town/City",
            "line1Label": "Street name and house number",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 5 digits."
        },
        "SG": {
            "cityLabel": "City",
            "line1Label": "Street name and house number",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 6 digits."
        },
        "SH": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "SI": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 4 digits."
        },
        "SJ": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "SK": {
            "cityLabel": "Post Office name",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 5 digits."
        },
        "SL": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "SM": {
            "cityLabel": "City",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 4789 followed by a single digit."
        },
        "SN": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "SO": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "SR": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "ST": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "SV": {
            "cityLabel": "City",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Department",
            "stateList": [
                {
                    "$elt": "Ahuachapán",
                    "$id": "AHUACHAPÁN"
                },
                {
                    "$elt": "Cabañas",
                    "$id": "CABAÑAS"
                },
                {
                    "$elt": "Chalatenango",
                    "$id": "CHALATENANGO"
                },
                {
                    "$elt": "Cuscatlán",
                    "$id": "CUSCATLÁN"
                },
                {
                    "$elt": "La Libertad",
                    "$id": "LA LIBERTAD"
                },
                {
                    "$elt": "La Paz",
                    "$id": "LA PAZ"
                },
                {
                    "$elt": "La Unión",
                    "$id": "LA UNIÓN"
                },
                {
                    "$elt": "Morazán",
                    "$id": "MORAZÁN"
                },
                {
                    "$elt": "San Miguel",
                    "$id": "SAN MIGUEL"
                },
                {
                    "$elt": "San Salvador",
                    "$id": "SAN SALVADOR"
                },
                {
                    "$elt": "San Vicente",
                    "$id": "SAN VICENTE"
                },
                {
                    "$elt": "Santa Ana",
                    "$id": "SANTA ANA"
                },
                {
                    "$elt": "Sonsonate",
                    "$id": "SONSONATE"
                },
                {
                    "$elt": "Usulután",
                    "$id": "USULUTÁN"
                }
            ],
            "validationError": "Your postcode must be 4 digits."
        },
        "SZ": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "TC": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "TD": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "TG": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "TH": {
            "cityLabel": "District",
            "line1Label": "House number and street name",
            "line2Label": "Address (continued)/Sub-district",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province",
            "stateList": [
                {
                    "$elt": "Amnat Charoen",
                    "$id": "Amnat Charoen"
                },
                {
                    "$elt": "Ang Thong",
                    "$id": "Ang Thong"
                },
                {
                    "$elt": "Bangkok",
                    "$id": "Bangkok"
                },
                {
                    "$elt": "Bueng Kan",
                    "$id": "Bueng Kan"
                },
                {
                    "$elt": "Buri Ram",
                    "$id": "Buri Ram"
                },
                {
                    "$elt": "Chachoengsao",
                    "$id": "Chachoengsao"
                },
                {
                    "$elt": "Chai Nat",
                    "$id": "Chai Nat"
                },
                {
                    "$elt": "Chaiyaphum",
                    "$id": "Chaiyaphum"
                },
                {
                    "$elt": "Chanthaburi",
                    "$id": "Chanthaburi"
                },
                {
                    "$elt": "Chiang Mai",
                    "$id": "Chiang Mai"
                },
                {
                    "$elt": "Chiang Rai",
                    "$id": "Chiang Rai"
                },
                {
                    "$elt": "Chon Buri",
                    "$id": "Chon Buri"
                },
                {
                    "$elt": "Chumphon",
                    "$id": "Chumphon"
                },
                {
                    "$elt": "Kalasin",
                    "$id": "Kalasin"
                },
                {
                    "$elt": "Kamphaeng Phet",
                    "$id": "Kamphaeng Phet"
                },
                {
                    "$elt": "Kanchanaburi",
                    "$id": "Kanchanaburi"
                },
                {
                    "$elt": "Khon Kaen",
                    "$id": "Khon Kaen"
                },
                {
                    "$elt": "Krabi",
                    "$id": "Krabi"
                },
                {
                    "$elt": "Lampang",
                    "$id": "Lampang"
                },
                {
                    "$elt": "Lamphun",
                    "$id": "Lamphun"
                },
                {
                    "$elt": "Loei",
                    "$id": "Loei"
                },
                {
                    "$elt": "Lop Buri",
                    "$id": "Lop Buri"
                },
                {
                    "$elt": "Mae Hong Son",
                    "$id": "Mae Hong Son"
                },
                {
                    "$elt": "Maha Sarakham",
                    "$id": "Maha Sarakham"
                },
                {
                    "$elt": "Mukdahan",
                    "$id": "Mukdahan"
                },
                {
                    "$elt": "Nakhon Nayok",
                    "$id": "Nakhon Nayok"
                },
                {
                    "$elt": "Nakhon Pathom",
                    "$id": "Nakhon Pathom"
                },
                {
                    "$elt": "Nakhon Phanom",
                    "$id": "Nakhon Phanom"
                },
                {
                    "$elt": "Nakhon Ratchasima",
                    "$id": "Nakhon Ratchasima"
                },
                {
                    "$elt": "Nakhon Sawan",
                    "$id": "Nakhon Sawan"
                },
                {
                    "$elt": "Nakhon Si Thammarat",
                    "$id": "Nakhon Si Thammarat"
                },
                {
                    "$elt": "Nan",
                    "$id": "Nan"
                },
                {
                    "$elt": "Narathiwat",
                    "$id": "Narathiwat"
                },
                {
                    "$elt": "Nong Bua Lamphu",
                    "$id": "Nong Bua Lamphu"
                },
                {
                    "$elt": "Nong Khai",
                    "$id": "Nong Khai"
                },
                {
                    "$elt": "Nonthaburi",
                    "$id": "Nonthaburi"
                },
                {
                    "$elt": "Pathum Thani",
                    "$id": "Pathum Thani"
                },
                {
                    "$elt": "Pattani",
                    "$id": "Pattani"
                },
                {
                    "$elt": "Phang Nga",
                    "$id": "Phang Nga"
                },
                {
                    "$elt": "Phatthalung",
                    "$id": "Phatthalung"
                },
                {
                    "$elt": "Phatthaya",
                    "$id": "Phatthaya"
                },
                {
                    "$elt": "Phayao",
                    "$id": "Phayao"
                },
                {
                    "$elt": "Phetchabun",
                    "$id": "Phetchabun"
                },
                {
                    "$elt": "Phetchaburi",
                    "$id": "Phetchaburi"
                },
                {
                    "$elt": "Phichit",
                    "$id": "Phichit"
                },
                {
                    "$elt": "Phitsanulok",
                    "$id": "Phitsanulok"
                },
                {
                    "$elt": "Phra Nakhon Si Ayutthaya",
                    "$id": "Phra Nakhon Si Ayutthaya"
                },
                {
                    "$elt": "Phrae",
                    "$id": "Phrae"
                },
                {
                    "$elt": "Phuket",
                    "$id": "Phuket"
                },
                {
                    "$elt": "Prachin Buri",
                    "$id": "Prachin Buri"
                },
                {
                    "$elt": "Prachuap Khiri Khan",
                    "$id": "Prachuap Khiri Khan"
                },
                {
                    "$elt": "Ranong",
                    "$id": "Ranong"
                },
                {
                    "$elt": "Ratchaburi",
                    "$id": "Ratchaburi"
                },
                {
                    "$elt": "Rayong",
                    "$id": "Rayong"
                },
                {
                    "$elt": "Roi Et",
                    "$id": "Roi Et"
                },
                {
                    "$elt": "Sa Kaeo",
                    "$id": "Sa Kaeo"
                },
                {
                    "$elt": "Sakon Nakhon",
                    "$id": "Sakon Nakhon"
                },
                {
                    "$elt": "Samut Prakan",
                    "$id": "Samut Prakan"
                },
                {
                    "$elt": "Samut Sakhon",
                    "$id": "Samut Sakhon"
                },
                {
                    "$elt": "Samut Songkhram",
                    "$id": "Samut Songkhram"
                },
                {
                    "$elt": "Saraburi",
                    "$id": "Saraburi"
                },
                {
                    "$elt": "Satun",
                    "$id": "Satun"
                },
                {
                    "$elt": "Si Sa Ket",
                    "$id": "Si Sa Ket"
                },
                {
                    "$elt": "Sing Buri",
                    "$id": "Sing Buri"
                },
                {
                    "$elt": "Songkhla",
                    "$id": "Songkhla"
                },
                {
                    "$elt": "Sukhothai",
                    "$id": "Sukhothai"
                },
                {
                    "$elt": "Suphan Buri",
                    "$id": "Suphan Buri"
                },
                {
                    "$elt": "Surat Thani",
                    "$id": "Surat Thani"
                },
                {
                    "$elt": "Surin",
                    "$id": "Surin"
                },
                {
                    "$elt": "Tak",
                    "$id": "Tak"
                },
                {
                    "$elt": "Trang",
                    "$id": "Trang"
                },
                {
                    "$elt": "Trat",
                    "$id": "Trat"
                },
                {
                    "$elt": "Ubon Ratchathani",
                    "$id": "Ubon Ratchathani"
                },
                {
                    "$elt": "Udon Thani",
                    "$id": "Udon Thani"
                },
                {
                    "$elt": "Uthai Thani",
                    "$id": "Uthai Thani"
                },
                {
                    "$elt": "Uttaradit",
                    "$id": "Uttaradit"
                },
                {
                    "$elt": "Yala",
                    "$id": "Yala"
                },
                {
                    "$elt": "Yasothon",
                    "$id": "Yasothon"
                }
            ],
            "validationError": "Your postcode must be 5 digits."
        },
        "TJ": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "TM": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "TN": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "TO": {
            "cityLabel": "Locality",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)"
        },
        "TR": {
            "cityLabel": "City",
            "cityList": [
                {
                    "$elt": "İstanbul",
                    "$id": "İSTANBUL"
                },
                {
                    "$elt": "Ankara",
                    "$id": "ANKARA"
                },
                {
                    "$elt": "İzmir",
                    "$id": "İZMİR"
                },
                {
                    "$elt": "Adana",
                    "$id": "ADANA"
                },
                {
                    "$elt": "Adıyaman",
                    "$id": "ADIYAMAN"
                },
                {
                    "$elt": "Afyonkarahisar",
                    "$id": "AFYONKARAHİSAR"
                },
                {
                    "$elt": "Ağrı",
                    "$id": "AĞRI"
                },
                {
                    "$elt": "Aksaray",
                    "$id": "AKSARAY"
                },
                {
                    "$elt": "Amasya",
                    "$id": "AMASYA"
                },
                {
                    "$elt": "Antalya",
                    "$id": "ANTALYA"
                },
                {
                    "$elt": "Ardahan",
                    "$id": "ARDAHAN"
                },
                {
                    "$elt": "Artvin",
                    "$id": "ARTVİN"
                },
                {
                    "$elt": "Aydın",
                    "$id": "AYDIN"
                },
                {
                    "$elt": "Balıkesir",
                    "$id": "BALIKESİR"
                },
                {
                    "$elt": "Bartın",
                    "$id": "BARTIN"
                },
                {
                    "$elt": "Batman",
                    "$id": "BATMAN"
                },
                {
                    "$elt": "Bayburt",
                    "$id": "BAYBURT"
                },
                {
                    "$elt": "Bilecik",
                    "$id": "BİLECİK"
                },
                {
                    "$elt": "Bingöl",
                    "$id": "BİNGÖL"
                },
                {
                    "$elt": "Bitlis",
                    "$id": "BİTLİS"
                },
                {
                    "$elt": "Bolu",
                    "$id": "BOLU"
                },
                {
                    "$elt": "Burdur",
                    "$id": "BURDUR"
                },
                {
                    "$elt": "Bursa",
                    "$id": "BURSA"
                },
                {
                    "$elt": "Çanakkale",
                    "$id": "ÇANAKKALE"
                },
                {
                    "$elt": "Çankırı",
                    "$id": "ÇANKIRI"
                },
                {
                    "$elt": "Çorum",
                    "$id": "ÇORUM"
                },
                {
                    "$elt": "Denizli",
                    "$id": "DENİZLİ"
                },
                {
                    "$elt": "Diyarbakır",
                    "$id": "DİYARBAKIR"
                },
                {
                    "$elt": "Düzce",
                    "$id": "DÜZCE"
                },
                {
                    "$elt": "Edirne",
                    "$id": "EDİRNE"
                },
                {
                    "$elt": "Elazığ",
                    "$id": "ELAZIĞ"
                },
                {
                    "$elt": "Erzincan",
                    "$id": "ERZİNCAN"
                },
                {
                    "$elt": "Erzurum",
                    "$id": "ERZURUM"
                },
                {
                    "$elt": "Eskişehir",
                    "$id": "ESKİŞEHİR"
                },
                {
                    "$elt": "Gaziantep",
                    "$id": "GAZİANTEP"
                },
                {
                    "$elt": "Giresun",
                    "$id": "GİRESUN"
                },
                {
                    "$elt": "Gümüşhane",
                    "$id": "GÜMÜŞHANE"
                },
                {
                    "$elt": "Hakkâri",
                    "$id": "HAKKÂRİ"
                },
                {
                    "$elt": "Hatay",
                    "$id": "HATAY"
                },
                {
                    "$elt": "Iğdır",
                    "$id": "IĞDIR"
                },
                {
                    "$elt": "Isparta",
                    "$id": "ISPARTA"
                },
                {
                    "$elt": "Kahramanmaraş",
                    "$id": "KAHRAMANMARAŞ"
                },
                {
                    "$elt": "Karabük",
                    "$id": "KARABÜK"
                },
                {
                    "$elt": "Karaman",
                    "$id": "KARAMAN"
                },
                {
                    "$elt": "Kars",
                    "$id": "KARS"
                },
                {
                    "$elt": "Kastamonu",
                    "$id": "KASTAMONU"
                },
                {
                    "$elt": "Kayseri",
                    "$id": "KAYSERİ"
                },
                {
                    "$elt": "Kırıkkale",
                    "$id": "KIRIKKALE"
                },
                {
                    "$elt": "Kırklareli",
                    "$id": "KIRKLARELİ"
                },
                {
                    "$elt": "Kırşehir",
                    "$id": "KIRŞEHİR"
                },
                {
                    "$elt": "Kilis",
                    "$id": "KİLİS"
                },
                {
                    "$elt": "Kocaeli",
                    "$id": "KOCAELİ"
                },
                {
                    "$elt": "Konya",
                    "$id": "KONYA"
                },
                {
                    "$elt": "Kütahya",
                    "$id": "KÜTAHYA"
                },
                {
                    "$elt": "Malatya",
                    "$id": "MALATYA"
                },
                {
                    "$elt": "Manisa",
                    "$id": "MANİSA"
                },
                {
                    "$elt": "Mardin",
                    "$id": "MARDİN"
                },
                {
                    "$elt": "Mersin",
                    "$id": "MERSİN"
                },
                {
                    "$elt": "Muğla",
                    "$id": "MUĞLA"
                },
                {
                    "$elt": "Muş",
                    "$id": "MUŞ"
                },
                {
                    "$elt": "Nevşehir",
                    "$id": "NEVŞEHİR"
                },
                {
                    "$elt": "Niğde",
                    "$id": "NİĞDE"
                },
                {
                    "$elt": "Ordu",
                    "$id": "ORDU"
                },
                {
                    "$elt": "Osmaniye",
                    "$id": "OSMANİYE"
                },
                {
                    "$elt": "Rize",
                    "$id": "RİZE"
                },
                {
                    "$elt": "Sakarya",
                    "$id": "SAKARYA"
                },
                {
                    "$elt": "Samsun",
                    "$id": "SAMSUN"
                },
                {
                    "$elt": "Siirt",
                    "$id": "SİİRT"
                },
                {
                    "$elt": "Sinop",
                    "$id": "SİNOP"
                },
                {
                    "$elt": "Sivas",
                    "$id": "SİVAS"
                },
                {
                    "$elt": "Şanlıurfa",
                    "$id": "ŞANLIURFA"
                },
                {
                    "$elt": "Şırnak",
                    "$id": "ŞIRNAK"
                },
                {
                    "$elt": "Tekirdağ",
                    "$id": "TEKİRDAĞ"
                },
                {
                    "$elt": "Tokat",
                    "$id": "TOKAT"
                },
                {
                    "$elt": "Trabzon",
                    "$id": "TRABZON"
                },
                {
                    "$elt": "Tunceli",
                    "$id": "TUNCELİ"
                },
                {
                    "$elt": "Uşak",
                    "$id": "UŞAK"
                },
                {
                    "$elt": "Van",
                    "$id": "VAN"
                },
                {
                    "$elt": "Yalova",
                    "$id": "YALOVA"
                },
                {
                    "$elt": "Yozgat",
                    "$id": "YOZGAT"
                },
                {
                    "$elt": "Zonguldak",
                    "$id": "ZONGULDAK"
                }
            ],
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "selectLabel": "Select",
            "stateLabel": "District",
            "validationError": "Your postcode must be 5 digits."
        },
        "TT": {
            "cityLabel": "City",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 6 digits."
        },
        "TV": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "TW": {
            "cityLabel": "Town/City/County",
            "cityList": [
                {
                    "$elt": "Changhua County",
                    "$id": "Changhua County"
                },
                {
                    "$elt": "Chiayi City",
                    "$id": "Chiayi City"
                },
                {
                    "$elt": "Chiayi County",
                    "$id": "Chiayi County"
                },
                {
                    "$elt": "Hsinchu City",
                    "$id": "Hsinchu City"
                },
                {
                    "$elt": "Hsinchu County",
                    "$id": "Hsinchu County"
                },
                {
                    "$elt": "Hualien County",
                    "$id": "Hualien County"
                },
                {
                    "$elt": "Kaohsiung City",
                    "$id": "Kaohsiung City"
                },
                {
                    "$elt": "Keelung City",
                    "$id": "Keelung City"
                },
                {
                    "$elt": "Kinmen County",
                    "$id": "Kinmen County"
                },
                {
                    "$elt": "Lienchiang County",
                    "$id": "Lienchiang County"
                },
                {
                    "$elt": "Miaoli County",
                    "$id": "Miaoli County"
                },
                {
                    "$elt": "Nantou County",
                    "$id": "Nantou County"
                },
                {
                    "$elt": "New Taipei City",
                    "$id": "New Taipei City"
                },
                {
                    "$elt": "Penghu County",
                    "$id": "Penghu County"
                },
                {
                    "$elt": "Pingtung County",
                    "$id": "Pingtung County"
                },
                {
                    "$elt": "Taichung City",
                    "$id": "Taichung City"
                },
                {
                    "$elt": "Tainan City",
                    "$id": "Tainan City"
                },
                {
                    "$elt": "Taipei City",
                    "$id": "Taipei City"
                },
                {
                    "$elt": "Taitung County",
                    "$id": "Taitung County"
                },
                {
                    "$elt": "Taoyuan City",
                    "$id": "Taoyuan City"
                },
                {
                    "$elt": "Yilan County",
                    "$id": "Yilan County"
                },
                {
                    "$elt": "Yunlin County",
                    "$id": "Yunlin County"
                }
            ],
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "selectLabel": "Select",
            "stateLabel": "Township/District",
            "validationError": "Your postcode must be 3 or 5 digits long."
        },
        "TZ": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "UA": {
            "cityLabel": "Centre of population",
            "line1Label": "Address line 1",
            "line2Label": "Additional information",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Region",
            "stateList": [
                {
                    "$elt": "Cherkasy Oblast",
                    "$id": "CHERKASKA OBL"
                },
                {
                    "$elt": "Chernihiv Oblast",
                    "$id": "CHERNIHIVSKA OBL"
                },
                {
                    "$elt": "Chernivtsi Oblast",
                    "$id": "CHERNIVETSKA OBL"
                },
                {
                    "$elt": "Dnipropetrovsk Oblast",
                    "$id": "DNIPROPETROVSKA OBL"
                },
                {
                    "$elt": "Donetsk Oblast",
                    "$id": "DONETSKA OBL"
                },
                {
                    "$elt": "Ivano-Frankivsk Oblast",
                    "$id": "IVANO-FRANKIVSKA OBL"
                },
                {
                    "$elt": "Kharkiv Oblast",
                    "$id": "KHARKIVSKA OBL"
                },
                {
                    "$elt": "Kherson Oblast",
                    "$id": "KHERSONSKA OBL"
                },
                {
                    "$elt": "Khmelnytskyi Oblast",
                    "$id": "KHMELNYTSKA OBL"
                },
                {
                    "$elt": "Kirovohrad Oblast",
                    "$id": "KIROVOHRADSKA OBL"
                },
                {
                    "$elt": "Kyiv",
                    "$id": "KYIV"
                },
                {
                    "$elt": "Kyiv Oblast",
                    "$id": "KYIVSKA OBL"
                },
                {
                    "$elt": "Luhansk Oblast",
                    "$id": "LUHANSKA OBL"
                },
                {
                    "$elt": "Lviv Oblast",
                    "$id": "LVIVSKA OBL"
                },
                {
                    "$elt": "Mykolaiv Oblast",
                    "$id": "MYKOLAIVSKA OBL"
                },
                {
                    "$elt": "Odessa Oblast",
                    "$id": "ODESKA OBL"
                },
                {
                    "$elt": "Poltava Oblast",
                    "$id": "POLTAVSKA OBL"
                },
                {
                    "$elt": "Rivne Oblast",
                    "$id": "RIVNENSKA OBL"
                },
                {
                    "$elt": "Sumy Oblast",
                    "$id": "SUMSKA OBL"
                },
                {
                    "$elt": "Ternopil Oblast",
                    "$id": "TERNOPILSKA OBL"
                },
                {
                    "$elt": "Vinnytsia Oblast",
                    "$id": "VINNYTSKA OBL"
                },
                {
                    "$elt": "Volyn Oblast",
                    "$id": "VOLYNSKA OBL"
                },
                {
                    "$elt": "Zakarpattia Oblast",
                    "$id": "ZAKARPATSKA OBL"
                },
                {
                    "$elt": "Zaporizhia Oblast",
                    "$id": "ZAPORIZKA OBL"
                },
                {
                    "$elt": "Zhytomyr Oblast",
                    "$id": "ZHYTOMYRSKA OBL"
                }
            ],
            "validationError": "Your postcode must be 5 digits."
        },
        "UG": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "US": {
            "cityLabel": "City",
            "line1Label": "Street address",
            "line2Label": "Apartment, suite, building.",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "State",
            "stateList": [
                {
                    "$elt": "Alabama",
                    "$id": "AL"
                },
                {
                    "$elt": "Alaska",
                    "$id": "AK"
                },
                {
                    "$elt": "American Samoa",
                    "$id": "AS"
                },
                {
                    "$elt": "Arizona",
                    "$id": "AZ"
                },
                {
                    "$elt": "Arkansas",
                    "$id": "AR"
                },
                {
                    "$elt": "Armed Forces Americas",
                    "$id": "AA"
                },
                {
                    "$elt": "Armed Forces Europe",
                    "$id": "AE"
                },
                {
                    "$elt": "Armed Forces Pacific",
                    "$id": "AP"
                },
                {
                    "$elt": "California",
                    "$id": "CA"
                },
                {
                    "$elt": "Colorado",
                    "$id": "CO"
                },
                {
                    "$elt": "Connecticut",
                    "$id": "CT"
                },
                {
                    "$elt": "Delaware",
                    "$id": "DE"
                },
                {
                    "$elt": "District of Columbia",
                    "$id": "DC"
                },
                {
                    "$elt": "Federated States of Micronesia",
                    "$id": "FM"
                },
                {
                    "$elt": "Florida",
                    "$id": "FL"
                },
                {
                    "$elt": "Georgia",
                    "$id": "GA"
                },
                {
                    "$elt": "Guam",
                    "$id": "GU"
                },
                {
                    "$elt": "Hawaii",
                    "$id": "HI"
                },
                {
                    "$elt": "Idaho",
                    "$id": "ID"
                },
                {
                    "$elt": "Illinois",
                    "$id": "IL"
                },
                {
                    "$elt": "Indiana",
                    "$id": "IN"
                },
                {
                    "$elt": "Iowa",
                    "$id": "IA"
                },
                {
                    "$elt": "Kansas",
                    "$id": "KS"
                },
                {
                    "$elt": "Kentucky",
                    "$id": "KY"
                },
                {
                    "$elt": "Louisiana",
                    "$id": "LA"
                },
                {
                    "$elt": "Maine",
                    "$id": "ME"
                },
                {
                    "$elt": "Marshall Islands",
                    "$id": "MH"
                },
                {
                    "$elt": "Maryland",
                    "$id": "MD"
                },
                {
                    "$elt": "Massachusetts",
                    "$id": "MA"
                },
                {
                    "$elt": "Michigan",
                    "$id": "MI"
                },
                {
                    "$elt": "Minnesota",
                    "$id": "MN"
                },
                {
                    "$elt": "Mississippi",
                    "$id": "MS"
                },
                {
                    "$elt": "Missouri",
                    "$id": "MO"
                },
                {
                    "$elt": "Montana",
                    "$id": "MT"
                },
                {
                    "$elt": "Nebraska",
                    "$id": "NE"
                },
                {
                    "$elt": "Nevada",
                    "$id": "NV"
                },
                {
                    "$elt": "New Hampshire",
                    "$id": "NH"
                },
                {
                    "$elt": "New Jersey",
                    "$id": "NJ"
                },
                {
                    "$elt": "New Mexico",
                    "$id": "NM"
                },
                {
                    "$elt": "New York",
                    "$id": "NY"
                },
                {
                    "$elt": "North Carolina",
                    "$id": "NC"
                },
                {
                    "$elt": "North Dakota",
                    "$id": "ND"
                },
                {
                    "$elt": "Northern Mariana Islands",
                    "$id": "MP"
                },
                {
                    "$elt": "Ohio",
                    "$id": "OH"
                },
                {
                    "$elt": "Oklahoma",
                    "$id": "OK"
                },
                {
                    "$elt": "Oregon",
                    "$id": "OR"
                },
                {
                    "$elt": "Palau",
                    "$id": "PW"
                },
                {
                    "$elt": "Pennsylvania",
                    "$id": "PA"
                },
                {
                    "$elt": "Puerto Rico",
                    "$id": "PR"
                },
                {
                    "$elt": "Rhode Island",
                    "$id": "RI"
                },
                {
                    "$elt": "South Carolina",
                    "$id": "SC"
                },
                {
                    "$elt": "South Dakota",
                    "$id": "SD"
                },
                {
                    "$elt": "Tennessee",
                    "$id": "TN"
                },
                {
                    "$elt": "Texas",
                    "$id": "TX"
                },
                {
                    "$elt": "Utah",
                    "$id": "UT"
                },
                {
                    "$elt": "Vermont",
                    "$id": "VT"
                },
                {
                    "$elt": "Virgin Islands",
                    "$id": "VI"
                },
                {
                    "$elt": "Virginia",
                    "$id": "VA"
                },
                {
                    "$elt": "Washington",
                    "$id": "WA"
                },
                {
                    "$elt": "West Virginia",
                    "$id": "WV"
                },
                {
                    "$elt": "Wisconsin",
                    "$id": "WI"
                },
                {
                    "$elt": "Wyoming",
                    "$id": "WY"
                }
            ],
            "validationError": "Your postcode must be 5 or 9 digits."
        },
        "UY": {
            "cityLabel": "Town/City/Locality",
            "line1Label": "Street name and house number",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Department",
            "stateList": [
                {
                    "$elt": "Artigas",
                    "$id": "Artigas"
                },
                {
                    "$elt": "Canelones",
                    "$id": "Canelones"
                },
                {
                    "$elt": "Cerro Largo",
                    "$id": "Cerro Largo"
                },
                {
                    "$elt": "Colonia",
                    "$id": "Colonia"
                },
                {
                    "$elt": "Durazno",
                    "$id": "Durazno"
                },
                {
                    "$elt": "Flores",
                    "$id": "Flores"
                },
                {
                    "$elt": "Florida",
                    "$id": "Florida"
                },
                {
                    "$elt": "Lavalleja",
                    "$id": "Lavalleja"
                },
                {
                    "$elt": "Maldonado",
                    "$id": "Maldonado"
                },
                {
                    "$elt": "Montevideo",
                    "$id": "Montevideo"
                },
                {
                    "$elt": "Paysandú",
                    "$id": "Paysandú"
                },
                {
                    "$elt": "Río Negro",
                    "$id": "Río Negro"
                },
                {
                    "$elt": "Rivera",
                    "$id": "Rivera"
                },
                {
                    "$elt": "Rocha",
                    "$id": "Rocha"
                },
                {
                    "$elt": "Salto",
                    "$id": "Salto"
                },
                {
                    "$elt": "San José",
                    "$id": "San José"
                },
                {
                    "$elt": "Soriano",
                    "$id": "Soriano"
                },
                {
                    "$elt": "Tacuarembó",
                    "$id": "Tacuarembó"
                },
                {
                    "$elt": "Treinta y Tres",
                    "$id": "Treinta y Tres"
                }
            ],
            "validationError": "Your postcode must be 5 digits long and cannot start with 0."
        },
        "VA": {
            "cityLabel": "City",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 00120."
        },
        "VC": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "VE": {
            "cityLabel": "City",
            "line1Label": "Street name and house number",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "County/Capital District/Federal Dependencies",
            "stateList": [
                {
                    "$elt": "Amazonas",
                    "$id": "ESTADO AMAZONAS"
                },
                {
                    "$elt": "Anzoátegui",
                    "$id": "ESTADO ANZOÁTEGUI"
                },
                {
                    "$elt": "Apure",
                    "$id": "ESTADO APURE"
                },
                {
                    "$elt": "Aragua",
                    "$id": "ESTADO ARAGUA"
                },
                {
                    "$elt": "Barinas",
                    "$id": "ESTADO BARINAS"
                },
                {
                    "$elt": "Bolívar",
                    "$id": "ESTADO BOLÍVAR"
                },
                {
                    "$elt": "Carabobo",
                    "$id": "ESTADO CARABOBO"
                },
                {
                    "$elt": "Cojedes",
                    "$id": "ESTADO COJEDES"
                },
                {
                    "$elt": "Delta Amacuro",
                    "$id": "ESTADO DELTA AMACURO"
                },
                {
                    "$elt": "Dependencias Federales",
                    "$id": "DEPENDENCIAS FEDERALES"
                },
                {
                    "$elt": "Distrito Capital",
                    "$id": "DISTRITO CAPITAL"
                },
                {
                    "$elt": "Falcón",
                    "$id": "ESTADO FALCÓN"
                },
                {
                    "$elt": "Guárico",
                    "$id": "ESTADO GUÁRICO"
                },
                {
                    "$elt": "Lara",
                    "$id": "ESTADO LARA"
                },
                {
                    "$elt": "Mérida",
                    "$id": "ESTADO MÉRIDA"
                },
                {
                    "$elt": "Miranda",
                    "$id": "ESTADO MIRANDA"
                },
                {
                    "$elt": "Monagas",
                    "$id": "ESTADO MONAGAS"
                },
                {
                    "$elt": "Nueva Esparta",
                    "$id": "ESTADO NUEVA ESPARTA"
                },
                {
                    "$elt": "Portuguesa",
                    "$id": "ESTADO PORTUGUESA"
                },
                {
                    "$elt": "Sucre",
                    "$id": "ESTADO SUCRE"
                },
                {
                    "$elt": "Táchira",
                    "$id": "ESTADO TÁCHIRA"
                },
                {
                    "$elt": "Trujillo",
                    "$id": "ESTADO TRUJILLO"
                },
                {
                    "$elt": "Vargas",
                    "$id": "ESTADO VARGAS"
                },
                {
                    "$elt": "Yaracuy",
                    "$id": "ESTADO YARACUY"
                },
                {
                    "$elt": "Zulia",
                    "$id": "ESTADO ZULIA"
                }
            ],
            "validationError": "Your postcode must be 4 digits long and can be followed by a dash and a single letter."
        },
        "VG": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "VN": {
            "cityLabel": "Town/City",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Larger city/Province",
            "stateList": [
                {
                    "$elt": "An Giang",
                    "$id": "An Giang"
                },
                {
                    "$elt": "Bà Rịa–Vũng Tàu",
                    "$id": "Bà Rịa–Vũng Tàu"
                },
                {
                    "$elt": "Bắc Giang",
                    "$id": "Bắc Giang"
                },
                {
                    "$elt": "Bắc Kạn",
                    "$id": "Bắc Kạn"
                },
                {
                    "$elt": "Bạc Liêu",
                    "$id": "Bạc Liêu"
                },
                {
                    "$elt": "Bắc Ninh",
                    "$id": "Bắc Ninh"
                },
                {
                    "$elt": "Bến Tre",
                    "$id": "Bến Tre"
                },
                {
                    "$elt": "Bình Định",
                    "$id": "Bình Định"
                },
                {
                    "$elt": "Bình Dương",
                    "$id": "Bình Dương"
                },
                {
                    "$elt": "Bình Phước",
                    "$id": "Bình Phước"
                },
                {
                    "$elt": "Bình Thuận",
                    "$id": "Bình Thuận"
                },
                {
                    "$elt": "Cà Mau",
                    "$id": "Cà Mau"
                },
                {
                    "$elt": "Cần Thơ",
                    "$id": "Cần Thơ"
                },
                {
                    "$elt": "Cao Bằng",
                    "$id": "Cao Bằng"
                },
                {
                    "$elt": "Đà Nẵng",
                    "$id": "Đà Nẵng"
                },
                {
                    "$elt": "Đắk Lắk",
                    "$id": "Đắk Lắk"
                },
                {
                    "$elt": "Đắk Nông",
                    "$id": "Đắk Nông"
                },
                {
                    "$elt": "Điện Biên",
                    "$id": "Điện Biên"
                },
                {
                    "$elt": "Đồng Nai",
                    "$id": "Đồng Nai"
                },
                {
                    "$elt": "Đồng Tháp",
                    "$id": "Đồng Tháp"
                },
                {
                    "$elt": "Gia Lai",
                    "$id": "Gia Lai"
                },
                {
                    "$elt": "Hà Giang",
                    "$id": "Hà Giang"
                },
                {
                    "$elt": "Hà Nam",
                    "$id": "Hà Nam"
                },
                {
                    "$elt": "Hà Nội",
                    "$id": "Hà Nội"
                },
                {
                    "$elt": "Hà Tĩnh",
                    "$id": "Hà Tĩnh"
                },
                {
                    "$elt": "Hải Dương",
                    "$id": "Hải Dương"
                },
                {
                    "$elt": "Hải Phòng",
                    "$id": "Hải Phòng"
                },
                {
                    "$elt": "Hậu Giang",
                    "$id": "Hậu Giang"
                },
                {
                    "$elt": "Hồ Chí Minh",
                    "$id": "Hồ Chí Minh"
                },
                {
                    "$elt": "Hòa Bình",
                    "$id": "Hòa Bình"
                },
                {
                    "$elt": "Hưng Yên",
                    "$id": "Hưng Yên"
                },
                {
                    "$elt": "Khánh Hòa",
                    "$id": "Khánh Hòa"
                },
                {
                    "$elt": "Kiên Giang",
                    "$id": "Kiên Giang"
                },
                {
                    "$elt": "Kon Tum",
                    "$id": "Kon Tum"
                },
                {
                    "$elt": "Lai Châu",
                    "$id": "Lai Châu"
                },
                {
                    "$elt": "Lâm Đồng",
                    "$id": "Lâm Đồng"
                },
                {
                    "$elt": "Lạng Sơn",
                    "$id": "Lạng Sơn"
                },
                {
                    "$elt": "Lào Cai",
                    "$id": "Lào Cai"
                },
                {
                    "$elt": "Long An",
                    "$id": "Long An"
                },
                {
                    "$elt": "Nam Định",
                    "$id": "Nam Định"
                },
                {
                    "$elt": "Nghệ An",
                    "$id": "Nghệ An"
                },
                {
                    "$elt": "Ninh Bình",
                    "$id": "Ninh Bình"
                },
                {
                    "$elt": "Ninh Thuận",
                    "$id": "Ninh Thuận"
                },
                {
                    "$elt": "Phú Thọ",
                    "$id": "Phú Thọ"
                },
                {
                    "$elt": "Phú Yên",
                    "$id": "Phú Yên"
                },
                {
                    "$elt": "Quảng Bình",
                    "$id": "Quảng Bình"
                },
                {
                    "$elt": "Quảng Nam",
                    "$id": "Quảng Nam"
                },
                {
                    "$elt": "Quảng Ngãi",
                    "$id": "Quảng Ngãi"
                },
                {
                    "$elt": "Quảng Ninh",
                    "$id": "Quảng Ninh"
                },
                {
                    "$elt": "Quảng Trị",
                    "$id": "Quảng Trị"
                },
                {
                    "$elt": "Sóc Trăng",
                    "$id": "Sóc Trăng"
                },
                {
                    "$elt": "Sơn La",
                    "$id": "Sơn La"
                },
                {
                    "$elt": "Tây Ninh",
                    "$id": "Tây Ninh"
                },
                {
                    "$elt": "Thái Bình",
                    "$id": "Thái Bình"
                },
                {
                    "$elt": "Thái Nguyên",
                    "$id": "Thái Nguyên"
                },
                {
                    "$elt": "Thanh Hóa",
                    "$id": "Thanh Hóa"
                },
                {
                    "$elt": "Thừa Thiên–Huế",
                    "$id": "Thừa Thiên–Huế"
                },
                {
                    "$elt": "Tiền Giang",
                    "$id": "Tiền Giang"
                },
                {
                    "$elt": "Trà Vinh",
                    "$id": "Trà Vinh"
                },
                {
                    "$elt": "Tuyên Quang",
                    "$id": "Tuyên Quang"
                },
                {
                    "$elt": "Vĩnh Long",
                    "$id": "Vĩnh Long"
                },
                {
                    "$elt": "Vĩnh Phúc",
                    "$id": "Vĩnh Phúc"
                },
                {
                    "$elt": "Yên Bái",
                    "$id": "Yên Bái"
                }
            ],
            "validationError": "Your postcode must be 6 digits."
        },
        "VU": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "WF": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "WS": {
            "cityLabel": "Locality",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 4 digits."
        },
        "YE": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "YT": {
            "cityLabel": "City",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "validationError": "Your postcode must be 5 digits long and begin with 976."
        },
        "ZA": {
            "cityLabel": "Town/City",
            "line1Label": "Address line 1",
            "line2Label": "Suburb",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "District/Province",
            "validationError": "Your postcode must be 4 digits."
        },
        "ZM": {
            "cityLabel": "City/Town/Village",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)",
            "postcodeLabel": "Postcode",
            "stateLabel": "Province/Territory/Region"
        },
        "ZW": {
            "cityLabel": "Town/City",
            "line1Label": "Address line 1",
            "line2Label": "Address line 2",
            "optionalLabel": "(optional)"
        }
    },

    "territory": [
        {
            "code": "AL",
            "name": "Albania",
            "internationalDialingCode": "355"
        },
        {
            "code": "DZ",
            "name": "Algeria",
            "internationalDialingCode": "213"
        },
        {
            "code": "AD",
            "name": "Andorra",
            "internationalDialingCode": "376"
        },
        {
            "code": "AO",
            "name": "Angola",
            "internationalDialingCode": "244"
        },
        {
            "code": "AI",
            "name": "Anguilla",
            "internationalDialingCode": "1"
        },
        {
            "code": "AG",
            "name": "Antigua & Barbuda",
            "internationalDialingCode": "1"
        },
        {
            "code": "AR",
            "name": "Argentina",
            "internationalDialingCode": "54"
        },
        {
            "code": "AM",
            "name": "Armenia",
            "internationalDialingCode": "374"
        },
        {
            "code": "AW",
            "name": "Aruba",
            "internationalDialingCode": "297"
        },
        {
            "code": "AU",
            "name": "Australia",
            "internationalDialingCode": "61"
        },
        {
            "code": "AT",
            "name": "Austria",
            "internationalDialingCode": "43"
        },
        {
            "code": "AZ",
            "name": "Azerbaijan",
            "internationalDialingCode": "994"
        },
        {
            "code": "BS",
            "name": "Bahamas",
            "internationalDialingCode": "1"
        },
        {
            "code": "BH",
            "name": "Bahrain",
            "internationalDialingCode": "973"
        },
        {
            "code": "BB",
            "name": "Barbados",
            "internationalDialingCode": "1"
        },
        {
            "code": "BY",
            "name": "Belarus",
            "internationalDialingCode": "375"
        },
        {
            "code": "BE",
            "name": "Belgium",
            "internationalDialingCode": "32"
        },
        {
            "code": "BZ",
            "name": "Belize",
            "internationalDialingCode": "501"
        },
        {
            "code": "BJ",
            "name": "Benin",
            "internationalDialingCode": "229"
        },
        {
            "code": "BM",
            "name": "Bermuda",
            "internationalDialingCode": "1"
        },
        {
            "code": "BT",
            "name": "Bhutan",
            "internationalDialingCode": "975"
        },
        {
            "code": "BO",
            "name": "Bolivia",
            "internationalDialingCode": "591"
        },
        {
            "code": "BA",
            "name": "Bosnia & Herzegovina",
            "internationalDialingCode": "387"
        },
        {
            "code": "BW",
            "name": "Botswana",
            "internationalDialingCode": "267"
        },
        {
            "code": "BR",
            "name": "Brazil",
            "internationalDialingCode": "55"
        },
        {
            "code": "VG",
            "name": "British Virgin Islands",
            "internationalDialingCode": "1"
        },
        {
            "code": "BN",
            "name": "Brunei",
            "internationalDialingCode": "673"
        },
        {
            "code": "BG",
            "name": "Bulgaria",
            "internationalDialingCode": "359"
        },
        {
            "code": "BF",
            "name": "Burkina Faso",
            "internationalDialingCode": "226"
        },
        {
            "code": "BI",
            "name": "Burundi",
            "internationalDialingCode": "257"
        },
        {
            "code": "KH",
            "name": "Cambodia",
            "internationalDialingCode": "855"
        },
        {
            "code": "CM",
            "name": "Cameroon",
            "internationalDialingCode": "237"
        },
        {
            "code": "CA",
            "name": "Canada",
            "internationalDialingCode": "1"
        },
        {
            "code": "CV",
            "name": "Cape Verde",
            "internationalDialingCode": "238"
        },
        {
            "code": "KY",
            "name": "Cayman Islands",
            "internationalDialingCode": "1"
        },
        {
            "code": "TD",
            "name": "Chad",
            "internationalDialingCode": "235"
        },
        {
            "code": "CL",
            "name": "Chile",
            "internationalDialingCode": "56"
        },
        {
            "code": "CN",
            "name": "China",
            "internationalDialingCode": "86"
        },
        {
            "code": "CO",
            "name": "Colombia",
            "internationalDialingCode": "57"
        },
        {
            "code": "KM",
            "name": "Comoros",
            "internationalDialingCode": "269"
        },
        {
            "code": "CG",
            "name": "Congo - Brazzaville",
            "internationalDialingCode": "242"
        },
        {
            "code": "CD",
            "name": "Congo - Kinshasa",
            "internationalDialingCode": "243"
        },
        {
            "code": "CK",
            "name": "Cook Islands",
            "internationalDialingCode": "682"
        },
        {
            "code": "CR",
            "name": "Costa Rica",
            "internationalDialingCode": "506"
        },
        {
            "code": "CI",
            "name": "Côte d’Ivoire",
            "internationalDialingCode": "225"
        },
        {
            "code": "HR",
            "name": "Croatia",
            "internationalDialingCode": "385"
        },
        {
            "code": "CY",
            "name": "Cyprus",
            "internationalDialingCode": "357"
        },
        {
            "code": "CZ",
            "name": "Czech Republic",
            "internationalDialingCode": "420"
        },
        {
            "code": "DK",
            "name": "Denmark",
            "internationalDialingCode": "45"
        },
        {
            "code": "DJ",
            "name": "Djibouti",
            "internationalDialingCode": "253"
        },
        {
            "code": "DM",
            "name": "Dominica",
            "internationalDialingCode": "1"
        },
        {
            "code": "DO",
            "name": "Dominican Republic",
            "internationalDialingCode": "1"
        },
        {
            "code": "EC",
            "name": "Ecuador",
            "internationalDialingCode": "593"
        },
        {
            "code": "EG",
            "name": "Egypt",
            "internationalDialingCode": "20"
        },
        {
            "code": "SV",
            "name": "El Salvador",
            "internationalDialingCode": "503"
        },
        {
            "code": "ER",
            "name": "Eritrea",
            "internationalDialingCode": "291"
        },
        {
            "code": "EE",
            "name": "Estonia",
            "internationalDialingCode": "372"
        },
        {
            "code": "ET",
            "name": "Ethiopia",
            "internationalDialingCode": "251"
        },
        {
            "code": "FK",
            "name": "Falkland Islands",
            "internationalDialingCode": "500"
        },
        {
            "code": "FO",
            "name": "Faroe Islands",
            "internationalDialingCode": "298"
        },
        {
            "code": "FJ",
            "name": "Fiji",
            "internationalDialingCode": "679"
        },
        {
            "code": "FI",
            "name": "Finland",
            "internationalDialingCode": "358"
        },
        {
            "code": "FR",
            "name": "France",
            "internationalDialingCode": "33"
        },
        {
            "code": "GF",
            "name": "French Guiana",
            "internationalDialingCode": "594"
        },
        {
            "code": "PF",
            "name": "French Polynesia",
            "internationalDialingCode": "689"
        },
        {
            "code": "GA",
            "name": "Gabon",
            "internationalDialingCode": "241"
        },
        {
            "code": "GM",
            "name": "Gambia",
            "internationalDialingCode": "220"
        },
        {
            "code": "GE",
            "name": "Georgia",
            "internationalDialingCode": "995"
        },
        {
            "code": "DE",
            "name": "Germany",
            "internationalDialingCode": "49"
        },
        {
            "code": "GI",
            "name": "Gibraltar",
            "internationalDialingCode": "350"
        },
        {
            "code": "GR",
            "name": "Greece",
            "internationalDialingCode": "30"
        },
        {
            "code": "GL",
            "name": "Greenland",
            "internationalDialingCode": "299"
        },
        {
            "code": "GD",
            "name": "Grenada",
            "internationalDialingCode": "1"
        },
        {
            "code": "GP",
            "name": "Guadeloupe",
            "internationalDialingCode": "590"
        },
        {
            "code": "GT",
            "name": "Guatemala",
            "internationalDialingCode": "502"
        },
        {
            "code": "GN",
            "name": "Guinea",
            "internationalDialingCode": "224"
        },
        {
            "code": "GW",
            "name": "Guinea-Bissau",
            "internationalDialingCode": "245"
        },
        {
            "code": "GY",
            "name": "Guyana",
            "internationalDialingCode": "592"
        },
        {
            "code": "HN",
            "name": "Honduras",
            "internationalDialingCode": "504"
        },
        {
            "code": "HK",
            "name": "Hong Kong SAR China",
            "internationalDialingCode": "852"
        },
        {
            "code": "HU",
            "name": "Hungary",
            "internationalDialingCode": "36"
        },
        {
            "code": "IS",
            "name": "Iceland",
            "internationalDialingCode": "354"
        },
        {
            "code": "IN",
            "name": "India",
            "internationalDialingCode": "91"
        },
        {
            "code": "ID",
            "name": "Indonesia",
            "internationalDialingCode": "62"
        },
        {
            "code": "IE",
            "name": "Ireland",
            "internationalDialingCode": "353"
        },
        {
            "code": "IL",
            "name": "Israel",
            "internationalDialingCode": "972"
        },
        {
            "code": "IT",
            "name": "Italy",
            "internationalDialingCode": "39"
        },
        {
            "code": "JM",
            "name": "Jamaica",
            "internationalDialingCode": "1"
        },
        {
            "code": "JP",
            "name": "Japan",
            "internationalDialingCode": "81"
        },
        {
            "code": "JO",
            "name": "Jordan",
            "internationalDialingCode": "962"
        },
        {
            "code": "KZ",
            "name": "Kazakhstan",
            "internationalDialingCode": "7"
        },
        {
            "code": "KE",
            "name": "Kenya",
            "internationalDialingCode": "254"
        },
        {
            "code": "KI",
            "name": "Kiribati",
            "internationalDialingCode": "686"
        },
        {
            "code": "KW",
            "name": "Kuwait",
            "internationalDialingCode": "965"
        },
        {
            "code": "KG",
            "name": "Kyrgyzstan",
            "internationalDialingCode": "996"
        },
        {
            "code": "LA",
            "name": "Laos",
            "internationalDialingCode": "856"
        },
        {
            "code": "LV",
            "name": "Latvia",
            "internationalDialingCode": "371"
        },
        {
            "code": "LS",
            "name": "Lesotho",
            "internationalDialingCode": "266"
        },
        {
            "code": "LI",
            "name": "Liechtenstein",
            "internationalDialingCode": "423"
        },
        {
            "code": "LT",
            "name": "Lithuania",
            "internationalDialingCode": "370"
        },
        {
            "code": "LU",
            "name": "Luxembourg",
            "internationalDialingCode": "352"
        },
        {
            "code": "MK",
            "name": "Macedonia",
            "internationalDialingCode": "389"
        },
        {
            "code": "MG",
            "name": "Madagascar",
            "internationalDialingCode": "261"
        },
        {
            "code": "MW",
            "name": "Malawi",
            "internationalDialingCode": "265"
        },
        {
            "code": "MY",
            "name": "Malaysia",
            "internationalDialingCode": "60"
        },
        {
            "code": "MV",
            "name": "Maldives",
            "internationalDialingCode": "960"
        },
        {
            "code": "ML",
            "name": "Mali",
            "internationalDialingCode": "223"
        },
        {
            "code": "MT",
            "name": "Malta",
            "internationalDialingCode": "356"
        },
        {
            "code": "MH",
            "name": "Marshall Islands",
            "internationalDialingCode": "692"
        },
        {
            "code": "MQ",
            "name": "Martinique",
            "internationalDialingCode": "596"
        },
        {
            "code": "MR",
            "name": "Mauritania",
            "internationalDialingCode": "222"
        },
        {
            "code": "MU",
            "name": "Mauritius",
            "internationalDialingCode": "230"
        },
        {
            "code": "YT",
            "name": "Mayotte",
            "internationalDialingCode": "262"
        },
        {
            "code": "MX",
            "name": "Mexico",
            "internationalDialingCode": "52"
        },
        {
            "code": "FM",
            "name": "Micronesia",
            "internationalDialingCode": "691"
        },
        {
            "code": "MD",
            "name": "Moldova",
            "internationalDialingCode": "373"
        },
        {
            "code": "MC",
            "name": "Monaco",
            "internationalDialingCode": "377"
        },
        {
            "code": "MN",
            "name": "Mongolia",
            "internationalDialingCode": "976"
        },
        {
            "code": "ME",
            "name": "Montenegro",
            "internationalDialingCode": "382"
        },
        {
            "code": "MS",
            "name": "Montserrat",
            "internationalDialingCode": "1"
        },
        {
            "code": "MA",
            "name": "Morocco",
            "internationalDialingCode": "212"
        },
        {
            "code": "MZ",
            "name": "Mozambique",
            "internationalDialingCode": "258"
        },
        {
            "code": "NA",
            "name": "Namibia",
            "internationalDialingCode": "264"
        },
        {
            "code": "NR",
            "name": "Nauru",
            "internationalDialingCode": "674"
        },
        {
            "code": "NP",
            "name": "Nepal",
            "internationalDialingCode": "977"
        },
        {
            "code": "NL",
            "name": "Netherlands",
            "internationalDialingCode": "31"
        },
        {
            "code": "AN",
            "name": "Netherlands Antilles",
            "internationalDialingCode": "599"
        },
        {
            "code": "NC",
            "name": "New Caledonia",
            "internationalDialingCode": "687"
        },
        {
            "code": "NZ",
            "name": "New Zealand",
            "internationalDialingCode": "64"
        },
        {
            "code": "NI",
            "name": "Nicaragua",
            "internationalDialingCode": "505"
        },
        {
            "code": "NE",
            "name": "Niger",
            "internationalDialingCode": "227"
        },
        {
            "code": "NG",
            "name": "Nigeria",
            "internationalDialingCode": "234"
        },
        {
            "code": "NU",
            "name": "Niue",
            "internationalDialingCode": "683"
        },
        {
            "code": "NF",
            "name": "Norfolk Island",
            "internationalDialingCode": "672"
        },
        {
            "code": "NO",
            "name": "Norway",
            "internationalDialingCode": "47"
        },
        {
            "code": "OM",
            "name": "Oman",
            "internationalDialingCode": "968"
        },
        {
            "code": "PW",
            "name": "Palau",
            "internationalDialingCode": "680"
        },
        {
            "code": "PA",
            "name": "Panama",
            "internationalDialingCode": "507"
        },
        {
            "code": "PG",
            "name": "Papua New Guinea",
            "internationalDialingCode": "675"
        },
        {
            "code": "PY",
            "name": "Paraguay",
            "internationalDialingCode": "595"
        },
        {
            "code": "PE",
            "name": "Peru",
            "internationalDialingCode": "51"
        },
        {
            "code": "PH",
            "name": "Philippines",
            "internationalDialingCode": "63"
        },
        {
            "code": "PN",
            "name": "Pitcairn Islands",
            "internationalDialingCode": "64"
        },
        {
            "code": "PL",
            "name": "Poland",
            "internationalDialingCode": "48"
        },
        {
            "code": "PT",
            "name": "Portugal",
            "internationalDialingCode": "351"
        },
        {
            "code": "QA",
            "name": "Qatar",
            "internationalDialingCode": "974"
        },
        {
            "code": "RE",
            "name": "Réunion",
            "internationalDialingCode": "262"
        },
        {
            "code": "RO",
            "name": "Romania",
            "internationalDialingCode": "40"
        },
        {
            "code": "RU",
            "name": "Russia",
            "internationalDialingCode": "7"
        },
        {
            "code": "RW",
            "name": "Rwanda",
            "internationalDialingCode": "250"
        },
        {
            "code": "WS",
            "name": "Samoa",
            "internationalDialingCode": "685"
        },
        {
            "code": "SM",
            "name": "San Marino",
            "internationalDialingCode": "378"
        },
        {
            "code": "ST",
            "name": "São Tomé & Príncipe",
            "internationalDialingCode": "239"
        },
        {
            "code": "SA",
            "name": "Saudi Arabia",
            "internationalDialingCode": "966"
        },
        {
            "code": "SN",
            "name": "Senegal",
            "internationalDialingCode": "221"
        },
        {
            "code": "RS",
            "name": "Serbia",
            "internationalDialingCode": "381"
        },
        {
            "code": "SC",
            "name": "Seychelles",
            "internationalDialingCode": "248"
        },
        {
            "code": "SL",
            "name": "Sierra Leone",
            "internationalDialingCode": "232"
        },
        {
            "code": "SG",
            "name": "Singapore",
            "internationalDialingCode": "65"
        },
        {
            "code": "SK",
            "name": "Slovakia",
            "internationalDialingCode": "421"
        },
        {
            "code": "SI",
            "name": "Slovenia",
            "internationalDialingCode": "386"
        },
        {
            "code": "SB",
            "name": "Solomon Islands",
            "internationalDialingCode": "677"
        },
        {
            "code": "SO",
            "name": "Somalia",
            "internationalDialingCode": "252"
        },
        {
            "code": "ZA",
            "name": "South Africa",
            "internationalDialingCode": "27"
        },
        {
            "code": "KR",
            "name": "South Korea",
            "internationalDialingCode": "82"
        },
        {
            "code": "ES",
            "name": "Spain",
            "internationalDialingCode": "34"
        },
        {
            "code": "LK",
            "name": "Sri Lanka",
            "internationalDialingCode": "94"
        },
        {
            "code": "SH",
            "name": "St. Helena",
            "internationalDialingCode": "290"
        },
        {
            "code": "KN",
            "name": "St. Kitts & Nevis",
            "internationalDialingCode": "1"
        },
        {
            "code": "LC",
            "name": "St. Lucia",
            "internationalDialingCode": "1"
        },
        {
            "code": "PM",
            "name": "St. Pierre & Miquelon",
            "internationalDialingCode": "508"
        },
        {
            "code": "VC",
            "name": "St. Vincent & Grenadines",
            "internationalDialingCode": "1"
        },
        {
            "code": "SR",
            "name": "Suriname",
            "internationalDialingCode": "597"
        },
        {
            "code": "SJ",
            "name": "Svalbard & Jan Mayen",
            "internationalDialingCode": "47"
        },
        {
            "code": "SZ",
            "name": "Swaziland",
            "internationalDialingCode": "268"
        },
        {
            "code": "SE",
            "name": "Sweden",
            "internationalDialingCode": "46"
        },
        {
            "code": "CH",
            "name": "Switzerland",
            "internationalDialingCode": "41"
        },
        {
            "code": "TW",
            "name": "Taiwan",
            "internationalDialingCode": "886"
        },
        {
            "code": "TJ",
            "name": "Tajikistan",
            "internationalDialingCode": "992"
        },
        {
            "code": "TZ",
            "name": "Tanzania",
            "internationalDialingCode": "255"
        },
        {
            "code": "TH",
            "name": "Thailand",
            "internationalDialingCode": "66"
        },
        {
            "code": "TG",
            "name": "Togo",
            "internationalDialingCode": "228"
        },
        {
            "code": "TO",
            "name": "Tonga",
            "internationalDialingCode": "676"
        },
        {
            "code": "TT",
            "name": "Trinidad & Tobago",
            "internationalDialingCode": "1"
        },
        {
            "code": "TN",
            "name": "Tunisia",
            "internationalDialingCode": "216"
        },
        {
            "code": "TR",
            "name": "Turkey",
            "internationalDialingCode": "90"
        },
        {
            "code": "TM",
            "name": "Turkmenistan",
            "internationalDialingCode": "993"
        },
        {
            "code": "TC",
            "name": "Turks & Caicos Islands",
            "internationalDialingCode": "1"
        },
        {
            "code": "TV",
            "name": "Tuvalu",
            "internationalDialingCode": "688"
        },
        {
            "code": "UG",
            "name": "Uganda",
            "internationalDialingCode": "256"
        },
        {
            "code": "UA",
            "name": "Ukraine",
            "internationalDialingCode": "380"
        },
        {
            "code": "AE",
            "name": "United Arab Emirates",
            "internationalDialingCode": "971"
        },
        {
            "code": "GB",
            "name": "United Kingdom",
            "internationalDialingCode": "44"
        },
        {
            "code": "US",
            "name": "United States",
            "internationalDialingCode": "1"
        },
        {
            "code": "UY",
            "name": "Uruguay",
            "internationalDialingCode": "598"
        },
        {
            "code": "VU",
            "name": "Vanuatu",
            "internationalDialingCode": "678"
        },
        {
            "code": "VA",
            "name": "Vatican City",
            "internationalDialingCode": "39"
        },
        {
            "code": "VE",
            "name": "Venezuela",
            "internationalDialingCode": "58"
        },
        {
            "code": "VN",
            "name": "Vietnam",
            "internationalDialingCode": "84"
        },
        {
            "code": "WF",
            "name": "Wallis & Futuna",
            "internationalDialingCode": "681"
        },
        {
            "code": "YE",
            "name": "Yemen",
            "internationalDialingCode": "967"
        },
        {
            "code": "ZM",
            "name": "Zambia",
            "internationalDialingCode": "260"
        },
        {
            "code": "ZW",
            "name": "Zimbabwe",
            "internationalDialingCode": "263"
        }
    ],
    "post":{
        "AL": {
            "territoryId": "AL",
            "regex": "\\d{4}"
        },
        "AR": {
            "territoryId": "AR",
            "regex": "([A-HJ-NP-Z])?\\d{4}([A-Z]{3})?"
        },
        "AT": {
            "territoryId": "AT",
            "regex": "\\d{4}"
        },
        "AU": {
            "territoryId": "AU",
            "regex": "\\d{4}"
        },
        "BE": {
            "territoryId": "BE",
            "regex": "\\d{4}"
        },
        "BG": {
            "territoryId": "BG",
            "regex": "\\d{4}"
        },
        "BN": {
            "territoryId": "BN",
            "regex": "[A-Z]{2}[ ]?\\d{4}"
        },
        "BR": {
            "territoryId": "BR",
            "regex": "\\d{5}[\\-]?\\d{3}"
        },
        "BY": {
            "territoryId": "BY",
            "regex": "\\d{6}"
        },
        "C2": {
            "territoryId": "C2",
            "regex": "\\d{6}"
        },
        "CA": {
            "territoryId": "CA",
            "regex": "[ABCEGHJKLMNPRSTVXY]\\d[ABCEGHJ-NPRSTV-Z][ ]?\\d[ABCEGHJ-NPRSTV-Z]\\d"
        },
        "CH": {
            "territoryId": "CH",
            "regex": "\\d{4}"
        },
        "CL": {
            "territoryId": "CL",
            "regex": "\\d{7}"
        },
        "CN": {
            "territoryId": "CN",
            "regex": "\\d{6}"
        },
        "CO": {
            "territoryId": "CO",
            "regex": "\\d{6}"
        },
        "CR": {
            "territoryId": "CR",
            "regex": "\\d{4,5}|\\d{3}-\\d{4}"
        },
        "CY": {
            "territoryId": "CY",
            "regex": "\\d{4}"
        },
        "CZ": {
            "territoryId": "CZ",
            "regex": "\\d{3}[ ]?\\d{2}"
        },
        "DE": {
            "territoryId": "DE",
            "regex": "\\d{5}"
        },
        "DK": {
            "territoryId": "DK",
            "regex": "\\d{4}"
        },
        "DO": {
            "territoryId": "DO",
            "regex": "\\d{5}"
        },
        "EC": {
            "territoryId": "EC",
            "regex": "\\d{6}"
        },
        "EE": {
            "territoryId": "EE",
            "regex": "\\d{5}"
        },
        "ES": {
            "territoryId": "ES",
            "regex": "\\d{5}"
        },
        "FI": {
            "territoryId": "FI",
            "regex": "\\d{5}"
        },
        "FM": {
            "territoryId": "FM",
            "regex": "(\\d{4})([ \\-]\\d{4})?"
        },
        "FO": {
            "territoryId": "FO",
            "regex": "FO-\\d{3}"
        },
        "FR": {
            "territoryId": "FR",
            "regex": "\\d{5}"
        },
        "GB": {
            "territoryId": "GB",
            "regex": "GIR[ ]?0AA|((AB|AL|B|BA|BB|BD|BH|BL|BN|BR|BS|BT|CA|CB|CF|CH|CM|CO|CR|CT|CV|CW|DA|DD|DE|DG|DH|DL|DN|DT|DY|E|EC|EH|EN|EX|FK|FY|G|GL|GY|GU|HA|HD|HG|HP|HR|HS|HU|HX|IG|IM|IP|IV|JE|KA|KT|KW|KY|L|LA|LD|LE|LL|LN|LS|LU|M|ME|MK|ML|N|NE|NG|NN|NP|NR|NW|OL|OX|PA|PE|PH|PL|PO|PR|RG|RH|RM|S|SA|SE|SG|SK|SL|SM|SN|SO|SP|SR|SS|ST|SW|SY|TA|TD|TF|TN|TQ|TR|TS|TW|UB|W|WA|WC|WD|WF|WN|WR|WS|WV|YO|ZE)(\\d[\\dA-Z]?[ ]?\\d[ABD-HJLN-UW-Z]{2}))|BFPO[ ]?\\d{1,4}"
        },
        "GF": {
            "territoryId": "GF",
            "regex": "9[78]3\\d{2}"
        },
        "GL": {
            "territoryId": "GL",
            "regex": "DK-39\\d{2}"
        },
        "GP": {
            "territoryId": "GP",
            "regex": "9[78][01]\\d{2}"
        },
        "GR": {
            "territoryId": "GR",
            "regex": "\\d{3}[ ]?\\d{2}"
        },
        "HR": {
            "territoryId": "HR",
            "regex": "\\d{5}"
        },
        "HU": {
            "territoryId": "HU",
            "regex": "\\d{4}"
        },
        "ID": {
            "territoryId": "ID",
            "regex": "\\d{5}"
        },
        "IL": {
            "territoryId": "IL",
            "regex": "\\d{7}"
        },
        "IN": {
            "territoryId": "IN",
            "regex": "\\d{6}"
        },
        "IS": {
            "territoryId": "IS",
            "regex": "\\d{3}"
        },
        "IT": {
            "territoryId": "IT",
            "regex": "\\d{5}"
        },
        "JP": {
            "territoryId": "JP",
            "regex": "\\d{3}-\\d{4}"
        },
        "KG": {
            "territoryId": "KG",
            "regex": "\\d{6}"
        },
        "KH": {
            "territoryId": "KH",
            "regex": "\\d{5}"
        },
        "KR": {
            "territoryId": "KR",
            "regex": "\\d{5}"
        },
        "KW": {
            "territoryId": "KW",
            "regex": "\\d{5}"
        },
        "KZ": {
            "territoryId": "KZ",
            "regex": ".{7}"
        },
        "LA": {
            "territoryId": "LA",
            "regex": "\\d{5}"
        },
        "LK": {
            "territoryId": "LK",
            "regex": "\\d{5}"
        },
        "LT": {
            "territoryId": "LT",
            "regex": "\\d{5}"
        },
        "LU": {
            "territoryId": "LU",
            "regex": "\\d{4}"
        },
        "LV": {
            "territoryId": "LV",
            "regex": "LV-\\d{4}"
        },
        "MA": {
            "territoryId": "MA",
            "regex": "\\d{5}"
        },
        "MC": {
            "territoryId": "MC",
            "regex": "980\\d{2}"
        },
        "MD": {
            "territoryId": "MD",
            "regex": "\\d{4}"
        },
        "ME": {
            "territoryId": "ME",
            "regex": "8\\d{4}"
        },
        "MK": {
            "territoryId": "MK",
            "regex": "\\d{4}"
        },
        "MN": {
            "territoryId": "MN",
            "regex": "\\d{5}"
        },
        "MQ": {
            "territoryId": "MQ",
            "regex": "9[78]2\\d{2}"
        },
        "MT": {
            "territoryId": "MT",
            "regex": "[A-Z]{3}[ ]?\\d{2,4}"
        },
        "MU": {
            "territoryId": "MU",
            "regex": "\\d{5}"
        },
        "MV": {
            "territoryId": "MV",
            "regex": "\\d{5}"
        },
        "MX": {
            "territoryId": "MX",
            "regex": "\\d{5}"
        },
        "MY": {
            "territoryId": "MY",
            "regex": "\\d{5}"
        },
        "NG": {
            "territoryId": "NG",
            "regex": "(\\d{6})?"
        },
        "NL": {
            "territoryId": "NL",
            "regex": "\\d{4}[ ]?[A-Z]{2}"
        },
        "NP": {
            "territoryId": "NP",
            "regex": "\\d{5}"
        },
        "NZ": {
            "territoryId": "NZ",
            "regex": "\\d{4}"
        },
        "NO": {
            "territoryId": "NO",
            "regex": "\\d{4}"
        },
        "OM": {
            "territoryId": "OM",
            "regex": "\\d{3}"
        },
        "PA": {
            "territoryId": "PA",
            "regex": "\\d{4}"
        },
        "PE": {
            "territoryId": "PE",
            "regex": "\\d{5}"
        },
        "PH": {
            "territoryId": "PH",
            "regex": "\\d{4}"
        },
        "PL": {
            "territoryId": "PL",
            "regex": "\\d{2}-\\d{3}"
        },
        "PT": {
            "territoryId": "PT",
            "regex": "\\d{4}(\\d{3})?"
        },
        "PY": {
            "territoryId": "PY",
            "regex": "\\d{4}"
        },
        "RE": {
            "territoryId": "RE",
            "regex": "9[78]4\\d{2}"
        },
        "RO": {
            "territoryId": "RO",
            "regex": "\\d{6}"
        },
        "RS": {
            "territoryId": "RS",
            "regex": "\\d{5}|\\d{6}"
        },
        "RU": {
            "territoryId": "RU",
            "regex": "\\d{6}"
        },
        "SA": {
            "territoryId": "SA",
            "regex": "\\d{5}"
        },
        "SE": {
            "territoryId": "SE",
            "regex": "\\d{3}[ ]?\\d{2}"
        },
        "SI": {
            "territoryId": "SI",
            "regex": "\\d{4}"
        },
        "SG": {
            "territoryId": "SG",
            "regex": "\\d{6}"
        },
        "SK": {
            "territoryId": "SK",
            "regex": "\\d{3}[ ]?\\d{2}"
        },
        "SM": {
            "territoryId": "SM",
            "regex": "4789\\d"
        },
        "SV": {
            "territoryId": "SV",
            "regex": "\\d{4}"
        },
        "TH": {
            "territoryId": "TH",
            "regex": "\\d{5}"
        },
        "TR": {
            "territoryId": "TR",
            "regex": "\\d{5}"
        },
        "TT": {
            "territoryId": "TT",
            "regex": "\\d{6}"
        },
        "TW": {
            "territoryId": "TW",
            "regex": "\\d{3}(\\d{2})?"
        },
        "UA": {
            "territoryId": "UA",
            "regex": "\\d{5}"
        },
        "US": {
            "territoryId": "US",
            "regex": "\\d{5}([ \\-]\\d{4})?"
        },
        "UY": {
            "territoryId": "UY",
            "regex": "\\d{5}"
        },
        "VA": {
            "territoryId": "VA",
            "regex": "00120"
        },
        "VE": {
            "territoryId": "VE",
            "regex": "\\d{4}"
        },
        "VN": {
            "territoryId": "VN",
            "regex": "\\d{6}"
        },
        "WS": {
            "territoryId": "WS",
            "regex": "\\d{4}"
        },
        "YT": {
            "territoryId": "YT",
            "regex": "976\\d{2}"
        },
        "ZA": {
            "territoryId": "ZA",
            "regex": "\\d{4}"
        }
    }
}
