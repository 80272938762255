export function timeFormat(val,fmt) {
    if (val === 0 || val === '' || val === undefined) return '';
    let ret;
    let len = val.toString().length
    let timestamp = val
    if (len === 10) {
        timestamp = Number(timestamp) * 1000
    }
    if (typeof timestamp === 'string') {
        timestamp = Number(timestamp)
    }
    let date = new Date(timestamp)
    const opt = {
        "Y+": date.getFullYear().toString(),        // 年
        "m+": (date.getMonth() + 1).toString(),     // 月
        "d+": date.getDate().toString(),            // 日
        "H+": date.getHours().toString(),           // 时
        "M+": date.getMinutes().toString(),         // 分
        "S+": date.getSeconds().toString()          // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
            fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
        }
    }

    return fmt;
}
