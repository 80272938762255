import Vue from 'vue'
import Vuex from 'vuex'
import getters from "./getters";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null,
    },
    mutations: {
        setUserInfo:(state,userInfo)=>{
            state.userInfo = userInfo;
            if (userInfo == null){
                localStorage.removeItem('userInfo')
                return
            }
            localStorage.setItem('userInfo',JSON.stringify(userInfo))
        }
    },
    actions: {},
    modules: {},
    getters
})
