<template>
    <div id="app">
        <page-head @scroll="scroll" @sign="signClick" ></page-head>

        <div class="router-box">
            <router-view/>
        </div>
        <page-footer @scroll="scroll"></page-footer>
    </div>
</template>

<script>
    import pageHead from '@/components/page-head.vue'
    import PageFooter from '@/components/page-footer.vue'

    export default {
        data() {
            return{}
        },
        components: {
            pageHead,
            PageFooter
        },
        created(){
            // let lan = navigator.language.split('-')[0]
            // this.$i18n.locale = "fr";
            // switch (lan) {
            //     case "en":
            //         this.$i18n.locale = "en-US";
            //         break;
            //     case "fr":
            //         this.$i18n.locale = "fr";
            //         break;
            //     default:
            //         this.$i18n.locale = "en-US";
            // }
        },
        mounted() {
            window.$("#app").on("click",".positioning-btn",function(e){
                document.querySelector(`#index-top`).scrollIntoView({
                    block: 'start',
                    behavior: 'smooth'
                })
                e.preventDefault();
            });

            // nav style
            var nav_flag = false
            var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            window.$('.nav-btn').click(function () {
                if (nav_flag) {
                    window.$(this).removeClass('active')
                    window.$('.navbar-list').removeClass('active')
                    nav_flag = false
                    if (scrollTop < 50) {
                        window.$('.navbar').removeClass('active')
                    }
                } else {
                    window.$(this).addClass('active')
                    window.$('.navbar-list').addClass('active')
                    nav_flag = true
                    window.$('.navbar').addClass('active')
                }
            })
            window.onscroll = function () {
                scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
                if (nav_flag){
                    window.$('.navbar').addClass('active')
                    return
                }
                if (scrollTop > 50) {
                    window.$('.navbar').addClass('active')
                }else {
                    window.$('.navbar').removeClass('active')
                }
            }

        },
        methods: {
            signClick() {

            },
            scroll(e) {
                if (this.$route.path === '/') {
                    document.querySelector(`#${e}`).scrollIntoView({
                        block: 'start',
                        behavior: 'smooth'
                    })
                }else {
                    this.$router.push({
                        path: '/',
                        query: {
                            scroll: e
                        }
                    })
                }
            }
        },
        watch:{
            $route(to){
                document.title = to.name
                // window.ga('set', 'title', to.name);
                // window.ga('send', 'pageview');
                this.$analytics.setCurrentScreen(to.name) // sets `screen_name` parameter
                this.$analytics.logEvent('page_view') // log event with `screen_name` parameter attached
                console.log(to.name)
                if (to.name !== 'index') {
                    document.querySelector(`#app`).scrollIntoView(true)
                }
            }
        }
    }
</script>


<style lang="scss">
    @import "style/public";

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: left;
        color: #2c3e50;
        .router-box{
            width: 100%;
            min-height: 400px;
        }
    }

    #nav {
        padding: 30px;

        a {
            font-weight: bold;
            color: #2c3e50;

            &.router-link-exact-active {
                color: #42b983;
            }
        }
    }
</style>
