<template>
    <div id="index-top">
        <one @deliver="getDeliver"></one>

        <two></two>
        <three></three>

        

        <div class="three-view scroll-view" data-scrollTop="Price" id="Price">
            <div class="content">
                <div class="three-view-bg">
                    <h1 class="title">{{$t("index.price.title")}}</h1>
                    <!-- <p class="title-tips">Our geolocation service is a payment service with a $<span
                            class="price-txt"><span>{{userObj.price.contentJsonObject.trialPrice}}</span></span> trial for two days. In case you do not cancel your
                        subscription, it will be renewed automatically and you will be charged $<span
                                class="after"><span>{{userObj.price.presentPrice}}</span></span>
                        per month.
                    </p>
                    <ul>
                        <li><img src="@/assets/images/price-bank.png" alt="master"></li>
                    </ul> -->

                    <div class="price-wrap">
                        <div class="price-box">
                            <div class="top">
                                <div class="top-content">
                                    <p>{{$t("index.price.trialTitle")}}</p>
                                    <p class="price">
                                        <span>$</span>
                                        <span class="num price-txt"><span>{{userObj.price.contentJsonObject.trialPrice}}</span></span>
                                    </p>
                                    <p>{{$t("index.price.trialDay")}}</p>
                                    <p class="title-tips">
                                        {{$t("index.price.buyTipsF")}} $<span class="price-txt"><span>{{userObj.price.contentJsonObject.trialPrice}}</span></span> 
                                        {{$t("index.price.buyTipsC")}} $<span class="after"><span>{{userObj.price.presentPrice}}</span></span>
                                        {{$t("index.price.buyTipsL")}}
                                    </p>
                                </div>
                            </div>
                            <!-- <div class="bottom">
                                <ul>
                                    <li>
                                        <img src="@/assets/images/price-success-icon.png" alt="">
                                        <span>Precise Geographic Location</span>
                                    </li>
                                    <li>
                                        <img src="@/assets/images/price-success-icon.png" alt="">
                                        <span>Any Mobile Phone Brand</span>
                                    </li>
                                    <li>
                                        <img src="@/assets/images/price-success-icon.png" alt="">
                                        <span>Any Mobile Telecommunications Company</span>
                                    </li>
                                    <li>
                                        <img src="@/assets/images/price-success-icon.png" alt="">
                                        <span>All Regions</span>
                                    </li>
                                </ul>
                                <a class="startUP" @click="backTop">START UP</a>
                            </div> -->
                        </div>
                        <div class="right-box">
                            <p>{{$t("index.price.monthTitle")}}</p>
                            <p class="price">
                                <span>$</span>
                                <span class="num after"><span>{{userObj.price.presentPrice}}</span></span>
                            </p>
                            <p>{{$t("index.price.month")}}</p>
                            <p>{{$t("index.price.monthDesc")}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <four></four> -->
        <div v-show="shadow_status === 0" class="shadow-box paddle-shadow">
            <div class="pay-box">
                <img src="@/assets/images/index-pay-icon.png" alt="">
                <div class="close-btn" @click="shadow_status = -1" >
                    <img src="@/assets/images/pay-close.png" alt="">
                </div>
                <div class="top">
                    <h2>{{$t("map.locaOf")}} <span class="phoneNumber">{{info.phoneNumber}}</span></h2>
                    <p class="txt">{{$t("index.pay.TrialTime")}}</p>
                    <div class="line"></div>
                    <p class="email-txt">{{info.email}}</p>
                </div>

                <div class="form-box">
                    <div class="inp-box">
                        <div class="car">
                            <img src="@/assets/images/pay-email-icon.png" alt="">
                            <p class="email">
                                <input placeholder="email" v-model="info.email" disabled/>
                            </p>
                        </div>
                        <div class="car-info">
                            <div class="country-box">
                                <p class="country">
                                    <input type="text" id="country" />
                                    <input type="hidden" id="country_code" v-model="country_code"/>
                                </p>
                            </div>
                            <div>
                                <img src="@/assets/images/pay-zip-icon.png" alt="">
                                <p class="zip">
                                    <input type="text" :placeholder='$t("index.pay.zipUP")' v-model="zip">
                                </p>
                            </div>
                        </div>
                    </div>
                    <a class="paddle-pay price-txt" @click="paddlePay()">{{$t("index.pay.pay")}} $ <span>{{userObj.price.contentJsonObject.trialPrice}}</span></a>
                    <!-- <a class="pay-btn price-txt PayPal" data-paypal="0">
                        <img src="@/assets/images/Paypal.png" alt="">
                    </a> -->
                    <p class="err-tips" :style="{display: stripeErr_status? 'flex' :'none'}">
                        <img src="@/assets/images/pay-err-tips-icon.png" alt="">
                        <span>{{stripeErr_text}}</span>
                    </p>
                    <div class="pay-loader-box" :style="{display: payLoad? 'flex':'none'}">
                        <div class="loader"></div>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="shadow_status === 1" class="shadow-box stripe-card-shadow">
            <div class="pay-box">
                <img src="@/assets/images/index-pay-icon.png" alt="">
                <div class="close-btn" @click="closePop()" >
                    <img src="@/assets/images/pay-close.png" alt="">
                </div>
                <div class="top">
                    <h2>{{$t("map.locaOf")}} <span class="phoneNumber">{{info.phoneNumber}}</span></h2>
                    <p class="txt">{{$t("index.pay.TrialTime")}}</p>
                    <div class="line"></div>
                    <p class="email-txt">{{info.email}}</p>
                </div>

                <div class="form-box">
                    <div class="inp-box lnline-car">
                        <div class="car">
                            <img src="@/assets/images/pay-car-number-icon.png" alt="">
                            <p id="card-number"></p>
                        </div>
                        <div class="car-info">
                            <div>
                                <img src="@/assets/images/pay-date-icon.png" alt="">
                                <p id="card-expiry"></p>
                            </div>
                            <div>
                                <img src="@/assets/images/pay-cvc-icon.png" alt="">
                                <p id="card-cvc"></p>
                            </div>
                        </div>
                    </div>

                    <a class="pay-btn price-txt" id="stripe-card" @click="stripePay()">{{$t("index.pay.pay")}} $ <span>{{userObj.price.contentJsonObject.trialPrice}}</span></a>

                    <!-- <a class="pay-btn price-txt PayPal" data-paypal="0" @click="paypal">
                        <img src="@/assets/images/Paypal.png" alt="" @click="paypal">
                    </a> -->

                    <p class="err-tips" :style="{display: stripeErr_status? 'flex' :'none'}">
                        <img src="@/assets/images/pay-err-tips-icon.png" alt="">
                        <span>{{stripeErr_text}}</span>
                    </p>
                    <div class="pay-loader-box" :style="{display: payLoad? 'flex':'none'}">
                        <div class="loader"></div>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="shadow_status === 2" class="shadow-box MyLianlian-shadow" >
            <div class="pay-box">
                <img src="@/assets/images/index-pay-icon.png" alt="">
                <div class="close-btn" @click="closePop()">
                    <img src="@/assets/images/pay-close.png" alt="">
                </div>
                <div class="top">
                    <h2>Location of <span class="phoneNumber">{{info.phoneNumber}}</span></h2>
                    <p class="txt">2-Day Trial - Without Permanence</p>
                    <div class="line"></div>
                    <p class="email-txt">{{info.email}}</p>
                </div>

                <div class="form-box">
                    <div class="list-txt">
                        <p>
                            <img src="@/assets/images/price-success-icon.png" alt="">
                            <span>All Regions</span>
                        </p>
                        <p>
                            <img src="@/assets/images/price-success-icon.png" alt="">
                            <span>Precise Geographic Location</span>
                        </p>
                        <p>
                            <img src="@/assets/images/price-success-icon.png" alt="">
                            <span>Any Mobile Phone Brand</span>
                        </p>
                        <p>
                            <img src="@/assets/images/price-success-icon.png" alt="">
                            <span>Any Mobile Telecommunications Company</span>
                        </p>
                    </div>
                    <!-- <a class="lianlian-pay price-txt">Pay $ <span>--</span></a> -->
                    <a class="lianlianBtn pay-btn" @click="openLianLianCrad">
                        <img src="@/assets/images/Stripe-checkout.png" alt="">
                        <p>Credit Card</p>
                    </a>
                    <a class="pay-btn price-txt PayPal" data-paypal="0" @click="paypal">
                        <img src="@/assets/images/Paypal.png" alt="">
                    </a>

                    <p class="err-tips" :style="{display: stripeErr_status? 'flex' :'none'}">
                        <img src="@/assets/images/pay-err-tips-icon.png" alt="">
                        <span>{{stripeErr_text}}</span>
                    </p>
                    <div class="pay-loader-box" :style="{display: payLoad? 'flex':'none'}">
                        <div class="loader"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="shadow-box MyLianlianCrad-shadow" v-show="MyLianlianCrad">
            <div class="pay-box">
                <img src="@/assets/images/index-pay-icon.png" alt="">
                <div class="close-btn" @click="MyLianlianCrad = false">
                    <img src="@/assets/images/pay-close.png" alt="">
                </div>
                <div class="top">
                    <h2>Location of <span class="phoneNumber">{{info.phoneNumber}}</span></h2>
                    <p class="txt">2-Day Trial - Without Permanence</p>
                    <div class="line"></div>
                    <p class="email-txt">{{info.email}}</p>
                </div>

                <div class="form-box">
                    <div id="lianlian-crad"></div>
                    <div class="my-lianlian-from">
                        <div class="lianlian-input-box" style="margin-top: 0;">
                            <p>Name on card</p>
                            <input class="lianlian-name" type="text" name="name" v-model="lianlinaPayUserInfo.name">
                        </div>
                        <div class="lianlian-input-box">
                            <p>Email</p>
                            <input disabled class="lianlian-email" type="text" name="email" v-model="info.email">
                        </div>
                        <div class="lianlian-input-box">
                            <p>Billing address</p>
                            <div class="ll-input-group">
                                <select name="country" id="territory" aria-placeholder="Country/Region"
                                        v-model="lianlinaPayUserInfo.territoryVal" @change="territoryChange(lianlinaPayUserInfo.territoryVal)">
                                    <option v-for="item in countryData.territory" :value="item.code" :key="item.name">{{item.name}}</option>
                                </select>
                                <input class="lianlian-addressA" type="text" name="address"
                                       placeholder="Address line 1" v-model="lianlinaPayUserInfo.addressA">
                                <input class="lianlian-addressB" type="text" name="address"
                                       placeholder="Address line 2" v-model="lianlinaPayUserInfo.addressB">
                                <div class="inputs">
                                    <input class="lianlian-city" type="text" name="city"
                                           placeholder="City" v-model="lianlinaPayUserInfo.city">
                                    <input class="lianlian-zip" type="text" name="zip"
                                           placeholder="ZIP" v-model="lianlinaPayUserInfo.zip">
                                </div>
                                <select name="state" id="state" v-model="lianlinaPayUserInfo.state"
                                        v-show="countryData.state[lianlinaPayUserInfo.territoryVal]['stateList']">
                                    <option v-for="item in countryData.state[lianlinaPayUserInfo.territoryVal]['stateList']"
                                            :value="item['$id']" :key="item['$elt']">{{item['$elt']}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <a class="myPay Mylianlian-pay price-txt" style="margin-left: auto;margin-right: auto;" @click="lianlianPay">
                        Pay $ <span>{{userObj.price.contentJsonObject.trialPrice}}</span>
                    </a>
                    <p class="err-tips">
                        <img src="@/assets/images/pay-err-tips-icon.png" alt="">
                        <span></span>
                    </p>
                    <!--<div class="pay-loader-box" :style="{display: payLoad? 'flex':'none'}">-->
                    <!--    <div class="loader"></div>-->
                    <!--</div>-->
                </div>
            </div>
        </div>

        <div class="paddle-wrap" :style="{display: paddleCheckout? 'flex':'none'}">
            <div class="checkout-container">
                <div class="paddle-checkout-close-btn" @click="paddleCheckoutClose">
                    <img src="@/assets/images/pay-close.png" alt="">
                </div>
                <div class="paddle-checkout"></div>
            </div>
        </div>

    </div>
</template>

<script>
    import One from '@/components/one'
    import Two from '@/components/two'
    // import Four from '@/components/four'
    import Three from '@/components/three'
    // import {loadStripe} from '@stripe/stripe-js'
    import countryData from "@/assets/js/countryData";

    export default {
        name: "index",
        data() {
            return{
                shadow_status: -1,
                userObj: localStorage.getItem('order_userObj') || {
                    price:{
                        presentPrice: '--',
                        contentJsonObject: {
                            trialPrice: '--'
                        }
                    }
                },
                elementStyles:{
                    base: {
                        color: '#606266',
                        '::placeholder': {
                            color: '#C0C4CC',
                        },
                    },
                },
                stripeErr_text: '',
                stripeErr_status: false,
                info: {
                    email: '-',
                    phoneNumber: ''
                },
                stripe: null,
                cardNumber: null,
                payLoad: false,
                logEventPayType: 'Stripe',
                country_code: 'US',
                zip: '',
                paddleCheckout: false,
                MyLianlianCrad: false,
                countryData,

                lianlianElements: null,
                lianlinaPayUserInfo: {
                    territoryVal: 'AL',
                    name: '',
                    addressA: '',
                    addressB: '',
                    city: '',
                    zip: '',
                    state: ''
                },
                lianlianToken: '',
                llpInterval: null
            }
        },
        created() {
            if (!localStorage.getItem('home_page')) {
                localStorage.setItem('home_page',this.$route.path)
            }else {
                if (this.$route.path != localStorage.getItem('home_page')) {
                    this.$router.push({
                        path: localStorage.getItem('home_page')
                    })
                }
            }
            // this.amplitude.getInstance().logEvent('HomeScreen')
            this.eventLog('HomeScreen')
        },
        async mounted() {
            this.loading()

            // const optimizelyClientInstance = window.optimizelySdk.createInstance({
            //     datafile: window.optimizelyDatafile
            // });
            let variation = 'variation_2'
            const enabled = true
            // const enabled = optimizelyClientInstance.isFeatureEnabled('pricing', window.uuidstr);
            // variation = optimizelyClientInstance.activate('pricing_test', window.uuidstr);
            window.$("#country").countrySelect();

            if (this.$getQueryString('scroll')) {
                document.querySelector(`#${this.$getQueryString('scroll')}`).scrollIntoView({
                    block: 'start',
                    behavior: 'smooth'
                })
            }else {
                document.querySelector(`#index-top`).scrollIntoView({
                    block: 'start',
                    behavior: 'smooth'
                })
            }
            try {
                let price = await this.$axios.get('/portal/priceSetup',{
                    params:{
                        hasWeight: true,
                        channel: window.global_method || 'best_ind'
                    }
                })
                this.userObj.price = price.result.find(function (item) {
                    return item.isMaxWeight
                })
                if (enabled && variation == 'variation_2') {
                    // this.amplitude.getInstance().setUserProperties({'Optimizely_A/B_Test_Payment_Method': 'Paddle'})
                    this.logEventPayType = 'Paddle'

                }
                else if (enabled && variation == 'variation_3') {
                    // this.amplitude.getInstance().setUserProperties({'Optimizely_A/B_Test_Payment_Method': 'Lianlian'})
                    this.logEventPayType = 'Lianlian'
                    // 这里是为了 连连支付js 动态加载问题
                    this.llpInterval = setInterval(() =>{
                        if (window.LLP) {
                            this.lianlianElements = window.LLP.elements();
                            console.log('d-llp')
                            clearInterval(this.llpInterval)
                        }
                    })
                }
                else {
                    this.logEventPayType = 'Stripe'
                    console.log(this.userObj.price.publicStripeKey)
                    // this.amplitude.getInstance().setUserProperties({'Optimizely_A/B_Test_Payment_Method': 'Stripe'})
                    await this.initStripe(this.userObj.price.publicStripeKey)
                }
                this.hideLoading()
            }catch (e) {
                console.log(e)
                this.hideLoading()
                this.toast(this.$t("tips.err"))
            }
        },
        methods: {
            backTop() {
                document.querySelector(`#index-top`).scrollIntoView({
                    block: 'start',
                    behavior: 'smooth'
                })
            },
            paypal() {
                this.gtag('event', 'ZHIFU2_FMP', {
                    'event_category': 'DIANJI',
                    'event_label': 'ZHIFU2'
                });
                this.payLoad = true
                let params = {
                    "currency": "USD",
                    "email": this.info.email,
                    "money": this.userObj.price.presentPrice,
                    "priceSetupId": this.userObj.price.id,
                    "renew": true,
                    "subscribeMoney": this.userObj.price.presentPrice,
                    "type": this.userObj.price.orderType,
                    "userEmail": this.info.email,
                    "successUrl": this.add_url_search([location.origin , '/pay-success'], 'successPay=PayPalCheckout'),
                    "canelUrl": this.add_url_search([location.origin , location.pathname], 'canelPay=PayPalCheckout'),
                    isTryout: this.userObj.price.contentJsonObject.isTryout,
                    apkChannel: window.channel,
                    webId: window.uuidstr,
                    payWay: 0,
                    paymentType: 'checkout'
                }
                // params.payAccountCode = this.userObj.price.contentJsonObject.stripeCode

                params.content = JSON.stringify({
                    phoneNumber: this.info.phoneNumber
                })
                localStorage.setItem('params',JSON.stringify(params))
                // this.amplitude.getInstance().logEvent('InitiateCheckout')
                this.eventLog('InitiateCheckout')
                window.fbq('track', 'InitiateCheckout');
                this.$axios.post('/portal/order',params).then(res=>{
                    if (res.success == 1) {
                        sessionStorage.setItem('order_code', res.code)
                        sessionStorage.setItem('order_userObj', JSON.stringify(this.userObj))
                        location.href = res.obj
                    } else {
                        this.payLoad = false
                        this.toast(res.msg)
                    }
                }).catch(err=>{
                    console.log(err)
                    this.payLoad = false
                })

            },
            async stripePay() {
                this.gtag('event', 'ZHIFU2_FMP', {
                    'event_category': 'DIANJI',
                    'event_label': 'ZHIFU2'
                });
                try {
                    this.payLoad = true
                    const stripeRes = await this.stripe.createPaymentMethod({
                        type: 'card',
                        card: this.cardNumber,
                        "billing_details": {
                            "email": this.info.email,
                        },
                    })

                    if (stripeRes.error) {
                        if (stripeRes.error.code == 'testmode_charges_only') {
                            stripeRes.error.message = 'System error, please try again later'
                            setTimeout(()=>{
                                this.checkStripeKey()
                            },2000)
                        }
                        this.stripeErr(stripeRes)
                        this.payLoad = false
                        return
                    }

                    let params = {
                        "currency": "USD",
                        "email": this.info.email,
                        "money": this.userObj.price.presentPrice,
                        "priceSetupId": this.userObj.price.id,
                        "renew": true,
                        "subscribeMoney": this.userObj.price.presentPrice,
                        "type": this.userObj.price.orderType,
                        "userEmail": this.info.email,
                        "successUrl": this.add_url_search([location.origin , '/pay-success'], 'successPay=PayPalCheckout'),
                        "canelUrl": this.add_url_search([location.origin , location.pathname], 'canelPay=PayPalCheckout'),
                        isTryout: this.userObj.price.contentJsonObject.isTryout,
                        apkChannel: window.channel,
                        webId: window.uuidstr,
                        payWay: 2,
                        toPayId: stripeRes.paymentMethod.id,
                        paymentType: 'card',
                        payAccountCode: this.userObj.price.contentJsonObject.stripeCode,
                        content: JSON.stringify({
                            phoneNumber: this.info.phoneNumber
                        })
                    }
                    // this.amplitude.getInstance().logEvent('InitiateCheckout')
                    this.eventLog('InitiateCheckout')
                    window.fbq('track', 'InitiateCheckout');
                    localStorage.setItem('params',JSON.stringify(params))
                    const orderRes = await this.$axios.post('/portal/order',params)
                    if (orderRes.success != '1') {
                        this.stripeErr_text = orderRes.msg
                        this.stripeErr_status = true
                        this.payLoad = false
                    }else {
                        // let obj = JSON.parse(orderRes.obj)
                        sessionStorage.setItem('order_code', orderRes.code)
                        sessionStorage.setItem('order_userObj', JSON.stringify(this.userObj));
                        this.$router.push({
                            path: '/pay-success',
                            query: this.routerQuery('successPay=Stripe_Card')
                        })
                    }
                    console.log(orderRes)
                }catch (e) {
                    console.log(e)
                    this.payLoad = false
                }
            },

            paddlePay() {
                this.gtag('event', 'ZHIFU2_FMP', {
                    'event_category': 'DIANJI',
                    'event_label': 'ZHIFU2'
                });
                let zip = this.zip.trim()
                let country = this.country_code.toUpperCase()
                if (!/^[0-9]+.?[0-9]*$/.test(zip)) {
                    this.toast(this.$t("tips.incorrectCode"))
                    return
                }
                // this.amplitude.getInstance().logEvent('InitiateCheckout')
                this.eventLog('InitiateCheckout')
                window.fbq('track', 'InitiateCheckout');
                // userObj
                let params = {
                    "currency": "USD",
                    "email": this.info.email,
                    "money": this.userObj.price.presentPrice,
                    "priceSetupId": this.userObj.price.id,
                    "renew": true,
                    "subscribeMoney": this.userObj.price.presentPrice,
                    "type": this.userObj.price.orderType,
                    "userEmail": this.info.email,
                    "successUrl": this.add_url_search([location.origin, '/pay-success'],
                        'successPay=paddleCheckout'),
                    "canelUrl": this.add_url_search([location.origin, location.pathname],
                        'canelPay=paddleCheckout'),
                    "url": '/v1/portal/order',
                    "isTryout": this.userObj.price.contentJsonObject.isTryout,
                    "apkChannel": window.channel,
                    "webId": window.uuidstr,
                    "postCode": zip,
                    "country": country,
                }
                params.payWay = 7;
                // params.payAccountCode = this.userObj.price.contentJsonObject.stripeCode
                params.content = JSON.stringify({
                    phoneNumber: this.info.phoneNumber
                })
                localStorage.setItem('params', JSON.stringify(params))
                this.payLoad = true
                let that = this
                this.$axios.post('/portal/order',params).then(res=>{
                    if (res.success == 1) {
                        sessionStorage.setItem('order_code', res.code)
                        sessionStorage.setItem('order_userObj', JSON.stringify(this.userObj))
                        if (process.env.NODE_ENV != 'production') {
                          window.Paddle.Environment.set('sandbox');   
                        }
                        
                        window.Paddle.Setup({
                            vendor: Number(process.env.VUE_APP_PADDLE_PAY_KEY), // Replace with your Vendor ID.
                            eventCallback: function(eventData) {
                                console.log(eventData)
                            }
                        });
                        window.Paddle.Checkout.open({
                            email: this.info.email,
                            postcode: zip,
                            country: country,
                            success: this.add_url_search([location.origin , '/pay-success'], 'successPay=paddle_Checkout'),
                            override: res.obj,
                            method: 'inline',
                            allowQuantity: false,
                            disableLogout: true,
                            frameTarget: 'paddle-checkout', // The className of your checkout <div>
                            frameInitialHeight: 416,
                            frameStyle: 'width:100%; min-width:100%; background-color: transparent; border: none;',    // Please ensure the minimum width is kept at or above 495px
                            loadCallback: function (e) {
                                console.log(e)
                                that.payLoad = false
                                that.paddleCheckout = true
                                that.shadow_status = -1
                            }
                        });
                    } else {
                        this.payLoad = false
                        this.toast(res.msg)
                    }
                }).catch(err=>{
                    console.log(err)
                    this.toast(err.msg || this.$t("tips.paymentErr"))
                    this.payLoad = false
                })

                // postFetch(params, successPayCallbackPaddleCheckout, function (err) {
                //     $('.pay-loader-box').css('display', 'none')
                //     toggleTips(true, err.msg, 4000)
                // })
            },

            checkStripeKey() {
                this.stripeErr_text = ''
                this.stripeErr_status = false

                this.$axios.get('/other/checkStripe/'+this.userObj.price.contentJsonObject.stripeCode).then(res=>{
                    if (res.success == 1) {
                        this.initStripe(res.obj.publicStripeKey)
                        this.userObj.price.contentJsonObject.stripeCode = res.obj.stripeCode
                        this.payLoad = false
                    }
                }).catch(err=>{
                    console.log(err)
                    this.payLoad = false
                })
            },
            // async initStripe(key) {
            //     this.stripe = await loadStripe(key)
            //     this.$nextTick(() => {
            //         let elementInput = this.stripe.elements();
            //         this.cardNumber = elementInput.create('cardNumber', {
            //             style: this.elementStyles
            //         });
            //         this.cardNumber.mount('#card-number');

            //         let cardExpiry = elementInput.create('cardExpiry', {
            //             style: this.elementStyles
            //         });
            //         cardExpiry.mount('#card-expiry');

            //         let cardCvc = elementInput.create('cardCvc', {
            //             style: this.elementStyles
            //         });
            //         cardCvc.mount('#card-cvc');

            //         this.cardNumber.on('change', (event)=> {
            //             this.stripeErr(event)
            //         })
            //         cardExpiry.on('change', (event)=> {
            //             this.stripeErr(event)
            //         })
            //         cardCvc.on('change', (event)=> {
            //             this.stripeErr(event)
            //         })
            //         this.hideLoading()
            //     })
            // },
            paddleCheckoutClose() {
                this.paddleCheckout = false
                document.querySelector('.paddle-checkout iframe').remove()
            },
            openLianLianCrad() {
                // payLoad
                // this.amplitude.getInstance().logEvent('InitiateCheckout')
                this.eventLog('InitiateCheckout')
                window.fbq('track', 'InitiateCheckout');
                let params = {
                    "currency": "USD",
                    "email": this.info.email,
                    "money": this.userObj.price.presentPrice,
                    "priceSetupId": this.userObj.price.id,
                    "renew": true,
                    "subscribeMoney": this.userObj.price.presentPrice,
                    "type": this.userObj.price.orderType,
                    "userEmail": this.info.email,
                    "successUrl": process.env.VUE_APP_BASE_API + '/otherHtml/paySuccessUrl?resultUrl=' + encodeURIComponent(location.origin + '/pay-success'),
                    "canelUrl": this.add_url_search([location.origin , location.pathname], 'canelPay=LianLianCheckout'),
                    "urlParams": localStorage.getItem('url_search') ? localStorage.getItem('url_search').substr(1) + '&successPay=LianLianCheckout' :'successPay=LianLianCheckout',
                    "url": '/v1/portal/order',
                    isTryout: this.userObj.price.contentJsonObject.isTryout,
                    apkChannel: window.channel,
                    webId: window.uuidstr,
                    payWay: 8,
                    paymentType: 'iframe'
                }
                // params.payAccountCode = this.userObj.price.contentJsonObject.stripeCode
                this.payLoad = true
                params.content = JSON.stringify({
                    phoneNumber: this.info.phoneNumber
                })
                localStorage.setItem('params',JSON.stringify(params))
                this.$axios.post('/portal/order',params).then(res=>{
                    if (res.success == 1) {
                        this.MyLianlianCrad = true
                        this.shadow_status = -1
                        this.lianlianToken = JSON.parse(res.obj.content).lianlianToken
                        this.initLianLianPay()
                        this.payLoad = false
                        this.shadow_status = -1
                        this.MyLianlianCrad = true
                        sessionStorage.setItem('order_code', res.code)
                        sessionStorage.setItem('order_userObj', JSON.stringify(this.userObj))
                    }else {
                        this.payLoad = false
                        this.toast(res.msg)
                    }
                }).catch(err=>{
                    this.toast(err.msg || this.$t("tips.paymentErr"))
                    this.payLoad = false
                })
            },

            lianlianPay() {
                this.loading()
                if (this.lianlinaPayUserInfo.name == '') {
                    this.hideLoading()
                    this.toast(this.$t("tips.cradNameNoEmpty"))
                    return
                }
                if (this.lianlinaPayUserInfo.addressA == '') {
                    this.hideLoading()
                    this.toast(this.$t("tips.addressNoEmpty"))
                    return
                }
                if (this.lianlinaPayUserInfo.city == '') {
                    this.hideLoading()
                    this.toast(this.$t("tips.cityNoEmpty"))
                    return
                }
                if (this.lianlinaPayUserInfo.zip == '') {
                    this.hideLoading()
                    this.toast(this.$t("tips.zipNoEmpty"))
                    return
                }

                if (this.countryData['post'][this.lianlinaPayUserInfo.territoryVal]) {
                    let reg = new RegExp(countryData['post']['US']['regex'])
                    if (!reg.test(this.lianlinaPayUserInfo.zip)) {
                        this.hideLoading()
                        this.toast(this.$t("tips.zipIncorrect"))
                        return
                    }
                }
                this.hideLoading()
                let that = this
                window.LLP.confirmPay({
                    payment_method: {
                        cardName: this.lianlinaPayUserInfo.name,
                        email: this.userObj.email,
                        billing_details: {
                            cardCountry: this.lianlinaPayUserInfo.territoryVal,
                            cardCity: this.lianlinaPayUserInfo.city,
                            cardLine1: this.lianlinaPayUserInfo.addressA,
                            cardLine2: this.lianlinaPayUserInfo.addressB,
                            cardState: this.lianlinaPayUserInfo.state,
                            cardPostcode: this.lianlinaPayUserInfo.zip,
                        },
                    },
                }).then(function (result) {
                    that.hideLoading()
                    if (result.code == 200000) {
                        that.$router.push({
                            path: '/pay-success',
                            query: that.routerQuery('successPay=LianLianCheckout')
                        })
                        return
                    }
                    switch (result.code) {
                        case 900000:
                            that.toast('Invalid Request arguments');
                            break;
                        case 980000:
                            that.toast('Unavaliable Service');
                            break;
                        case 990000:
                            that.toast('System Busy');
                            break;
                        case 403001:
                            that.toast('The Customer has restrictions to perform \n' +
                                'exchange operation');
                            break;
                        case 403005:
                            that.toast('The Customer has exceeded the annual \n' +
                                'limit. For him to continue shopping, send \n' +
                                'proof of income to request limit increase');
                            break;
                        case 409010:
                            that.toast('Invalid Card Information');
                            break;
                        case 409012:
                            that.toast('Payment Failed');
                            break;
                        case 409013:
                            that.toast('Payment Denied');
                            break;
                        case 409014:
                            that.toast('Card Balance Insufficient');
                            break;
                        case 409015:
                            that.toast('Payment Timeout');
                            break;
                        case 409016:
                            that.toast('Invalid Card Brand');
                            break;
                        case 500900:
                            that.toast('Repetitive Operation');
                            break;
                        case 600000:
                            that.toast('Invalid Key');
                            break;
                        default:
                            that.toast(result.error.message)
                            break;
                    }
                    that.initLianLianPay()
                });
            },

            initLianLianPay() {
                let card = this.lianlianElements.create('card', {
                    key: this.lianlianToken,
                    style: {
                        base: {
                            fontFamily: 'San Francisco, Roboto, Helvetica Neue, Helvetica, Arial',
                            backgroundColor: '#FFFFFF',
                            fontSize: '12px',
                            fontWeight: '500',
                            floatLabelSize: '14px',
                            color: '#bcbcbc',
                            height: '264px'
                        },
                    }
                });
                card.mount('#lianlian-crad');
            },
            territoryChange(val) {
                this.lianlinaPayUserInfo.state = this.countryData.state[val]['stateList'] ? this.countryData.state[val]['stateList'][0]['$id'] : ''
            },
            closePop() {
                this.shadow_status = -1
                // this.amplitude.getInstance().logEvent('ExitCheckout',{
                //     Source: this.logEventPayType
                // })
                this.eventLog('ExitCheckout-'+this.logEventPayType)
            },
            getDeliver(e) {
                if (this.logEventPayType == 'Lianlian') {
                    this.shadow_status = 2
                }else if (this.logEventPayType == 'Paddle') {
                    this.shadow_status = 0
                }else {
                    this.shadow_status = 1
                }
                console.log(this.shadow_status)
                this.info = e
                this.userObj = Object.assign(this.userObj,this.info)
                // this.amplitude.getInstance().logEvent('CheckoutScreen',{
                //     Source: this.logEventPayType
                // })
                this.eventLog('CheckoutScreen-'+this.logEventPayType)
            },
            stripeErr(event) {
                if (event.error) {
                    this.stripeErr_text = event.error.message
                    this.stripeErr_status = true
                } else {
                    this.stripeErr_text = ''
                    this.stripeErr_status = false
                }
            }
        },
        components: {
            One,
            Two,
            Three,
            // Four
        },
        destroyed() {
            window.$('#cardFrame').remove();
            clearInterval(this.llpInterval)
        }
    }
</script>

<style lang="scss" scoped>
    @import "../style/index";
</style>
